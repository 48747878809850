import { FB_Refs_Rtdb as rtdb_refs } from '@ws/constants'

export class RTDB_Jessup_Refs {
  static jessup_eid() {
    return 'jessup_eid'
  }

  static assemble_court(event_id: string, room_id: string) {
    return  `${rtdb_refs._root(event_id)}/assemble_court/${room_id}`
  }

  static emp_ilsa_id(event_id: string, user_id: string) {
    return `${rtdb_refs.event_member_public_root(event_id)}/${user_id}/ilsa_id`
  }

  static matches_ready(event_id: string) {
    return `${rtdb_refs._root(event_id)}/matches_ready`
  }

  static match_judges(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/judges/members`
  }

  static match_app_memorial(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/memorial_link`
  }

  static match_app_observers(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/app_observers`
  }

  static match_app_team_id(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/name`
  }

  static match_app_users(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/0/members`
  }

  static match_res_memorial(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/memorial_link`
  }

  static match_res_observers(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/res_observers`
  }

  static match_res_team_id(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/name`
  }

  static match_res_users(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/teams/teams/1/members`
  }

  static match_room_link(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/room_link`
  }

  static match_room_name(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/room_name`
  }

  static match_open_time(event_id: string, match_id: string) {
    return `${rtdb_refs.room(event_id, match_id)}/open_time`
  }

  static match_speakers(event_id: string) {
    return `${rtdb_refs._root(event_id)}/court_speakers`
  }

  static match_speaker_app(event_id: string) {
    return `${this.match_speakers(event_id)}/app`
  }

  // static match_speaker_app1(event_id: string) {
  //   return `${this.match_speakers(event_id)}/app1`
  // }

  // static match_speaker_app2(event_id: string) {
  //   return `${this.match_speakers(event_id)}/app2`
  // }

  static match_speaker_res(event_id: string) {
    return `${this.match_speakers(event_id)}/res`
  }

  // static match_speaker_res1(event_id: string) {
  //   return `${this.match_speakers(event_id)}/res1`
  // }

  // static match_speaker_res2(event_id: string) {
  //   return `${this.match_speakers(event_id)}/res2`
  // }

}
