import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'exp-tag',
  templateUrl: './exp.component.html',
  styleUrls: ['./exp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Experimental_Tag_Component {
  letters = ['e','x','p','e','r','i','m','e','n','t','a','l']
  hex: string[] = []

  @Input() font_size = ''

  constructor() {
    this.letters.forEach((l, i) => this.hex.push(this.rando_hex))
  }

  get rando_hex() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return `#${randomColor}`
  }
}
