import { App_Fs_Org, App_Fs_Event } from '@app/types'
import { Organization, Event } from '@ws/schema-fs'

// https://dev.to/krumpet/generic-type-guard-in-typescript-258l
// interface typeMap { // for mapping from strings to types
//   string: string
//   number: number
//   boolean: boolean
//   org: Organization
// }
// type PrimitiveOrConstructor = // 'string' | 'number' | 'boolean' | constructor
//   | { new (...args: any[]): any }
//   | keyof typeMap
// // infer the guarded type from a specific case of PrimitiveOrConstructor
// type GuardedType<T extends PrimitiveOrConstructor> = T extends { new(...args: any[]): infer U } ? U : T extends keyof typeMap ? typeMap[T] : never
// // finally, guard ALL the types!
// function typeGuard<T extends PrimitiveOrConstructor>(o: any, className: T):
//   o is GuardedType<T> {
//     const localPrimitiveOrConstructor: PrimitiveOrConstructor = className
//     console.log('localPrimitiveOrConstructor: ', localPrimitiveOrConstructor)
//     console.log('o: ', o)

//     if (typeof localPrimitiveOrConstructor === 'string') {
//       console.log('is string')
//       console.log('typeof o: ', typeof o)

//       return typeof o === localPrimitiveOrConstructor
//     }

//     console.log('typeof o: ', typeof o)

//   return o instanceof localPrimitiveOrConstructor
// }

export class App_To_Db_Shapers {
  static fs = {
    event: (event: App_Fs_Event): Event => {
      const temp = Object.assign({}, event)
      delete temp.is_new
      // @ts-ignore
      delete temp.member_organization
      // @ts-ignore
      delete temp.id
      return temp
    },

    org: (org: App_Fs_Org): Organization => {
      const temp = Object.assign({}, org)
      // The App_Fs_Org type.
      // @ts-ignore
      delete temp.id
      delete temp.is_new
      delete temp.joined_on
      delete temp.role
      // Try to get working later???????????????
      // if (typeGuard(temp, Organization_Class)) {
      //   return temp
      // } else {
      //   throw new Error('wrong type')
      // }
      return temp
    }
  }
}
