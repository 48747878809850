import { State, Action, Selector, StateContext, StateToken } from '@ngxs/store'
import { Set_User_Public } from './actions'
import { App_User_Public } from '@app/types'
import { STORE_ENTITIES } from '../../constants/reducers'
import { Injectable } from '@angular/core'

export const USER_PUBLIC_STATE_TOKEN = new StateToken<App_User_Public>(STORE_ENTITIES.USER_PUBLIC)

@State<App_User_Public>({
  name: USER_PUBLIC_STATE_TOKEN,
})

@Injectable()
export class User_Public_State {

  @Selector()
  public static getState(state: App_User_Public) {
    return state
  }

  @Action(Set_User_Public)
  private add_user_public(
    ctx: StateContext<App_User_Public>,
    action: Set_User_Public,
  ) {
    ctx.patchState(action.payload)
  }
}
