declare const require: any
import { Injectable } from '@angular/core'
import { LISTENER_TYPE_FS as LISTENER_TYPE, ERROR_CODES } from '@app/constants'
import { FB_Refs_Firestore as refs } from '@ws/constants'
require('firebase/firestore')
import firebase from 'firebase/app'
import {
  DocumentReference,
  Transaction,
  UpdateData,
} from '@firebase/firestore-types'
import * as Schema from '@ws/schema-fs'
import {
  App_Fs_Org,
} from "@app/types"
import { Misc_Utils } from '@app/utils'
import { unpack_fs_collection, unpack_fs_doc } from './utils'
import {
  DocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types'
import {
  Org_Member_Invite,
  ORG_MEMBER_INVITATION_STATUS,
} from '@ws/schema-fs'
import clonedeep from 'lodash.clonedeep'


interface Fs_Listeners {
  [LISTENER_TYPE.ON_SNAPSHOT]: Record<string, boolean>
}

type Org_Member_No_Name = Pick<Schema.Org_Member, 'joined_on' | 'role'>

/**
 * This is the interface to the Firestore DB api. It handles
 * all the SDK calls. Also, Firebase's listeners can
 * be tricky to manage, so the FS listeners will all be handled
 * here in order to keep track of listeners and centralize logic.
 *
 * You will notice there may be cases of the Partial<T>
 * Typescript utility. During set operations, this will always
 * be paired with { merge: true }, thus ensuring partial types
 * will merge appropriately with Firebase data, while also
 * ensuring type safety.
 */
@Injectable({ providedIn: 'root' })
export class Firestore_Db_Service {
  private db = firebase.firestore()
  // Tracks all Firestore listeners.
  private listeners: Fs_Listeners = {
    [LISTENER_TYPE.ON_SNAPSHOT]: {},
  }

  constructor() {}

  async add_event(data: Schema.Event): Promise<DocumentReference> {
		return this.db.collection(refs.event_collection()).add(data)
  }

	async add_org_member_invitation_job(
    org_id: Schema.GUID,
    data: Schema.Org_Member_Invite,
  ): Promise<DocumentReference> {
		return this.db
			.collection(refs.organization_member_invitation_collection(org_id))
			.add(data)
	}

	// Creates a unique firestore key for the given collection reference.
	create_key(ref: string): string {
		return this.db.collection(ref).doc().id
  }

  delete_event(event_id: string) {
    return this.db.doc(refs.event(event_id)).delete()
  }

	async delete_organization(org_id: Schema.GUID): Promise<any> {
		return this.run_transaction(async(t) => {
			return t.delete(this.db.doc(refs.organization(org_id)))
		})
	}

	delete_organization_member(
    org_id: Schema.GUID,
    member_id: Schema.UID,
  ): Promise<any> {
    return this.db
      .doc(refs.organization_member(org_id, member_id))
      .delete()
	}

	// Must be a transation due to fluctuating invitation states.
	async delete_organization_member_invitation(
    organization_id: Schema.GUID,
    invite_id: Schema.GUID,
    ): Promise<any> {
		return this.run_transaction(async(t) => {
      const i = await t.get(this.db
        .doc(refs
          .organization_member_invitation(organization_id, invite_id)))

      if (!i.exists) {
				return 0
			}

			return t.delete(this.db.doc(refs.organization_member_invitation(organization_id, invite_id)))
		})
	}

	async get_event(event_id: Schema.GUID) {
    let query
    try {
      query = await this.db.doc(refs.event(event_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }

		return unpack_fs_doc<Schema.Event>(query)
  }

  async get_archived_event(event_id: string) {
    let query
    try {
      query = await this.db.doc(refs.archive_event(event_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }

		return unpack_fs_doc<Schema.Event>(query)
  }

  async get_event_public_invite(event_id: Schema.GUID) {
    let query
    try {
      query = await this.db.doc(refs.event_invitation(event_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
    return unpack_fs_doc<Schema.Event_Invitation>(query)
  }

	async get_organization(org_id: string) {
    let query
    try {
      query = await this.db.doc(refs.organization(org_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.Organization>(query)
	}

	/**
	 * Fetches an org object, and the signed-in user's member data for that org (mainly to get
	 * their role in the org).
	 * @param  {string} organization_id - id of the org
	 * @return {org obj} - org obj with member data
	 */
	async get_organization_with_current_user_member_data(
    organization_id: string,
    user_id: string
  ) {
		let org
		try {
			org = await this.get_organization(organization_id)
		} catch (e) {
			throw e
    }

		if (!org) {
			throw new Error(ERROR_CODES.ORG_DNE)
		}

    // This must exist, as role is used everywhere throughout app.
		const member: Schema.Org_Member | undefined = await this.get_organization_member(
      organization_id,
      user_id,
    )

    let app_org: App_Fs_Org | undefined

    if (member) {
      const m_obj: Org_Member_No_Name = Object.assign(
        {},
        { joined_on: member.joined_on },
        { role: member.role },
      )
      app_org = Object.assign({}, org, m_obj)
    }

    return app_org
	}

	async get_organization_event_collection(organization_id: string) {
    let query
    try {
      query = await this.db
      .collection(refs
        .organization_event_collection(organization_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }

		return unpack_fs_collection<Schema.Org_Event>(query)
	}

	async get_organization_member(org_id: string, member_id: string) {
    let query
    try {
      query = await this.db.doc(refs.organization_member(org_id, member_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.Org_Member>(query)
	}

	async get_organization_member_invitation(org_id: string, invite_id: string) {
    let query
    try {
      query = await this.db
        .doc(refs.organization_member_invitation(org_id, invite_id))
        .get()
    } catch (e) {
      console.error(e)
      return undefined
    }

		return unpack_fs_doc<Org_Member_Invite>(query)
	}

	async get_organization_member_collection(organization_id: string) {
    let query
    try {
      query = await this.db
      .collection(refs.organization_member_collection(organization_id))
      .get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_collection<Schema.Org_Member>(query)
	}

	async get_org_member_invitation_col(org_id: string) {
    let query
    try {
      query = await this.db.collection(refs
        .organization_member_invitation_collection(org_id))
        .get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_collection<Org_Member_Invite>(query)
  }

  async get_user_app(user_id: Schema.UID) {
    let query
    try {
      query = await this.db.doc(refs.user_app(user_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.User_App>(query)
  }

  async get_user_billing(uid: string) {
    let query
    try {
      query = await this.db.doc(refs.user_billing(uid)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.User_Billing>(query)
  }

  async get_user_private(user_id: Schema.UID){
    let query
    try {
      query = await this.db.doc(refs.user_private(user_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.User_Private>(query)
	}

	async get_user_public(user_id: Schema.UID){
    let query
    try {
      query = await this.db.doc(refs.user_public(user_id)).get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_doc<Schema.User_Public>(query)
	}

	async get_user_organization_collection(uid: string) {
    let query
    try {
      query = await this.db
      .collection(refs.user_organizations_collection(uid))
      .get()
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_fs_collection<Schema.User_Org_Doc>(query)
	}

	listen_on_document({
    path,
    callback,
    listener_type = LISTENER_TYPE.ON_SNAPSHOT,
    track = true,
  }: {
    path: string,
    callback: (snap: DocumentSnapshot) => any,
    listener_type?: LISTENER_TYPE,
    track?: boolean,
  }) {
		// Make sure this listener is not already attached.
		const service_path = `${LISTENER_TYPE.ON_SNAPSHOT}.${path}`

		if (this.listeners[LISTENER_TYPE.ON_SNAPSHOT][path]) {
      console.log(`Service for ${service_path} already loaded. Returning.`)
      return
    }

		try {
			// Set the listener.
			this.db.doc(path).onSnapshot(callback, (error) => {
				console.info('Error listening on document path: ', path)
      })

      if (track) {
        // Track the listener
        this.listeners[LISTENER_TYPE.ON_SNAPSHOT][path] = true
      }
		} catch (e) {
			console.error(e)
		}
	}

	listen_on_collection({
    path,
    callback,
    listener_type = LISTENER_TYPE.ON_SNAPSHOT,
    track = true,
  }: {
    path: string,
    callback: (snap: QuerySnapshot) => any,
    listener_type?: LISTENER_TYPE,
    track?: boolean,
  }) {
		// Make sure this listener is not already attached.
		if (this.listeners[listener_type][path]) {
      console.log(`Service for ${path} already loaded. Returning.`)
      return
    }

		try {
			// Set the listener.
			this.db.collection(path).onSnapshot(callback, (error) => {
				console.info('Error listening on collection path: ', path)
			})
      if (track) {
        // Track the listener
        this.listeners[LISTENER_TYPE.ON_SNAPSHOT][path] = true
      }
		} catch (e) {
			console.error(e)
		}
  }

  put_user_name_change_job(name: string, user_id: string) {
    const job: Schema.Job_User_Name_Change = {
      name,
      user_id,
    }

    return this.db.collection(refs.job_user_name_change_collection()).add(job)
  }

  run_transaction(
    transaction: (t: Transaction) => Promise<any>,
    callback?: any,
    catchback?: any,
  ) {
		// 'catchback' forwards errors to an error handler (defined or Express default).
		const cb = callback || function(value: any) {
      const v = value || undefined
      return v
    }
    const errHandler = catchback || function(err: Error) {
			throw err
		}
		return this.db.runTransaction(transaction).then(cb).catch(errHandler)
  }

  async set_org_member_invitation(
    status: ORG_MEMBER_INVITATION_STATUS,
    org_id: string,
    invite_id: string,
    user_id: string,
  ) {
    return this.run_transaction(async(t: Transaction) => {
      // Make sure the invite is still there.
      const invite_ref = this.db.doc(refs.organization_member_invitation(org_id, invite_id))
      const invite = await t.get(invite_ref)

      if (!invite.exists) {
        throw new Error('!invite')
      }

      // Update the invitation status. CF triggers on this status change.
      return t.update(invite_ref, {
        status,
        closed_on: Misc_Utils.get_fs_fieldvalue(),
        // This uid is used in the security rules so a member can add themselves as a member.
        user_id,
      })
    })
  }

  set_user_app(data: Schema.User_App, uid: string) {
    const d = sanitize_id(data)
		return this.db.doc(refs.user_app(uid)).set(d, { merge: true })
	}

  set_user_private(data: Partial<Schema.User_Private>, uid: string) {
    const d = sanitize_id(data)
		return this.db.doc(refs.user_private(uid)).set(d, { merge: true })
	}

	set_user_public(data: Schema.User_Public,  uid: string) {
		return this.db.doc(refs.user_public(uid)).set(data, { merge: true })
	}

	update_event(event_id: Schema.GUID, data: UpdateData) {
		return this.db.doc(refs.event(event_id)).update(data)
  }

  async update_event_invite_key(event_id: string) {
    const invite_key = this.create_key(refs.event_invitation_private_col(event_id))
    await this.db.doc(refs.event_invitation(event_id)).update({public: invite_key})
    return invite_key
  }

	update_organization_logos(org_id: Schema.GUID, logos: Schema.Org_Logos) {
		return this.db.doc(refs.organization(org_id)).update({
      'urls.logos': logos,
    })
	}

	update_organization_member_status(
    org_id: Schema.GUID,
    member_id: Schema.UID,
    data: { role: Schema.ORG_MEMBER_ROLE },
  ) {
    return this.db
      .doc(refs.organization_member(org_id, member_id))
      .update(data)
  }

  update_username(name: string, uid: string) {
    return this.db
      .doc(refs.user_public(uid))
      .update({ name })
  }
}

function sanitize_id(data: any) {
  const d = clonedeep(data)
  delete d.id
  return d
}

// function event_is_today(start_date: Date): boolean {
// 	const start: number = Misc_Utils.zero_out_date_time(start_date).getTime()
// 	const now: number = Misc_Utils.zero_out_date_time(new Date()).getTime()
// 	return now >= start
// }

