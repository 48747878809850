import { make_route } from '../utils'

const base = 'billing'

export class Billing {
  static root(project: string, is_local = false) {
    return make_route({ is_local, project, path: base})
  }

  static credits_intent(project: string, is_local = false) {
    const path = `${base}/credits-intent`
    return make_route({ is_local, project, path })
  }
}
