<div
  fxLayoutAlign="center center"
  class='container h100 o-y-auto'
  *ngIf='!done'>

  <div class='auth-container'>
    <form
      class='animated fadeIn form-container'
      nz-form
      nzNoColon
      nzLayout='vertical'
      [formGroup]="form"
      (ngSubmit)="submit_form()">

     <div class='ta-center header'>Create your account</div>

      <nz-form-item class='p-rel'>
        <div class='name-note'>* Signing up for a competitor? Use their name instead.</div>
        <nz-form-label class='hidden' nzFor="name"></nz-form-label>
        <nz-form-control nzErrorTip="Please input your name" nzHasFeedback>
          <nz-input-group nzPrefixIcon="user" nzSize="large">
            <input
              formControlName="name"
              id="name"
              nz-input
              placeholder="First and last name"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label class='hidden' nzFor="email"></nz-form-label>
        <nz-form-control nzErrorTip="Invalid email" nzHasFeedback>
          <nz-input-group nzPrefixIcon="mail" nzSize="large">
            <input
              formControlName="email"
              id="email"
              nz-input
              placeholder="Email"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label class='hidden' nzFor="password"></nz-form-label>
        <nz-form-control nzErrorTip="Please input your password" nzHasFeedback>
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input
              formControlName="password"
              id="password"
              nz-input
              placeholder='Password'
              type="password"
              (ngModelChange)="validator_update_confirm()"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label class='hidden' nzFor="checkPassword"></nz-form-label>
        <nz-form-control [nzErrorTip]="errorTpl" nzHasFeedback>
          <nz-input-group nzPrefixIcon="check" nzSize="large">
            <input
              id="checkPassword"
              formControlName="checkPassword"
              nz-input
              placeholder='Enter your password again'
              type="password"/>
          </nz-input-group>
          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Please confirm your password
            </ng-container>
            <ng-container *ngIf="control.hasError('confirm')">
              Passwords do not match
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-row>
        <nz-form-control>
          <label nz-checkbox formControlName="agree" required>
              I agree to the
          </label>
          <a
            class='terms'
            [href]="url_tos"
            target="_blank">Terms of Use</a>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-row class="register-area">
        <nz-form-control>
          <button
            nzBlock
            nzType="primary"
            nz-button
            [nzLoading]='submitting'
            nzSize='large'
            [disabled]="!form.valid || submitting"
            >Register</button>
        </nz-form-control>
      </nz-form-item>

      <br>

      <p class='ta-center fs-1-3-rem'>Already have an account?</p>
      <p class='ta-center fs-1-3-rem'><a [routerLink]='url_login'>Sign in</a></p>

      <a
        class='p-abs pp fs-1-2-rem'
        [href]='url_pp'
        target="_blank">Privacy</a>
      <a class='p-abs logo' [href]='url_app'>
        <logo-letter [fill]='logo_fill' [height]="'3rem'"></logo-letter>
      </a>
    </form>
  </div>

  <div class='doge-container'><doge></doge></div>

</div>
