import { STORE_ENTITIES } from '@app/constants'
import { App_Fs_Event } from '@app/types'

export class Delete_Event {
  public static readonly type = `[${STORE_ENTITIES.FS_EVENTS}] Delete Event`
  constructor(
    public event_id: string,
    public organization_id: string,
  ) {}
}

export class Set_Event {
  public static readonly type = `[${STORE_ENTITIES.FS_EVENTS}] Set Event`
  constructor(
    public payload: App_Fs_Event,
    public organization_id: string,
  ) { }
}
