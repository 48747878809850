declare const require: any
import { Injectable } from '@angular/core'
import { ERROR_CODES, LISTENER_TYPE_FS } from '@app/constants'
import { Firestore_Db_Service } from '@app/services/firestore.service'
import { FB_Storage_Service } from "@app/services/fb-storage.service"
import { App_Store_Service } from '@app/services/store.service'
import { User_Service } from '@app/services/user.service'
import { Rtdb_Service } from '@app/services/rtdb.service'
import * as Schema_Fs from '@ws/schema-fs'
import {
  FB_Refs_Firestore as fs_refs,
  FB_Refs_Storage,
  Routes,
} from '@ws/constants'
import {
  DocumentSnapshot,
  QuerySnapshot,
  DocumentChange,
  UpdateData,
} from '@firebase/firestore-types'
import * as utils from './utils'
import {
  App_Fs_Event,
  App_Fs_Org,
  App_Fs_Org_Member,
  App_Fs_Org_Member_Invite,
} from '@app/types'
import { Store } from "@ngxs/store"
import {
  DocumentReference,
} from '@firebase/firestore-types'
import { Misc_Utils } from '@app/utils'
import { get_image_thumbnail } from '@app/utils'
import { User_App, User_Private } from '@ws/schema-fs'
import { environment as env } from '@app/env'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Templates_Service } from '@app/services/templates.service'
import clonedeep from 'lodash.clonedeep'
import { Event_Date_Utils as EDU } from '@ws/utils'
import { Auth_Service } from './auth.service'

const _get = require('lodash.get')

interface Fetched {
  events: Record<Schema_Fs.GUID, boolean>
  org_members: Record<Schema_Fs.GUID, boolean>
  org_member_invites: Record<Schema_Fs.GUID, boolean>
}

type T_User_Public_Name = Pick<Schema_Fs.User_Public, 'name'>
type T_User_App_Last_Org = Pick<Schema_Fs.User_App, 'last_org'>
type T_User_Last_Org_Id = Pick<Schema_Fs.User_App_Last_Org, 'id'>
type T_User_Last_Org_Use = Pick<Schema_Fs.User_App_Last_Org, 'use'>

// The purpose of this service is to tie together the various firebase apis and application state management. Common ops will be to fetch data, perhaps shape it in some way, store it, then maybe listen for changes depending on needs of the caller.
@Injectable({ providedIn: 'root' })
export class Api_Service {
  // This tracks all calls to fetch data. The purpose is to assist in reducing total read operations to Firestore. Ideally, each async call will only conduct one fetch, and from then on will read from the cache. Also, whenever data is saved to Firestore, the component or DB layer will cache the new data locally and NGXS selectors will pick up the changes.
  fetched: Fetched = {
    events: {},
    org_members: {},
    org_member_invites: {},
  }
  user: any
  // Flag to indicate user's organization have been loaded.
  user_orgs_loaded = false

  constructor(
    private readonly _auths: Auth_Service,
    private readonly _fs_s: Firestore_Db_Service,
    private readonly _notification_s: NzNotificationService,
    private readonly _rtdb_s: Rtdb_Service,
    private readonly _ss: App_Store_Service,
    private readonly _store: Store,
    private readonly _storage_s: FB_Storage_Service,
    private readonly _template_s: Templates_Service,
    private readonly _us: User_Service,
  ) {
    this.user = this._us.get_user()
  }

  // Invites a new org member and stores the invitation.
	async add_org_member_invite(
    org_id: Schema_Fs.GUID,
    invite: Schema_Fs.Org_Member_Invite,
  ) {
    let res: DocumentReference
    try {
      res = await this._fs_s.add_org_member_invitation_job(org_id, invite)
    } catch (e) {
      throw e
    }

    if (res && !(res instanceof Error) && (res as DocumentReference).id) {
      const i: App_Fs_Org_Member_Invite = Object.assign(
        {},
        invite,
        { id: res.id },
        { link: get_invite_link(org_id, res.id) })
      // Make sure the date used is of Firestore flavor (UI needs it).
      i.created.on = Misc_Utils.get_fs_timestamp_from_date()
      // Cache it.
      this._ss.set_org_invite(org_id, i)
    }

    return res.id || ''
  }

  async delete_event(event_id: string){
    try {
      this._fs_s.delete_event(event_id)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async delete_event_admin(
    event_id: string,
    admin_id: string,
  ) {
    return this._rtdb_s.remove_event_admin(event_id, admin_id)
  }

  async delete_organization(org_id: Schema_Fs.GUID) {
    try {
      await this._fs_s.delete_organization(org_id)
      this._ss.delete_org(org_id)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async delete_org_member(
    org_id: Schema_Fs.GUID,
    member_id: Schema_Fs.GUID,
  ){
    try {
      await this._fs_s.delete_organization_member(org_id, member_id)
      this._ss.delete_org_member(org_id, member_id)
    } catch (e) {
      throw e
    }
  }

  async delete_org_member_invite(
    org_id: Schema_Fs.GUID,
    invite_id: Schema_Fs.GUID,
  ) {
    try {
      await this._fs_s.delete_organization_member_invitation(org_id, invite_id)
      // Remove from the cache.
      this._ss.delete_org_invite(org_id, invite_id)
    } catch (e) {
      throw e
    }
  }

  async get_fs_event(event_id: string, org: App_Fs_Org) {
      let event
      try {
        event = await this._fs_s.get_event(event_id)
      } catch(e) {
        console.error(e)
      }

      if (event) {
        // Set the member org.
        const app_event: App_Fs_Event = Misc_Utils.set_member_organization(event, org)

        // For events going on today, listen to it for changes. The reason for this is b/c an event director will need to open the event, and some people may be waiting on it to open.
        if (this.should_listen_to_event(app_event) && org) {
          // Provide the unpacker (no idea why this is necessary but only way to work).
          const cb = this.event_listener_cb(org).bind(this)
          try {
            this._fs_s.listen_on_document({
              path: fs_refs.event(app_event.id),
              callback: cb,
            })
          } catch (err) {
            console.error(`Error listening on event ${app_event.id}`, err)
          }
        }
        // Cache it.
        this._ss.set_org_event(app_event, org.id)
        return app_event
      }

      return undefined
  }

  async get_event_invite_key(event_id: string) {
    return await this._fs_s.get_event_public_invite(event_id)
  }

  async get_events(org: App_Fs_Org) {
    const events: App_Fs_Event[] = []

    if (this.fetched.events[org.id]) {
      // Since the events have already been fetched, just get them out of the store.
      return Object.values(this._ss.get_org_events(org.id))
    }

    if (!this.fetched.events[org.id]) {
      // First get the collection of events on the org (i.e. the event ids).
      let org_events: Schema_Fs.Org_Events | undefined
      try {
        org_events = await this._fs_s.get_organization_event_collection(org.id)
        this.fetched.events[org.id] = true
      } catch (e) {
        console.error(`Error fetching organization events for org id: ${org.id}.`)
        console.error(e)
      }

      if (org_events) {
        const parr: Promise<App_Fs_Event | undefined>[] = []
        // Now get the actual event record for each event.
        Object.values(org_events).forEach((event) => {
          parr.push(this.get_fs_event(event.id, org))
        })

        try {
          const events_pipeline = await Promise.all(parr)
          events_pipeline.forEach((event) => {
            if (event) {
              this._ss.set_org_event(event, org.id)
              events.push(event)
            }
          })
        } catch (e) {
          console.error(`Error fetching events for org: ${org.id}.`)
          console.error(e)
        } finally {
        }
      }
    }

    return events
  }

  async get_org_invites(
    org_id: string
  ){
    let invites: App_Fs_Org_Member_Invite[] = []

    if (this.fetched.org_member_invites[org_id]) {
      invites = Object.values(this._ss.get_org_invites(org_id))
    } else {
      try {
        const i = await this._fs_s.get_org_member_invitation_col(org_id)

        if (i) {
          invites = Object.values(i).map((invite) => {
            const obj: App_Fs_Org_Member_Invite = Object.assign(
              {},
              invite,
              { link: get_invite_link(org_id, invite.id) },
            )
            // Cache it.
            this._ss.set_org_invite(org_id, obj)
            return obj
          })
        }
        this.fetched.org_member_invites[org_id] = true
      } catch (e) {
        console.error(`Error fetching members for organization ${org_id}`)
      }
    }

    return invites
  }

  async get_org_members(org_id: string) {
    let members: App_Fs_Org_Member[] = []

    if (this.fetched.org_members[org_id]) {
      // Since the members have already been fetched, just get them out of the store.
      members = Object.values(this._ss.get_org_members(org_id))
    } else {
      try {
        const m = await this._fs_s.get_organization_member_collection(org_id)
        if (m) {
          members = Object.values(m)
          // Cache them.
          members.forEach((member) => {
            this._ss.set_org_member(member, org_id)
          })
        }
        this.fetched.org_members[org_id] = true
      } catch (e) {
        console.error(`Error fetching members for organization ${org_id}`)
      }
    }

    return members
  }

  async get_all_user_nodes() {
    const [user_app, user_private, user_public] = await Promise.all([
      this.get_user_app(),
      this.get_user_private(),
      this.get_user_public(),
    ])

    return { user_app, user_private, user_public }
  }

  private async get_user_app() {
    let user: Schema_Fs.User_App | undefined
    const uid: string = this._us.get_user_id()

    try {
      user = await this._fs_s.get_user_app(uid)
    } catch (e) {
      console.error('Error fetching user: ', e)
      throw(e)
    }

    if (user) {
      this._ss.set_user_app(user)
    }

    return user
  }

  private async get_user_private() {
    let user: Schema_Fs.User_Private | undefined
    const uid: string = this._us.get_user_id()

    try {
      user = await this._fs_s.get_user_private(uid)
    } catch (e) {
      console.error('Error fetching user: ', e)
      throw(e)
    }

    if (user) {
      this._ss.set_user_private(user)
    }

    return user
  }

  private async get_user_public() {
    let user: Schema_Fs.User_Public | undefined
    const uid = this._auths.user_id

    try {
      user = await this._fs_s.get_user_public(uid)
    } catch (e) {
      console.error('Error fetching user: ', e)
      throw(e)
    }

    if (user) {
      this._ss.set_user_public(user)
      this._fs_s.listen_on_document({
        path: fs_refs.user_public(uid),
        // cb needs 'this' context
        callback: this.listener_cb_user_public.bind(this),
      })
    }

    return user
  }

  async get_user_orgs() {
    const uid: string = this._us.get_user_id()
    // Get all the orgs first to synchonously populate the application state.
    let user_orgs: Record<string, Schema_Fs.User_Org_Doc> | undefined

    user_orgs = await this._fs_s.get_user_organization_collection(uid)

    if (user_orgs) {
      const org_pipeline: Promise<App_Fs_Org | undefined>[] = []

      Object.values(user_orgs).forEach((org: Schema_Fs.User_Org_Doc) => {
        org_pipeline
          .push(this._fs_s
            .get_organization_with_current_user_member_data(org.id!, this._us.user_id)
            .catch((e) => {
              console.error(`Error fetching org ${org.id}: `)
              console.error(e)
              return undefined
            })
          )
      })

      const orgs: (App_Fs_Org | undefined)[] = await Promise.all(org_pipeline)

      orgs.forEach((org, index) => {
        if (!org) {
          console.warn(`User org ${Object.values(user_orgs!)[index].id} exists, but organization does not.`)
        } else {
          this._ss.set_org(org)
        }
      })
      this.user_orgs_loaded = true
    }

    // Don't think we need to listen on this????
    // this._fs_s.listen_on_collection({
    //   path: fs_refs.user_organizations_collection(uid),
    //   callback: this.listener_cb_user_org.bind(this),
    // })
  }

  private event_listener_cb(member_org?: App_Fs_Org) {
    const self = this
    return function(snap: DocumentSnapshot) {
      if (snap.exists) {
        const fs_event: Schema_Fs.Event | undefined = <Schema_Fs.Event>utils.unpack_fs_doc(snap)
        // Set the member org.
        const app_event = Misc_Utils.set_member_organization(fs_event, member_org)
        self._ss.set_org_event(app_event, app_event.member_organization.id)
      }
    }
  }

  async set_event_admin(event_id: string, ids: string[]) {
    try {
      await this._rtdb_s.set_event_admins(event_id, ids)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async delete_event_tag_member(event_id: string, tag: string) {
    try {
      await this._rtdb_s.delete_event_tag_member(event_id, tag)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  // async set_event_tag_member(event_id: string, tag: string) {
  //   try {
  //     await this._rtdb_s.set_event_tag_member(event_id, tag)
  //     return 0
  //   } catch (e) {
  //     console.error(e)
  //     return 1
  //   }
  // }

  async put_org_logo(
    file: File,
    org_id: string,
  ) {
    const ref_original = FB_Refs_Storage.organization_logo_original(org_id)
    const ref_thumbnail = FB_Refs_Storage.organization_logo_thumbnail(org_id)
    const thumbnail = await get_image_thumbnail(file)

    // Put the files.
    try {
      await Promise.all([
        this._storage_s.put_file({
          data: file,
          ref: ref_original,
        }),
        this._storage_s.put_file({
          data: thumbnail,
          ref: ref_thumbnail,
        }),
      ])

      // Get their download URLs.
      const urls = await Promise.all([
        this._storage_s.get_download_url(ref_original),
        this._storage_s.get_download_url(ref_thumbnail),
      ])

      // Update the Organization table with logo urls
      const logos: Schema_Fs.Org_Logos = {
        original: urls[0],
        thumbnail: urls[1],
      }
      await this._fs_s.update_organization_logos(org_id, logos)
      // Update the local store.
      const org = Object.assign({}, this._ss.get_org(org_id))
      org.urls = Object.assign({}, org.urls || {}, {logos})
      this._ss.set_org(org)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }

    // For when uploading via Cloud Function is FIGURED OUT.
    // let res
    // try {
    //   res = await this.fb_s.callable_put_org_logo(
    //     file as any as File,
    //     this.org_s.oid.getValue(),
    //   )
    // } catch (e) {
    //   console.log(e)
    // }
  }

  async respond_to_org_invitation(
    status: Schema_Fs.ORG_MEMBER_INVITATION_STATUS,
    org: Schema_Fs.Organization & Schema_Fs.GUID_Type,
    invite_id: string,
  ) {

    try {
      // First respond to the invite.
      // Using a transaction b/c the org owner may delete the invite and all ops must succeed.
      await this._fs_s.set_org_member_invitation(status, org.id, invite_id, this._us.user_id)
    } catch (e) {
      console.error(e)
      return 1
    }

    // If invite was accepted, cache org locally so it's available for the user right away.
    if (status === Schema_Fs.ORG_MEMBER_INVITATION_STATUS.ACCEPTED) {
      const app_org: App_Fs_Org = Object.assign({}, org, {
        joined_on: Misc_Utils.get_fs_timestamp_now(),
        role: Schema_Fs.ORG_MEMBER_ROLE.MEMBER,
      })

      this._ss.set_org(app_org)
    }

    return 0
  }

  // Replaces the existing event invite link with a new one.
  async revoke_event_invite(event_id: string) {
    // Create a new one and update the event with the new link.
    let invite_id
    try {
      invite_id = this._fs_s.update_event_invite_key(event_id)
    } catch (e) {
      return undefined
    }
    return invite_id
  }

  async set_user_app(data: User_App) {
    await this._fs_s.set_user_app(data, this._us.user_id)
    this._ss.set_user_app(data)
  }

  async set_user_app_last_org_id(id: string) {
    const user_app = this._ss.get_user_app()
    const base = { id: '', use: true }
    const last_org = user_app.last_org || base

    const new_last_org = merge_data<
      Schema_Fs.User_App_Last_Org, T_User_Last_Org_Id
    >(last_org, { id })

    const new_user_app = merge_data<
      User_App, T_User_App_Last_Org
    >(user_app, { last_org: new_last_org })

    await this.set_user_app(new_user_app)
  }

  async set_user_app_last_org_use(use: boolean) {
    const user_app = this._ss.get_user_app()
    const base = { id: '', use: true }
    const last_org = user_app.last_org || base

    const new_last_org = merge_data<
    Schema_Fs.User_App_Last_Org, T_User_Last_Org_Use
    >(last_org, { use })

    const new_user_app = merge_data<
      User_App, T_User_App_Last_Org
    >(user_app, { last_org: new_last_org })

    await this.set_user_app(new_user_app)
  }

  async set_user_private(data: User_Private) {
    await this._fs_s.set_user_private(data, this._us.user_id)
    this._ss.set_user_private(data)
  }

  private listener_cb_org(change: DocumentSnapshot) {
    if (change.exists) {
      // Using non-null assertion (!) b/c change.exists check confirms it's there.
      const change_org = utils.unpack_fs_doc<Schema_Fs.Organization>(change)!
      let merge_org: App_Fs_Org = this._ss.get_orgs()[change_org!.id!]

      if (merge_org) {
        // Merged the new and old data.
        merge_org = Object.assign({}, merge_org, change_org)
      }

      this._ss.set_org(merge_org)
    }
  }

  private async listener_cb_user_org(snapshot: QuerySnapshot) {
    const changes: DocumentChange[] = []
    snapshot.docChanges().forEach((change: DocumentChange) => {
      changes.push(change)
    })

    for (let i = 0; i < changes.length; i += 1) {
      const change = changes[i]
      // Using non-null assertion (!), b/c, well, it was 'added', and so it's there.
      const user_org = utils.unpack_fs_doc<Schema_Fs.User_Org_Doc>(change.doc)!
      const oid = user_org!.id

      // For each new org added to the user's orgs, we need to fetch the org node and cache it.
      if (change.type === 'added') {
        // Check to make sure this data has not been fetched (and thus listened on).
        const exisiting_org: App_Fs_Org = this._ss.get_orgs()[oid]

        if (!exisiting_org) {
          let org: App_Fs_Org | undefined
          try {
            org = await this._fs_s.get_organization_with_current_user_member_data(oid, this._us.user_id)
            // Check for data in the event the user has the org, but it does not exist.
            if (!org) return
            else this._ss.set_org(org)
          } catch (e) {
            if (e.toString().includes(ERROR_CODES.ORG_DNE)) {
              console.warn(`User org ${user_org.id} exists, but organization does not.`)
            } else {
              console.error(`Error fetching org ${user_org.id}:`, e)
            }
            return
          }
        }

        // Also listen on the org for changes, and ensure service is only called once.
        this._fs_s.listen_on_document({
          path: fs_refs.organization(oid),
          callback: this.listener_cb_org.bind(this),
        })
      }
      if (change.type === 'removed') {
        if (this._ss.get_orgs()[oid]) {
          this._ss.delete_org(oid)
        }
      }
    }
  }

  private listener_cb_user_public(snap: DocumentSnapshot) {
    const user: Schema_Fs.User_Public | undefined
      = <Schema_Fs.User_Public>utils.unpack_fs_doc(snap)
    if (user) {
      this._ss.set_user_public(user)
    }
  }

  // To determine if need to initiate event listener, event should not have already been listened on, should be open, and should be ongoing.
  private should_listen_to_event(event: App_Fs_Event) {
    const ref = `${LISTENER_TYPE_FS.ON_SNAPSHOT}.${fs_refs.event(event.id!)}`
    return !_get(this._store.snapshot().listeners, ref)
      && (
        // Always listen to the event if they are the creator.
        event.audit.created.by.user.id === this._us.get_user_id()
        || EDU.is_in_event_date_window(event.capacity)
      )
    }

  async update_event(event_id: string, updates: UpdateData) {
    try {
      await this._fs_s.update_event(event_id, updates)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async update_username(name: string) {
    await this._fs_s.update_username(name, this._us.user_id)
    await Promise.all([
      this._fs_s.update_username(name, this._us.user_id),
      this._fs_s.put_user_name_change_job(name, this._us.user_id),
    ])
  }


  async update_organization_member_status(
    org_id: Schema_Fs.GUID,
    member: App_Fs_Org_Member,
    data: { role: Schema_Fs.ORG_MEMBER_ROLE },
  ) {
    await this._fs_s.update_organization_member_status(
      org_id,
      member.id,
      data,
    )

    const new_member: App_Fs_Org_Member = Object.assign({}, member, data)
    this._ss.set_org_member(new_member, org_id)
  }
}


function get_invite_link(oid: string, iid: string) {
  return `${env.urls.app}` +
  `${Routes.str.organization_member_invite_landing_page(oid, iid)}`
}

function merge_data<T, U>(old_data: T, new_data: U) {
  const o = clonedeep(old_data)
  return Object.assign(o, new_data)
}
