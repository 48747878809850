<form
  class='width-wrapper'
  autocomplete="off"
  novalidate
  nzNoColon
  nz-form
  nzLayout='vertical'
  [formGroup]="form"
  (ngSubmit)="submit_form()"
  (keydown.enter)="$event.preventDefault()">

  <div class='w100'>
    <nz-form-item class='p-rel'>
      <nz-form-control nzHasFeedback>
        <nz-input-group nzSize="large">
          <input
            formControlName="name"
            id="name"
            placeholder='Enter your name'
            nz-input>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control class='ta-right'>
        <button
          nzType='primary'
          nz-button
          [nzLoading]='submitting'
          nzSize='large'
          [disabled]="!form.valid || submitting"
          >Save</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
