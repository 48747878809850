import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  CheckboxRequiredValidator,
} from '@angular/forms'
import { COLOR_PALETTE as CP } from '@ws/constants'
import { environment } from '@app/env'
import { Auth_Service } from "@app/services/auth.service"
import { Routes } from "@ws/constants"
import { User_Service } from '@app/services/user.service'
import { Misc_Utils } from '@app/utils'

@Component({
  selector: 'auth-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Register_Component implements OnInit {
  done = false
  form!: FormGroup
  logo_fill = CP.PURPLE
  submitting = false
  url_app = environment.urls.public
  url_login = Routes.arr.login()
  url_pp = environment.urls.pp
  url_tos = environment.urls.tos

  constructor(
    private readonly auth_s: Auth_Service,
    private readonly _cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly message_s: NzMessageService,
    private readonly _us: User_Service,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.validator_confirmation]],
      agree: [null, [new CheckboxRequiredValidator(), Validators.requiredTrue]],
    })
  }

  async submit_form(): Promise<any> {
    if (this.submitting) {
      return
    }

    this.submitting = true
    const message = this.message_s.loading(
      'Creating your account..',
      { nzDuration: 0 },
    ).messageId

    if (!this.form.valid) {
      this.submitting = false
      return
    }

    // By now these values are guaranteed to be here via ng form validators.
    // However, we must appease the tslint Gods and check.
    const email = this.form.get('email')! && this.form.get('email')!.value
    const pass = this.form.get('password') && this.form.get('password')!.value
    if (!email || !pass) {
      return
    }

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsDirty()
      this.form.controls[key].updateValueAndValidity()
      this.form.controls[key].disable()
    })

    let creds: firebase.default.auth.UserCredential

    try {
      creds = await this.auth_s.register_email(email, pass)

      if (!creds) {
        throw new Error('Registration failed for reasons unknown.')
      }

      this.save_user(creds)
      this.done = true
      this._cd.detectChanges()
    } catch (e) {
      this.message_s.error(e.toString(), { nzDuration: 5000 })
      // Re-enabled the form controls.
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].enable({emitEvent: false})
      })
    } finally {
      this.submitting = false
      this.message_s.remove(message)
    }
  }

  private async save_user(creds: firebase.default.auth.UserCredential): Promise<void> {
    // Save the user
    const uid = creds.user && creds.user.uid
    const name = Misc_Utils.trim_white(this.form.get('name')! && this.form.get('name')!.value)

    if (!uid || !name || !name.length) {
      return
    }
    // It's ok for errors to be swallowed here, since we are just saving user name. It can be added later by user.
    try {
      await this._us.update_user_public_name(uid, {name})
    } catch (e) {
      console.info('User name could not be saved..beep boop...')
    }
  }

  validator_update_confirm(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.form.controls.checkPassword.updateValueAndValidity())
  }

  validator_confirmation = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.form.controls.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }
}
