<form
  autocomplete="off"
  class='animated fadeIn'
  novalidate
  nzNoColon
  nz-form
  [formGroup]="form"
  (ngSubmit)="submit_form()"
  (keydown.enter)="$event.preventDefault()">

  <div class='w100'>

    <nz-form-item class='p-rel'>
      <nz-form-label
        [ngClass]="{'hidden': !show_labels}"
        nzFor="name">Name</nz-form-label>
      <nz-form-control
        nzHasFeedback
        [nzErrorTip]="name_errors_tmpl"
        nzValidatingTip="Checking if name exists...">
        <nz-input-group nzSize="large">
          <input
            formControlName="name"
            id="name"
            placeholder='Enter a name for your organization'
            nz-input
            [maxlength]='name_length_limit'>
          <ng-template #name_errors_tmpl let-control>
            <ng-container *ngIf="control.hasError('unavailable')">
              That name is already being used
            </ng-container>
            <ng-container *ngIf="control.hasError('required')">
              Please input a name
            </ng-container>
          </ng-template>
          <span class='length-limit'>
            {{ name_length + ' / ' + name_length_limit }}
          </span>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class='submit-button-container'>
      <nz-form-control
        [ngStyle]="{'text-align': (button_align ? button_align : 'center')}">
        <button
          nzType="primary"
          nz-button
          [nzLoading]='submitting'
          [nzSize]='button_size'
          [disabled]="!form.valid || submitting"
          >{{button_text || 'Submit'}}</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
