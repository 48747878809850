import { STORE_ENTITIES } from '@app/constants';
import { Org_Member_Invite, GUID_Type } from "@ws/schema-fs";
import { App_Fs_Org_Member_Invite } from '@app/types';

export class Delete_Invite {
  public static readonly type = `[${STORE_ENTITIES.FS_ORG_MEMBER_INVITES}] Delete Invite`;
  constructor(
    public organization_id: string,
    public invite_id: string,
  ) {}
}

export class Set_Invite {
  public static readonly type = `[${STORE_ENTITIES.FS_ORG_MEMBER_INVITES}] Set Invite`;
  constructor(
    public organization_id: string,
    public payload: App_Fs_Org_Member_Invite,
  ) { }
}


