import { NgModule } from '@angular/core'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzListModule } from 'ng-zorro-antd/list'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { NzResultModule } from 'ng-zorro-antd/result'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzStepsModule } from 'ng-zorro-antd/steps'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzTypographyModule } from 'ng-zorro-antd/typography'
import { NzUploadModule } from 'ng-zorro-antd/upload'


/**
 * This module simply supplies all of the ng-zorro UI components that the
 * application uses. Importing individual components rather than the entire
 * library provides a smaller bundle size.
 * TODO: Do away with this by importing components into individual files.
 */
@NgModule({
  declarations: [],
  imports: [
    NzAlertModule,
    NzAvatarModule,
    NzBadgeModule,
    NzBreadCrumbModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzFormModule,
    NzGridModule,
    NzListModule,
    NzMenuModule,
    NzMessageModule,
    NzModalModule,
    NzNotificationModule,
    NzPageHeaderModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzRadioModule,
    NzResultModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpinModule,
    NzStepsModule,
    NzSwitchModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzTimePickerModule,
    NzToolTipModule,
    NzTypographyModule,
    NzUploadModule,
  ],
  exports: [
    NzAlertModule,
    NzAvatarModule,
    NzBadgeModule,
    NzBreadCrumbModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzEmptyModule,
    NzFormModule,
    NzGridModule,
    NzListModule,
    NzMenuModule,
    NzMessageModule,
    NzModalModule,
    NzNotificationModule,
    NzPageHeaderModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzRadioModule,
    NzResultModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpinModule,
    NzStepsModule,
    NzSwitchModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzTimePickerModule,
    NzToolTipModule,
    NzTypographyModule,
    NzUploadModule,
  ],
})
export class Zorro_UI_Module { }
