import { Interfaces } from '@ws/constants'

interface Domains {
  app: string
  info: string
  pp: string
  pricing: string
  public: string
  tos: string
}

interface Env_Buckets {
  base: Interfaces.Buckets,
  develop: Interfaces.Buckets,
  main: Interfaces.Buckets,
}

export enum ENV_NAMES {
  BASE = '',
  DEV = 'dev',
  LOCAL = 'local',
  DEVELOP = 'develop',
  MAIN = 'main',
  STAGE = 'stage',
  QA = 'qa',
}

export const FIREBASE_BASE_CONFIG = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
}

const pp = 'https://info.yaatly.com/legal/pr'
const tos = 'https://info.yaatly.com/legal/terms-of-service'

interface Env_Domains {
  yaatly: {
    base: Domains
    dev: Domains
    local: Domains
    develop: Domains
    main: Domains
  }
  moot: {
    base: Domains
    dev: Domains
    local: Domains
    develop: Domains
    main: Domains
  }
  jessup: {
    base: Domains
    dev: Domains
    local: Domains
    develop: Domains
    main: Domains
  }
}

export const Domains: Env_Domains = {
  yaatly: {
    base: {
      app: '',
      info: '',
      pp: '',
      pricing: '',
      public: '',
      tos: '',
    },
    local: {
      app: 'http://localhost:4200',
      info: 'https://info.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    dev: {
      app: 'http://localhost:4200',
      info: 'https://info.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    develop: {
      app: 'https://yaatly-master-app.web.app',
      info: 'https://info.yaatly.com/',
      pp,
      pricing: 'https://master.yaatly.com/pricing',
      public: 'https://master.yaatly.com',
      tos,
    },
    main: {
      app: 'https://app.yaatly.com',
      info: 'https://info.yaatly.com/',
      pp,
      pricing: 'https://yaatly.com/pricing',
      public: 'https://yaatly.com',
      tos,
    },
  },
  moot: {
    base: {
      app: '',
      info: '',
      pp: '',
      pricing: '',
      public: '',
      tos: '',
    },
    local: {
      app: 'http://localhost:4200',
      info: 'https://info.moot.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    dev: {
      app: 'http://localhost:4200',
      info: 'https://info.moot.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    develop: {
      app: 'https://moot-develop-client.web.app/',
      info: 'https://info.moot.yaatly.com/',
      pp,
      pricing: 'https://master.yaatly.com/pricing',
      public: 'https://master.yaatly.com',
      tos,
    },
    main: {
      app: 'https://moot.yaatly.com',
      info: 'https://info.moot.yaatly.com/',
      pp,
      pricing: 'https://yaatly.com/pricing',
      public: 'https://yaatly.com',
      tos,
    },
  },
  jessup: {
    base: {
      app: '',
      info: '',
      pp: '',
      pricing: '',
      public: '',
      tos: '',
    },
    local: {
      app: 'http://localhost:4200',
      info: 'https://info.jessup.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    dev: {
      app: 'http://localhost:4200',
      info: 'https://info.jessup.yaatly.com/',
      pp,
      pricing: 'https://localhost:4201/pricing',
      public: 'http://localhost:4201',
      tos,
    },
    develop: {
      app: 'https://jessup-develop-client.web.app/',
      info: 'https://info.jessup.yaatly.com/',
      pp,
      pricing: 'https://master.yaatly.com/pricing',
      public: 'https://master.yaatly.com',
      tos,
    },
    main: {
      app: 'https://jessup.yaatly.com',
      info: 'https://info.jessup.yaatly.com/',
      pp,
      pricing: 'https://yaatly.com/pricing',
      public: 'https://yaatly.com',
      tos,
    },
  }
}

export const Buckets: Env_Buckets = {
  base: {
    default: '',
  },
  develop: {
    default: 'yaatly-master.appspot.com',
  },
  main: {
    default: 'yaatly-prod.appspot.com',
  },
  // qa: {},
}
