import {
  Action,
  State,
  Selector,
  StateContext,
  StateToken,
} from '@ngxs/store'
import { Delete_Invite, Set_Invite } from './actions'
import { STORE_ENTITIES } from "@app/constants"
import produce from 'immer'
import { GUID_Type } from '@ws/schema-fs'
import { App_Fs_Org_Member_Invite } from '@app/types'
import { Injectable } from '@angular/core'


// For member state, we are grouping members under their respective organizations. Why? B/c members will be accessed this way in most of the app.
export type Fs_Org_Member_Invite_State_Model = Record<string,
  Record<string, App_Fs_Org_Member_Invite & GUID_Type>
>

export const FS_ORG_MEMBER_INVITE_STATE_TOKEN = new StateToken<Fs_Org_Member_Invite_State_Model>(STORE_ENTITIES.FS_ORG_MEMBER_INVITES)


@State<Fs_Org_Member_Invite_State_Model>({
  name: FS_ORG_MEMBER_INVITE_STATE_TOKEN,
})

@Injectable()
export class Fs_Org_Member_Invite_State {
  @Selector()
  public static getState(state: Fs_Org_Member_Invite_State_Model) {
      return state
  }

  // Lazy selectors: https://www.ngxs.io/concepts/select#lazy-selectors.
  @Selector()
  static org_invites(state: Fs_Org_Member_Invite_State_Model) {
    return (org_id: string) => {
      const s = state[org_id] || {}
      return s
    }
  }

  @Action(Delete_Invite)
  private delete_invite(
    ctx: StateContext<Fs_Org_Member_Invite_State_Model>,
    action: Delete_Invite,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const oid = action.organization_id
        const iid = action.invite_id
        delete draft[oid][iid]
      }),
    )
  }

  @Action(Set_Invite)
  private set_invite(
    ctx: StateContext<Fs_Org_Member_Invite_State_Model>,
    action: Set_Invite,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const iid = action.payload.id
        const oid = action.organization_id
        // If the org doesn't exist, add it now.
        if (!draft[oid]) draft[oid] = {}
        draft[oid][iid] = action.payload
      }),
    )
  }

}
