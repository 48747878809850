// This static class is the central place to get routes for the entire
export class Routes {
  static str = {
    auth_root() {
      return 'a'
    },

    event(eid: string, use_root: boolean = true) {
      return `${Routes.str.event_root()}/${eid}`
    },

    event_invite_landing_page(
      eid: string,
      iid: string,
      use_root: boolean = true
    ) {
      const route = `${Routes.str.event_root()}/${eid}/invitation/${iid}`
      return use_root ? Routes.str.root().concat(route) : route
    },

    event_root() {
      return 'e'
    },

    event_anon_join(use_root: boolean = true) {
      const route = 'a'
      return use_root ? Routes.str.root().concat(route) : route
    },

    event_room(
      eid: string,
      rid: string,
      use_root: boolean = true,
    ) {
      const event = Routes.str.event(eid, use_root)
      return `${event}/r/${rid}`
    },

    event_jitsi_room(
      eid: string,
      rid: string,
      use_root: boolean = true,
    ) {
      const event = Routes.str.event(eid, use_root)
      return `${event}/r/${Routes.str.jitsi_path()}/${rid}`
    },

    event_posting_room(p: {
      eid: string,
      pid: string,
      cid: string,
      rid: string,
      use_root?: boolean,
    }) {
      const use_root = p.use_root ?? true
      const event = Routes.str.event(p.eid, use_root)
      return `${event}/r/${p.rid}/p/${p.pid}/c/${p.cid}`
    },

    event_jitsi_posting_room(p: {
      eid: string,
      pid: string,
      cid: string,
      rid: string,
      use_root?: boolean,
    }) {
      const use_root = p.use_root ?? true
      const event = Routes.str.event(p.eid, use_root)
      return `${event}/r/${Routes.str.jitsi_path()}/${p.rid}/p/${p.pid}/c/${p.cid}`
    },

    event_create_posting(
      eid: string,
      use_root: boolean = true,
    ) {
      const event = Routes.str.event(eid, use_root)
      return `${event}/create-posting`
    },

    first_organization(use_root: boolean = true) {
      const route = 'my-first-organization'
      return use_root ? Routes.str.root().concat(route) : route
    },

    home_user_account(use_root: boolean = true) {
      const route = 'account'
      return use_root ? Routes.str.root().concat(route) : route
    },

    incognito_logout(use_root: boolean = true) {
      const route = 'incogout'
      return use_root ? Routes.str.root().concat(route) : route
    },


    jitsi_path() {
      return 'jitsi'
    },

    login(use_root: boolean = true) {
      const route = `${Routes.str.auth_root()}/login`
      return use_root ? Routes.str.root().concat(route) : route
    },

    new_organization(use_root: boolean = true) {
      const route = 'new-organization'
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_root(use_root: boolean = true) {
      const route = 'o'
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization(oid: string, use_root: boolean = true) {
      const route = `o/${oid}`
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_billing(oid: string, use_root: boolean = true) {
      const route = `o/${oid}/billing`
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_events(oid: string, use_root: boolean = true) {
      const route = `o/${oid}/events`
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_members(oid: string, use_root: boolean = true) {
      const route = `o/${oid}/members`
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_member_invite_landing_page(
      oid: string,
      iid: string,
      use_root: boolean = true
    ) {
      const route = `o/${oid}/invitation/${iid}`
      return use_root ? Routes.str.root().concat(route) : route
    },

    organization_settings(oid: string, use_root: boolean = true) {
      const route = `o/${oid}/settings`
      return use_root ? Routes.str.root().concat(route) : route
    },

    register(use_root: boolean = true) {
      const route = `${Routes.str.auth_root()}/register`
      return use_root ? Routes.str.root().concat(route) : route
    },

    reset_password(use_root: boolean = true) {
      const route = `${Routes.str.auth_root()}/reset-password`
      return use_root ? Routes.str.root().concat(route) : route
    },

    root() {
      return '/'
    }
  }

  static arr = {
    draw_room(eid: string, use_root: boolean = true): string[] {
      return Routes.arr.event_root(use_root).concat([eid, 'draw'])
    },

    event(eid: string, use_root: boolean = true): string[] {
      return Routes.arr.event_root(use_root).concat([eid])
    },

    event_anon_join(use_root: boolean = true, eid: string): string[] {
      const route = ['e', eid, 'a', 'login']
      return get_arr_route(route, use_root)
    },

    event_root(use_root: boolean = true): string[] {
      const route = ['e']
      return get_arr_route(route, use_root)
    },

    event_room(eid: string, rid: string, use_root = true): string[] {
      const route = [eid, 'r', rid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_jitsi_room(eid: string, rid: string, use_root = true): string[] {
      const route = [eid, 'r', Routes.str.jitsi_path(), rid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_custom_group_room({
      event_id, room_id, group_id, use_root = true
    } : {
      event_id: string
      room_id: string
      group_id: string
      use_root?: boolean,
    }): string[] {
      const route = [event_id, 'r', Routes.str.jitsi_path(), room_id, 'g', group_id]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_social_room(eid: string, rid: string, use_root = true): string[] {
      const route = [eid, 'r', Routes.str.jitsi_path(), rid, 'social',]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_hq_room(eid: string, rid: string, use_root = true): string[] {
      const route = [eid, 'r', Routes.str.jitsi_path(), rid, 'hq',]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_team_jitsi_room(
      eid: string,
      rid: string,
      oid: string,
      use_root = true
    ): string[] {
      const route = [eid, 'r', Routes.str.jitsi_path(), rid, 'o', oid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_posting_room(p: {
      eid: string,
      pid: string,
      cid: string,
      rid: string,
      use_root?: boolean,
    }): string[] {
      const use_root = p.use_root ?? true
      const route = [p.eid, 'r', p.rid, 'p', p.pid, 'c', p.cid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_jitsi_posting_room(p: {
      eid: string,
      pid: string,
      cid: string,
      rid: string,
      use_root?: boolean,
    }) {
      const use_root = p.use_root ?? true
      const route = [p.eid, 'r', Routes.str.jitsi_path(), p.rid, 'p', p.pid, 'c', p.cid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    event_jitsi_posting_sibling_room(p: {
      eid: string,
      pid: string,
      cid: string,
      rid: string,
      use_root?: boolean,
    }) {
      const use_root = p.use_root ?? true
      const route = ['r', Routes.str.jitsi_path(), p.rid, 'p', p.pid, 'c', p.cid]
      return Routes.arr.event_root(use_root).concat(route)
    },

    first_org(use_root: boolean = true) {
      const route = ['create-new-organization']
      return get_arr_route(route, use_root)
    },

    home_user_account(use_root: boolean = true) {
      const route = ['account']
      return get_arr_route(route, use_root)
    },

    home_user_profile(use_root: boolean = true) {
      const route = ['account', 'profile']
      return get_arr_route(route, use_root)
    },

    incognito_logout(use_root: boolean = true) {
      const route = ['incogout']
      return get_arr_route(route, use_root)
    },

    login(use_root: boolean = true): string[] {
      const route = [Routes.str.auth_root(), 'login']
      return get_arr_route(route, use_root)
    },

    new_organization(use_root = true): string[] {
      const route = ['new-organization']
      return get_arr_route(route, use_root)
    },

    organization(oid: string, use_root: boolean = true): string[] {
      const route = ['o', oid]
      return get_arr_route(route, use_root)
    },

    organization_events(oid: string, use_root: boolean = true): string[] {
      const route = ['o', oid, 'events']
      return get_arr_route(route, use_root)
    },

    register(use_root: boolean = true): string[] {
      const route = [Routes.str.auth_root(), 'register']
      return get_arr_route(route, use_root)
    },

    reset_password(use_root: boolean = true): string[] {
      const route = [Routes.str.auth_root(), 'reset-password']
      return get_arr_route(route, use_root)
    },

    root() {
      return ['/']
    },
  }
}

function get_arr_route(route: string[], use_root: boolean) {
  if (use_root) {
    route[0] = `${Routes.arr.root()}${route[0]}`
  }

  return route
}
