import { NgModule, APP_INITIALIZER } from '@angular/core'
import { App_Load_Service } from './root-load.service'


const init_app = (loader: App_Load_Service) => {
  return async () => {
    await loader.init()
  }
}

// Multiple providers can be used if desired. Note they run concurrently.
// const get_settings = (appLoadService: AppLoadService) => {
//   return () => appLoadService.getSettings();
// }

@NgModule({
  providers: [
    App_Load_Service,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [App_Load_Service],
      multi: true
    },
    // Multiple providers can be used if desired. Note they run concurrently.
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: get_settings,
    //   deps: [AppLoadService],
    //   multi: true
    // },
  ]
})
export class App_Load_Module { }


// For ref: https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/
