import { I_Event_Capacity } from '@ws/schema-fs'

const formatUSD = require('format-usd')

// In cents.
export const CREDIT_RATES = {
  499: 50,
  999: 45,
  2499: 40,
  4999: 35,
  9999: 30,
  19999: 25,
  1000000000000: 20,
}

export const calc_credit_rate = (
  credits: number,
  in_dollars = false,
) => {
  for (const [tier, rate] of Object.entries(CREDIT_RATES)) {
    if (credits <= parseInt(tier, 10)) {
      const ret = in_dollars ? rate * 0.01 : rate
      return in_dollars ? rate * 0.01 : rate
    }
  }

  return in_dollars ? CREDIT_RATES[499] * .01 : CREDIT_RATES[499]
}

export const calc_credits_price = (
  credits: number,
  in_dollars = false,
) => {
  return in_dollars
    ? format_price_to_usd(calc_credit_rate(credits, in_dollars) * credits)
    : calc_credit_rate(credits, false) * credits
}

export const format_credits_price_in_usd = (credits: number) => {
  return format_price_to_usd(calc_credit_rate(credits, true) * credits)
}

export const calc_credits_price_amount = (credits: number) => {
  return calc_credit_rate(credits, false) * credits
}

export const calc_credit_rate_in_usd = (credits: number) => {
  return format_price_to_usd(calc_credit_rate(credits, true))
}

export const calc_day_credits_needed = (p: {
  start: number,
  end: number,
  capacity: number
}) => {
  if (p.end <= p.start) {
    throw new Error('end is less than start')
  }
  const { start, end, capacity } = p
  const diff = end - start
  const hours = get_hours_from_ms(diff)
  return parseFloat((hours * capacity).toFixed(2))
}

export const calc_event_credit_cost = (c: I_Event_Capacity) => {
  return Object.entries(c).reduce((acc, entry) => {
    const times = entry[0]
    const capacity = entry[1]
    const [start, end] = times.split('-')
    const day_credits = calc_day_credits_needed({
      start: parseInt(start, 10),
      end: parseInt(end, 10),
      capacity,
    })
    return acc + day_credits
  }, 0)
}

export const get_refund_credits_for_delete_op = (
  key: string,
  capacity: number
) => {
  const [start, end] = key.split('-')
  const start_ms = parseInt(start, 10)
  const end_ms = parseInt(end, 10)
  const hours = (end_ms - start_ms) / (1000 * 60 * 60)
  return hours * capacity
}

function format_price_to_usd(amount: number) {
  return formatUSD({ amount }) as string
}

function get_hours_from_ms(ms: number) {
  return (ms / (1000 * 60 * 60)) % 24
}

function get_minutes_from_ms(ms: number) {
  return (ms / (1000 * 60)) % 60
}

function get_seconds_from_ms(ms: number) {
  return (ms / 1000) % 60
}
