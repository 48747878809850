<h2 class='header'>
  <span class='header__text'>Sorry to bother you</span> &nbsp;😅
</h2>

<h4>We noticed your user name is missing. Will you please take a moment to update it?</h4>

<br>

<modal-username-change
  [modal_action]=modal_action>
</modal-username-change>
