import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'shared-textarea-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Shared_Textarea_Input_Component {
  saving = false
  @Input() input_action!: (input: string) => any
  @Input() input_value = ''
  @Input() input_id = ''
  @Input() input_rows = 4
  @Input() input_placeholder = ''
  @Input() input_button_size = 'small'
  @Input() input_button_type = 'primary'
  @Output() done = new EventEmitter<any>()
  @ViewChild('textarea', {static:false}) textarea?: ElementRef

  constructor(
    private readonly _cd: ChangeDetectorRef,
  ) {}

  async save() {
    if (this.saving) return
    this.saving = true
    const value = (this.textarea?.nativeElement as HTMLTextAreaElement).value?.trim()
    await this.input_action(value)
    this.saving = false
    this.done.next()
    this._cd.detectChanges()
  }


}
