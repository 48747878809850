import { Injectable, Inject } from '@angular/core'
import { App_Config } from '@ws/constants'
import safeStringifyJSON from 'json-stringify-safe'
import { ENV_CONFIG, I_Client_Env } from '@client/providers'

interface I_Meta  { [key: string]: any }

@Injectable({ providedIn: 'root'})
export class App_Logging_Service {
  private readonly _is_dev: boolean
  private _ctx: { [key: string]: any } = {}

  constructor(@Inject(ENV_CONFIG) env: I_Client_Env) {
    this._is_dev = env.env_name !== App_Config.ENV_NAMES.MAIN
  }

  debug(msg: string, meta: I_Meta = {}, trace = false) {
    if (this._is_dev) {
      if (trace) {
        console.trace(msg)
      } else {
        console.debug(msg)
      }
      console.debug(this._build_log(meta))
    }
  }

  error(msg: string, meta: I_Meta = {}) {
    console.error(msg)
    if (Object.keys(meta).length) {
      console.error(this._build_log(meta))
    }
  }

  info(msg: string, meta: I_Meta = {}) {
    console.info(msg)
    if (Object.keys(meta).length) {
      console.info(this._build_log(meta))
    }
  }

  warn(msg: string, meta: I_Meta = {}) {
    console.warn(msg)
    if (Object.keys(meta).length) {
      console.warn(this._build_log(meta))
    }
  }

  private _add_to_ctx(key: string, value: string) {
    this._ctx[key] = value
  }

  private _get_ctx() {
    return this._ctx
  }

  private _mergectx(ctx: { [key: string]: string }) {
    this._ctx = Object.assign({}, this._ctx, ctx)
  }

  private _build_log(meta: I_Meta) {
    return safeStringifyJSON(Object.assign({}, this._ctx, meta))
  }
}
