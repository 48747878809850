<ng-container *ngIf='is_ready'>
  <form nz-form [formGroup]=form>
    <nz-form-label [ngClass]=classes [nzFor]=input_id>{{input_label}}</nz-form-label>
    <nz-form-control>
      <input
        formControlName='input'
        #text
        nz-input
        nzSize='large'
        [disabled]=saving
        [id]=input_id
        [value]=input_value
        [placeholder]=input_placeholder>
    </nz-form-control>
  </form>
</ng-container>

