declare const require: any
import {
  Audit_W_Org_W_TS,
  Event as Fs_Event,
} from '@ws/schema-fs'
import { App_Fs_Event as App_Fs_Event, App_User_Public, I_UI_Event_Capacity_Dates } from '@app/types'
import { CONSTANTS } from "@ws/constants"
import {
  Audit_With_Org,
  Audit_WO_Org,
} from "@ws/schema-fs"
import { Timestamp as Timestamp_Fs } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { ActivatedRoute } from '@angular/router'
import { App_Fs_Org } from '@app/types'
import { AbstractControl, ValidationErrors, FormArray, FormGroup } from '@angular/forms'
import sortby from 'lodash.sortby'
import { I_Event_Capacity } from '@ws/schema-fs'
import { ServerValue } from '@firebase/database-types'


const _diffby = require('lodash/differenceBy')
const clone_deep = require('lodash.clonedeep')

export class Misc_Utils {
  // Technically serverTimestamp() returns a Fieldvalue type. However, we instead are asserting this as a Timestamp type b/c when we retrieve data with serverTimestamp, they come back as Timestamp, not Fieldvalue as they originally went in. Throughout the code, we will be using timestamp more frequently then fieldvalue. In fact we won't use Fieldvalue outside this class method. So it's less work to assert it once here.
  static get_fs_fieldvalue()  {
    return firebase.firestore.FieldValue.serverTimestamp() as Timestamp_Fs
  }

  static get_rtdb_timestamp() {
    return firebase.database.ServerValue.TIMESTAMP as number
  }

  static get_rtdb_timestamp_as_servervalue() {
    return firebase.database.ServerValue.TIMESTAMP as ServerValue
  }

  // All dates generated in-app (not being saved to DB) must be type Timestamp. This is b/c we will use Timestamp methods everywhere.
  static get_fs_timestamp_now() {
    return firebase.firestore.Timestamp.now()
  }

  // All dates generated in-app (not being saved to DB) must be type Timestamp. This is b/c we will use Timestamp methods everywhere.
  static get_fs_timestamp_from_date(date: Date = new Date()) {
    return firebase.firestore.Timestamp.fromDate(date)
  }

  /**
   * Finds the value of the needed param and also the activated route
   * for monitoring. Why? B/c Angular (surprisingly) does not have
   * a simple way to get
   *
   * @static
   * @param {ROUTE_PARAMS} param - The param we are looking for.
   * @param {ActivatedRoute} ar - The current AR instance
   * @returns {[string, ActivatedRoute]} - Return the desired param and it's containing AR (for subscribing to).
   * @memberof Misc_Utils
   */
  static get_activated_route_instance_from_param(
    param: CONSTANTS.ROUTE_PARAMS,
    ar: ActivatedRoute
  ): ActivatedRoute {
    return ar.pathFromRoot.find((parent: ActivatedRoute) => {
      return !!parent.snapshot.params[param]
    })!
  }

  // https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
  static set_timer(cb: any, interval: number): any {
    let expected = Date.now() + interval
    const i = interval || 1000 // ms
    function step() {
      const dt = Date.now() - expected // the drift (positive for overshooting)
        if (dt > i) {
            // something really bad happened. Maybe the browser (tab) was inactive? possibly special handling to avoid futile "catch up" run
        }
        cb()
        expected += i
        setTimeout(step, Math.max(0, i - dt)) // take into account drift
    }

    return setTimeout(step, interval)
  }

  // Remove excess whitespace, including extra spaces b/w chars.
  static trim_white(str: string) {
    return str.replace(/\s\s+/g, ' ').trim()
  }

  static get_audit_data_w_org(
    org: App_Fs_Org,
    user: App_User_Public,
    action?: CONSTANTS.CRUD_AUDIT_TYPE,
  ) {
    const obj: Audit_With_Org = {
      by: {
        organization: {
          id: org.id,
          name: org.name,
        },
        user: {
          id: user.id,
          name: user.name ,
        }
      },
      on: Date.now(),
    }

    if (action) {
      obj.type = action
    }

    return obj
  }

  static get_audit_data_w_org_w_ts(
    org: App_Fs_Org,
    user_id: string,
    username: string,
    action?: CONSTANTS.CRUD_AUDIT_TYPE,
  ) {
    const obj: Audit_W_Org_W_TS = {
      by: {
        organization: {
          id: org.id,
          name: org.name,
        },
        user: {
          id: user_id,
          name: username,
        }
      },
      on: Misc_Utils.get_fs_fieldvalue(),
    }

    if (action) {
      obj.type = action
    }

    return obj
  }

  static get_audit_data_wo_org(
    user_id: string,
    username: string,
    action?: CONSTANTS.CRUD_AUDIT_TYPE,
  ) {
    const obj: Audit_WO_Org = {
      by: {
        id: user_id,
        name: username ,
      },
      on: Date.now(),
    }

    if (action) {
      obj.type = action
    }

    return obj
  }

  // https://stackoverflow.com/a/38708623/3683643
  static get_user_timezone_abbreviation(): string | undefined {
    let result: string | undefined
    try {
      // Chrome, Firefox
      result = /.*\s(.+)/
        .exec((new Date())
        .toLocaleDateString(navigator.language, { timeZoneName: 'short' }))![1]
    } catch (e) {
      console.log('Timezone error. Trying backup.')
      // IE, some loss in accuracy due to guessing at the abbreviation Note: This regex adds a grouping around the open paren as a workaround for an IE regex parser bug
      result = (new Date())
        .toTimeString()
        .match(new RegExp('[A-Z](?!.*[(])', 'g'))!
        .join('')
    }
    return result
  }

  // Finds values in 'new_items' that do not exist in 'old_items', and adds an 'is_new' property to them before returning the new items. Will return new items if nothing new is there.
  static maybe_find_new_items<T>(
    old_items: T[],
    new_items: T[],
    filter_key: string = 'id',
  ): T[] {
    let return_items: T[] = []
    const found_items: [T & {is_new: boolean}] = _diffby(
      new_items,
      old_items,
      filter_key,
    )

    if (found_items && found_items.length) {
      found_items.forEach((item) => {
        // Toggle is_new.
        item = { ...item, is_new: true }
      })

      return_items = old_items.concat(found_items)
      return return_items
    }

    return new_items
  }


  // Every event must be associated with an organization in the context of what organization (the user belongs to) that was invited to an event. In other words, if a user is invited to an event, it was either b/c an organization they belonged to was invited, or, they were invited as an individual, in which case the host organization is the "member_organization". Maken zee sense?
  static set_member_organization(
    event: Fs_Event,
    org?: App_Fs_Org,
  ): App_Fs_Event {
    const e: App_Fs_Event = clone_deep(event)

    if (org) {
      e.member_organization = {
        id: org.id!,
        name: org.name,
      }
    } else {
      // If the organization does not exist, use the org owner's org. This would be the case in scenarios where an individual is invited to an event, but not their organization (this is done by the event admin in the event).
      e.member_organization = Object.assign(
        {},
        e.audit.created.by.organization,
      )
    }
    return e
  }

  static zero_out_date_time(date: Date): Date {
    const d = date
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
  }

  static pause(duration: number) {
    return new Promise((res) => setTimeout(res, duration))
  }


  // check_profanity: (uid, words, ref, data) => {
  //   const uid = firebase.auth().currentUser.uid
  //   const reff = fs_service.create_ref(ref)

  //   const filter = new Filter()

  //   if (filter.isProfane(words)) {
  //     // No need to await this request. Fire and forget.
  //     fs_service.put_potential_profanity(words, reff, data, uid)
  //   }
  // },

  static get_random_int(min:number = 1, max:number = 10000000) {
    const MIN = Math.ceil(min)
    const MAX = Math.floor(max)
    // The maximum is exclusive and the minimum is inclusive
    return Math.floor(Math.random() * (MAX - MIN)) + MIN
  }

  static get_random_string(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  // re_obj_rtdb_key: () => {
  //   return new RegExp(`[${C.RE_RTDB_KEY_RULE}]`, 'g')
  // },

  // re_test_valid_rtdb_key: (str: string) => {
  //   return !str.match(/[.$\[\]#\/]/)
  // },

  static rtdb_key_decode(val: string): string {
    // Handles:   #   $   [   ]   /
    const value = decodeURIComponent(val)
    // Handles .
    value.replace('-', '.')
    return value
  }

  static rtdb_key_encode(val: string): string {
    // Handles:   #   $   [   ]   /
    let value = encodeURIComponent(val)
    // Handles .
    value = value.replace('.', '-')
    return value
  }

  static get_all_form_errors(
    form: FormGroup | FormArray,
    path = ''
  ): Record<string, ValidationErrors> {
    const res: Record<string, ValidationErrors> = {}

    if (form instanceof FormGroup) {
      for (const key of Object.keys(form.controls)) {
        Object.assign(
          res,
          parse_control_error(
            `${path ? `${path}.${key}` : key}`,
            form.controls[key]
          )
        )
      }
    }

    if (form instanceof FormArray) {
      for (let i = 0; i < form.controls.length; i++) {
        Object.assign(
          res,
          parse_control_error(`${path ? `${path}.${i}` : i}`, form.controls[i])
        )
      }
    }

    return res
  }

  static zero_out_sec_and_ms(date: Date) {
    const d = new Date(date)
    d.setMilliseconds(0)
    d.setSeconds(0)
    return d
  }

  static get_ui_event_cap_dates(capacity: I_Event_Capacity, is_test: boolean) {
    return sortby(Object.entries(capacity).map((entry) => {
      const times = entry[0]
      const cap = entry[1]

      const [start, end] = times.split('-')
      const ui_date: I_UI_Event_Capacity_Dates = {
        capacity: is_test ? 'Unlimited' : cap,
        date: new Date(parseInt(start, 10)),
        end: new Date(parseInt(end, 10)),
        start: new Date(parseInt(start, 10)),
      }
      return ui_date
    }), 'date');
  }

  static sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  static get_route_param(
    act_route: ActivatedRoute,
    find_param: string,
  ) {
    const some = (arr: ActivatedRoute[]): boolean => {
      if (!arr.length) {
        return false
      }

      return arr.some((ar) => {
        if (!!ar.snapshot.params[find_param]) {
          return true
        }

        if (ar.children.length) {
          return some(ar.children)
        }

        return false
      })
    }

    return some(act_route.root.children)
  }

  // update_array: (old, neww) => {
  //   // Add or replace.
  //   if (neww.length >= old.length) {
  //     neww.forEach((new_item) => {
  //       // Determine if element has been added.
  //       const found_index = old.findIndex((old_item) => {
  //         return old_item.id === new_item.id
  //       })

  //       // Not found - new element case.
  //       if (found_index === -1) {
  //         old.push(new_item)
  //         // Found - check if update is necessary.
  //       } else {
  //         let old_item = old[found_index]
  //         if (!_.isEqual(new_item, old_item)) {
  //           // Delete the old properties and copy the new over.
  //           Object.keys(old_item).forEach((key) => {
  //             delete old[found_index][key]
  //           })

  //           Object.keys(new_item).forEach((key) => {
  //             old[found_index][key] = new_item[key]
  //           })
  //         }
  //       }
  //     })
  //     // New array is shorter than old. Remove missing element.
  //   } else {
  //     old.some((old_item, i) => {
  //       const found_index = neww.findIndex((new_item) => {
  //         return old_item.id === new_item.id
  //       })

  //       if (found_index === -1) {
  //         // Missing old element found, remove it from the original array (mutate).
  //         old.splice(i, 1)
  //       }
  //     })
  //   }
  // },

  // update_modified_audit_data: (operation) => {
  //   const s = store.getState()
  //   const name = s.user.name

  //   return {
  //     action: {
  //       type: operation,
  //     },
  //     by: {
  //       id: firebase.auth().currentUser.uid,
  //       name: {
  //         first: name.first,
  //         last: name.last,
  //       },
  //     },
  //     on: firebase.firestore.FieldValue.serverTimestamp(),
  //   }
  // },
}

function parse_control_error(
  key: string,
  control: AbstractControl
): Record<string, ValidationErrors> {
  const res: Record<string, ValidationErrors> = {}

  if (control instanceof FormGroup || control instanceof FormArray) {
    const errors = Misc_Utils.get_all_form_errors(control, key)
    if (Object.keys(errors).length > 0) {
      Object.assign(res, errors)
    }
  } else if (control.errors) {
    res[key] = control.errors
  }

  return res
}
