import {
  Action,
  State,
  Selector,
  StateContext,
  StateToken,
} from '@ngxs/store'
import { Set_Event, Delete_Event } from './actions'
import { STORE_ENTITIES } from "@app/constants"
import { App_Fs_Event } from '@app/types'
import produce from 'immer'
import { Injectable } from '@angular/core'


// For event state, we are grouping events under their respective organizations. Why? B/c events will be accessed this way in most of the app.
export type Fs_Event_State_Model = Record<string, Record<string, App_Fs_Event>>

export const FS_EVENT_STATE_TOKEN = new StateToken<Fs_Event_State_Model>(STORE_ENTITIES.FS_EVENTS)

@State<Fs_Event_State_Model>({
  name: FS_EVENT_STATE_TOKEN,
})

@Injectable()
export class Fs_Event_State {
  @Selector()
  public static getState(state: Fs_Event_State_Model) {
      return state
  }

  // Lazy selectors: https://www.ngxs.io/concepts/select#lazy-selectors.
  @Selector()
  static org_events(state: Fs_Event_State_Model) {
    return (org_id: string) => {
      return state[org_id] || {}
    }
  }

  @Selector()
  static org_event(state: Fs_Event_State_Model) {
    return (org_id: string, event_id: string) => {
      return (state[org_id] && state[org_id][event_id])  || {}
    }
  }

  @Action(Delete_Event)
  private delete_event(
    ctx: StateContext<Fs_Event_State_Model>,
    action: Delete_Event,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const eid = action.event_id
        const oid = action.organization_id
        delete draft[oid][eid]
      }),
    )
  }

  @Action(Set_Event)
  private set_event(
    ctx: StateContext<Fs_Event_State_Model>,
    action: Set_Event,
  ) {

    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const eid = action.payload.id
        const oid = action.organization_id
        // If the org doesn't exist, add it now.
        if (!draft[oid]) draft[oid] = {}
        draft[oid][eid] = action.payload
      }),
    )
  }
}
