// Weird thing required by Twilio.
(window as any).global = window
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { App_Module } from './app/modules/_root/root.module'
import { environment as env } from './environments/environment'
import firebase from 'firebase'

function get_config() {
  return is_local()
    ? {
      ...env.firebase_config,
      databaseURL: `http://localhost:9000?ns=${env.firebase_config.projectId}`,
    }
    : env.firebase_config
}

function is_local() {
  return env.use_local && location.hostname === 'localhost'
}

function maybe_enable_prod_mode() {
  if (env.production) {
    enableProdMode()
  }
}

function set_log_level() {
  if (env.production) {
    firebase.setLogLevel('silent')
  } else {
    // firebase.database.enableLogging(true)
    // firebase.setLogLevel('debug')
  }
}

function maybe_config_local_firestore() {
  if (is_local()) {
    firebase.firestore().settings({
      host: "localhost:8080",
      ssl: false
    })
  }
}

function init_firebase() {
  firebase.initializeApp(get_config())
  set_log_level()
  maybe_config_local_firestore()
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(App_Module).catch(err => console.error(err))
}

function handle_auth() {
  // We just want to get the user's auth status before the actual app loads. Why you ask? B/c when the app loads, if a user exists, we want to throw up our awesome loader to let them know we are getting data to load their app. Else, if they aren't logged, then no loading is necessary.
  const auth_unsub = firebase.auth().onAuthStateChanged((user) => {
    // Unsub since we have the user's state. A new sub will be added in the auth service.
    auth_unsub()
    // Load the app.
    bootstrap()
  })
}

maybe_enable_prod_mode()
init_firebase()
handle_auth()


