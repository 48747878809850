import { State, Action, Selector, StateContext, StateToken } from '@ngxs/store'
import { Set_User_App } from './actions'
import { App_User_App } from '@app/types'
import { STORE_ENTITIES } from '../../constants/reducers'
import { Injectable } from '@angular/core'

export const USER_APP_STATE_TOKEN = new StateToken<App_User_App>(STORE_ENTITIES.USER_APP)

@State<App_User_App>({
  name: USER_APP_STATE_TOKEN,
})

@Injectable()
export class User_App_State {

  @Selector()
  public static getState(state: App_User_App) {
    return state
  }

  @Action(Set_User_App)
  private add_user_app(
    ctx: StateContext<App_User_App>,
    action: Set_User_App,
  ) {
    ctx.patchState(action.payload)
  }
}
