import { Component, OnInit, Input } from '@angular/core';
import { COLOR_PALETTE } from '@ws/constants';

@Component({
  selector: 'icon-success',
  templateUrl: './icon-success.component.html',
  styleUrls: ['./icon-success.component.scss']
})
export class Icon_Success_Component implements OnInit {
  @Input() height = '';
  @Input() width = '';
  @Input() fill = '';
  dimensions: {height: string, width: string} = {} as any;
  fill_default = COLOR_PALETTE.PURPLE;

  constructor() { }

  ngOnInit() {
    this.set_styles();
  }

  private set_styles() {
    this.dimensions = {
      height: this.height || 'auto',
      width: this.width || 'auto',
    }
  }

}
