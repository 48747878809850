import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { COLOR_PALETTE as CP } from '@ws/constants'
import { environment } from '@app/env'
import * as firebase from 'firebase'
import { Auth_Service } from '@app/services/auth.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { Routes } from "@ws/constants"

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Login_Component {
  done = false
  form: FormGroup
  logo_fill = CP.PURPLE
  public_app_link = environment.urls.public
  reset_pass = false
  submitting = false
  url_forgot_pass = Routes.arr.reset_password()
  url_register = Routes.arr.register()
  url_pp = environment.urls.pp

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private readonly auth_s: Auth_Service,
    private readonly fb: FormBuilder,
    private readonly message_s: NzMessageService,
    ) {
    this.form = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
    })
  }

  private get_error_message(error: any): string {
    let m = ''
    switch(error.code) {
      case 'auth/user-not-found':
        m = 'That email address is not registered.'
        break
      case 'auth/wrong-password':
        m = 'That password is incorrect.'
        break
      default:
        m = error.message
    }

    return m
  }

  async submit_form(): Promise<void> {
    if (this.submitting) {
      return
    }

    this.submitting = true

    if (!this.form.valid) {
      this.submitting = false
      return
    }

    const email = this.form.get('email')! && this.form.get('email')!.value
    const pass = this.form.get('password') && this.form.get('password')!.value
    if (!email || !pass) {
      return
    }

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].markAsDirty()
      this.form.controls[key].updateValueAndValidity()
      this.form.controls[key].disable()
    })

    let creds: firebase.default.auth.UserCredential

    try {
      creds = await this.auth_s.login(email, pass)

      if (!creds) {
        throw new Error('Registration failed for reasons unknown.')
      }

      this.done = true
      this._cd.detectChanges()
    } catch (e) {
      const message = this.get_error_message(e)
      this.message_s.error(message, { nzDuration: 4000 })
      // Re-enabled the form controls.
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].enable({emitEvent: false})
      })
    } finally {
      this.submitting = false
    }
  }
}
