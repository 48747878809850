import { Injectable, OnDestroy } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { User_Billing } from '@ws/schema-fs'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { User_Service } from '../user.service'
import { FB_Refs_Firestore as refs} from "@ws/constants"
import fn from 'format-number'

@Injectable({
  providedIn: 'root'
})
export class User_Billing_Service implements OnDestroy {
  private readonly _billing$ = new BehaviorSubject<User_Billing>({} as User_Billing)
  private _is_init = false
  private readonly _destroy$ = new Subject<boolean>()
  billing$ = this._billing$.asObservable()

  constructor(
    private readonly _afs: AngularFirestore,
    private readonly _us: User_Service,
  ) {}

  ngOnDestroy() {
    this._destroy$.next(true)
  }

  init() {
    if (this._is_init) return

    this._afs.doc<User_Billing>(refs.user_billing(this._us.user_id))
      .valueChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe((billing) => {
        if (billing) {
          this._billing$.next(billing)
        }
    })
    this._is_init = true
  }

  get formatted_balance() {
    const credits = this._billing$.getValue().credits

    if (credits !== null && credits !== undefined) {
      const n = Math.round(credits * 100)/100
      return fn({integerSeparator: ',', padRight: 2})(n)
    } else {
      return '0'
    }

    // return credits
    //   ? fn({integerSeparator: ',', padRight: 2})(this._billing$.getValue().credits )
    //   : '0'
  }

  get_updated_formatted_balance(integer: number) {
    // * Remember: a positive integer is a debit, and a negative is a refund. See event-dates.ts in libs/utils.
    const n = (this._billing$.getValue().credits ?? 0) - integer
    return fn({integerSeparator: ','})(n)
  }

  get balance() {
    return this._billing$.getValue().credits
  }

  get is_init() { return this._is_init}
}
