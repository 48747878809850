import { Component, Input } from '@angular/core'

@Component({
  selector: 'name-change-modal',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class Name_Change_Modal_Component {
  @Input() modal_action!: any

}
