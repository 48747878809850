declare var require: any
import { Injectable } from '@angular/core'
require('firebase/storage')
import firebase from 'firebase/app'

type Upload_Task = firebase.storage.UploadTask

interface Put_File_Params {
  data: Blob | Uint8Array | ArrayBuffer,
  metadata?: firebase.storage.UploadMetadata,
  ref: string
}

@Injectable({providedIn: 'root'})
export class FB_Storage_Service {
  private storage = firebase.storage()

  constructor() { }

  get_download_url(ref: string) {
    return this.storage.ref(ref).getDownloadURL()
  }

  put_file(opts: Put_File_Params): Upload_Task {
    return this.storage
      .ref(opts.ref)
      .put(opts.data, opts.metadata)
  }
}
