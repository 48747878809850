import { State, Action, Selector, StateContext, StateToken } from '@ngxs/store'
import { Delete_Org, Set_Org } from './actions'
import { STORE_ENTITIES } from "@app/constants"
import { App_Fs_Org } from "@app/types"
import produce from 'immer'
import { Injectable } from '@angular/core'

export type Fs_Org_State_Model = Record<string, App_Fs_Org>

export const FS_ORG_STATE_TOKEN = new StateToken<Fs_Org_State_Model>(STORE_ENTITIES.FS_ORGANIZATIONS)

@State<Fs_Org_State_Model>({
  name: FS_ORG_STATE_TOKEN,
})
@Injectable()
export class Fs_Org_State {

  @Selector()
  public static getState(state: Fs_Org_State_Model) {
    return state
  }

  // Lazy selectors: https://www.ngxs.io/concepts/select#lazy-selectors.
  @Selector()
  static org(state: Fs_Org_State_Model) {
    return (org_id: string) => {
      const s = state[org_id] || {}
      return s
    }
  }

  @Action(Delete_Org)
  private delete_org(
    ctx: StateContext<Fs_Org_State_Model>,
    action: Delete_Org,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const oid = action.payload
        delete draft[oid]
      }),
    )
  }

  @Action(Set_Org)
  private set_org(
    ctx: StateContext<Fs_Org_State_Model>,
    action: Set_Org,
  ) {
    const org = {
      // 'as string' b/c id is type GUID and must be string
      // when used as a computed property.
      [action.payload.id as string]: action.payload,
    }
    ctx.patchState(org)
  }

}
