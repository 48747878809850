import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
import { Service_Interfaces as SI} from '@client/services'
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router'

@Injectable({ providedIn: 'root' })

// Service to manage showing/hiding the Loader component in app root.
export class Loading_Service {
  private show = new BehaviorSubject<SI.Show_Loader_Opts>({
    background: undefined,
    message: undefined,
    show: false,
  })
  // Tracks how many times service has been called.
  private count = 0
  private _is_showing = false
  private _is_app_refresh = false

  constructor(
    private router: Router,
  ) {
    this.set_ll_loader()
  }

  // Loader should only hide if it has not been called more than once.
  hide_loader() {
    if (this._is_app_refresh) {
      return
    }

    if (this.count <= 1) {
      this.show.next({
        background: undefined,
        message: undefined,
        show: false,
      })
      this._is_showing = false
    }
    this.count -= 1

    // Self correct if count gets out of sequence. This may happen in circumstances where the show_loader() call is delayed (e.g. in situations where it is only called on slow connections). This situation may see hide_loader() called, where show_loader() is never called. In this case, count will become negative. Just reset in the event this happens.
    if (this.count < 0) {
      this.count = 0
    }
  }

  hide_loader_now() {
    this.show.next({
      background: undefined,
      message: undefined,
      show: false,
    })
    this._is_showing = false
    this.count = 0
  }

  show_loader({
    message = 'loading',
    background = 'white',
    is_app_refresh = false,
  }: SI.Show_Loader_Opts) {
    if (this._is_app_refresh) {
      return
    }

    if (is_app_refresh) {
      this._is_app_refresh = true
    }

    this.show.next({
      background,
      message,
      show: true,
    })
    this.count += 1
    this._is_showing = true
  }

  get is_showing(): boolean {
    return this._is_showing
  }

  get_loader(): Observable<any> {
    return this.show.asObservable()
  }

  // This sets the loading indicator to display whenever a route is being lazy loaded. If multiple modules are being lazy loaded, it will continue to display until all are complete, or, it won't show it all if they load fast enough. NOTE - the below router events still fire even during preloading, so we must account for this via tracking router navigation events (to signal the user routed to a lazy loaded module vs. the module just preloading).
  // https://www.bennadel.com/blog/3506-preloading-lazy-loaded-feature-modules-in-angular-6-1-9.htm
  private set_ll_loader() {
    // As the router loads modules asynchronously (via loadChildren), we're going to keep track of how many asynchronous requests are currently active. If there is at least one pending load request, we'll show the indicator.
    let async_load_count = 0
    // As the user navigates around the application, we're going to keep track of how many pending navigation requests are currently active. This way, we can know if the asynchronous module loading is [possibly] happening because of a user navigation or, if it's happening as part of the pre-loading.
    let navigation_count = 0
    let timeout_id: any

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof RouteConfigLoadStart ) {
          async_load_count += 1
        } else if (event instanceof RouteConfigLoadEnd ) {
          // Remove the loader on the last module.
          async_load_count -= 1
          // Most often the module will be loaded before the loader is triggered after timeout. Thus, clear it here before it starts.
          if (!async_load_count) {
            clearTimeout(timeout_id)
            this.hide_loader()
            timeout_id = undefined
            async_load_count = 0
          }
        } else if ( event instanceof NavigationStart ) {
          navigation_count++
        } else if (
          ( event instanceof NavigationEnd ) ||
          ( event instanceof NavigationError ) ||
          ( event instanceof NavigationCancel )
          ) {
          navigation_count--
        }

        if (async_load_count && navigation_count && !timeout_id) {
          // For the first route, use a slight delay. This is so users with fast connections will not see the indicator blip.
          timeout_id = setTimeout(() => {
            this.show_loader({
              message: 'Loading...',
            })
          }, 1000)
        }
      }
    )
  }

}
