import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'check-mark',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Shared_Check_Mark_Component {
  @Input() color = '#52c41a'
  @Input() font_size = '1.4rem'

}
