export const RE_RTDB_KEY_RULE = '.$\\[\\]#/'
export const ORG_NAME_LENGTH_LIMIT = 80

export enum CRUD_AUDIT_TYPE {
  CREATE = 'create',
  DELETE = 'delete',
  READ = 'read',
  UPDATE = 'update',
}

export enum ROUTE_PARAMS {
  CHAT_ID = 'cid',
  EVENT_ID = 'eid',
  ORG_ID = 'oid',
  MEMBER_ID = 'mid',
  ROOM_ID = 'rid',
}

export enum HTTP_ERROR_CODES {
  ALREADY_EXISTS = 'already-exists',
  FAILED_PRECONDITION = 'failed-precondition',
  INTERNAL = 'internal',
  PERMISSION_DENIED = 'permission-denied',
}

export enum TWILIO_ROOM_TYPES {
  GROUP_SMALL = 'group-small',
  GROUP = 'group',
  P2P = 'peer-to-peer',
}

export enum YAATLY_MAIL {
  INFO = 'info@yaatly.com',
  NEW_ORG = 'new-org@yaatly.com',
  ROBOTS = 'robots@yaatly.com',
  SUPPORT = 'support@yaatly.com',
  TEST = 'yaatly-test-dev@yaatly.com',
}

export enum Info_Domains {
  FAQ = 'https://info.yaatly.com/faq#what-is-the-event-test-mode'
}

export enum DOMAINS {
  JITSI = 'meet.jit.si'
}
