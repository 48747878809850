declare const require: any
const readAndCompressImage = require('browser-image-resizer').readAndCompressImage
const mime = require('mime')


interface IResizeImageOptions {
  maxSize: number
  file: File
}

// Note that image/svg+xml mimetypes will be converted to image/png (default) b/c of some browser weirdness that doesn't let canvas deal in svg mimetypes. SO, as far as cloud storage goes, file 'Type' attribute will be image/png whereas metadata will know original extension.
export async function get_image_thumbnail(file: File): Promise<Blob> {
  const extension = file.name.split('.').pop()!
  const mimeType = mime.getType(extension)

  let thumbnail
  try {
    thumbnail = await readAndCompressImage(file, {
      quality: 1,
      maxWidth: 50,
      maxHeight: 50,
      mimeType,
    })
  } catch (e) {
    console.log(e)
    throw e
  }
  return thumbnail
}
