<div fxLayoutAlign="center center" class='container h100 o-y-auto'>

    <div class='auth-container'>
      <form
        class='animated fadeIn form-container'
        nzNoColon
        nz-form
        [formGroup]="form"
        (ngSubmit)="submit_form()">

        <div class='h100 w100' *ngIf='!submitted' fxLayoutAlign="center center">
          <div class='w100'>
            <div class='ta-center header'>Reset your password</div>

            <div class='ta-center instructions'>
              <p class='ta-center'>Provide your email address below to receive an email with instructions.</p>
            </div>


            <nz-form-item>
                <nz-form-label nzFor="email"></nz-form-label>
                <nz-form-control nzErrorTip="Invalid email" nzHasFeedback>
                  <nz-input-group nzPrefixIcon="mail" nzSize="large">
                    <input
                      formControlName="email"
                      id="email"
                      nzPrefixIcon="mail"
                      nz-input
                      placeholder="Email"/>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

            <nz-form-item>
              <nz-form-control class='ta-center'>
                <button
                  nzType="primary"
                  nz-button
                  [nzLoading]='submitting'
                  nzSize='large'
                  [disabled]="!form.valid || submitting"
                  >Submit</button>
              </nz-form-control>
            </nz-form-item>

            <br>

            <p class='ta-center fs-1-3-rem'>
              <a routerLink='/register'>Sign up</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a routerLink='/login'>Log in</a>
            </p>
          </div>
        </div>

        <div fxFlex='grow' *ngIf='submitted' fxLayoutAlign="center center">
          <div class='w100'>
            <ng-template [ngIf]='success'>
              <h3 class='ta-center'>A password reset email has been sent.</h3>
              <div class='ta-center icon-container'>
                <icon-success height='5rem'></icon-success>
              </div>
              <p class='ta-center'>Please follow the instructions in the email to reset your password.</p>
              <p class='ta-center fs-1-3-rem'>
                <a routerLink='/login'>Back to log in</a>
              </p>
            </ng-template>

            <ng-template [ngIf]='!success'>
              <h3 class='ta-center'>Something happened...</h3>
              <div class='ta-center icon-container'>
                <icon-fail height='5rem'></icon-fail>
              </div>
              <p class='ta-center'>Please try again</p>
              <p class='ta-center'>
                <a (click)='try_again()'>Back</a>
              </p>
            </ng-template>
          </div>
        </div>

        <a
          class='p-abs pp fs-1-2-rem'
          [href]='privacy_policy'
          target="_blank">Privacy</a>
        <a class='p-abs logo' [href]="public_app_link">
          <logo-letter [fill]='logo_fill' [height]="'3rem'"></logo-letter>
        </a>
      </form>

    </div>

    <div class='doge-container'><doge></doge></div>

  </div>
