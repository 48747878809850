import { Store, Select } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  Actions,
  Fs_Event_State,
  Fs_Event_State_Model,
  Fs_Org_Member_State,
  Fs_Org_State,
  Fs_Org_State_Model,
  Fs_Org_Member_Invite_State,
} from '@app/store'
import { Dispatch } from '@ngxs-labs/dispatch-decorator'
import {
  App_Fs_Event,
  App_Fs_Org,
  App_Fs_Org_Member_Invite,
  App_Fs_Org_Member,
  App_User_Public,
  App_User_Private,
  App_User_App,
} from '@app/types'
import * as Schema from '@ws/schema-fs'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  Fs_Org_Member_State_Model,
  Fs_Org_Member_Invite_State_Model,
  USER_PUBLIC_STATE_TOKEN,
  FS_ORG_STATE_TOKEN,
  FS_EVENT_STATE_TOKEN,
  FS_ORG_MEMBER_STATE_TOKEN,
  FS_ORG_MEMBER_INVITE_STATE_TOKEN,
 } from '../store/states'
import { USER_APP_STATE_TOKEN } from '../store/user_app/state'
import { USER_PRIVATE_STATE_TOKEN } from '../store/user_private/state'


// Facade for interacting with the state management layer.
@Injectable({ providedIn: 'root' })
export class App_Store_Service {
  @Select(Fs_Org_State) orgs$!: BehaviorSubject<Fs_Org_State_Model>
  @Select(Fs_Event_State) events$!: BehaviorSubject<Fs_Event_State_Model>

  constructor(private store: Store) {}


  //////////////////////////////////////////////////////////////////////
  // Dynamically generate custom Lazy Selectors: https://www.ngxs.io/concepts/select#lazy-selectors.
  //////////////////////////////////////////////////////////////////////
  public org_selector(org_id: string) {
    return this.store
      .select(Fs_Org_State.org)
      .pipe(map(filter => filter(org_id)))
  }

  public org_event_selector(org_id: string, event_id: string) {
    return this.store
      .select(Fs_Event_State.org_event)
      .pipe(map(filter => filter(org_id, event_id)))
  }

  public org_events_selector(org_id: string) {
    return this.store
      .select(Fs_Event_State.org_events)
      .pipe(map(filter => filter(org_id)))
  }

  public org_invites_selector(org_id: string) {
    return this.store
      .select(Fs_Org_Member_Invite_State.org_invites)
      .pipe(map(filter => filter(org_id)))
  }

  public org_members_selector(org_id: string) {
    return this.store
      .select(Fs_Org_Member_State.org_members)
      .pipe(map(filter => filter(org_id)))
  }

  public org_member_selector(org_id: string, member_id: string) {
    return this.store
      .select(Fs_Org_Member_State.org_member)
      .pipe(map(filter => filter(org_id, member_id)))
  }

  //////////////////////////////////////////////////////////////////////
  // Convenience static selectors.
  //////////////////////////////////////////////////////////////////////
  public get_org_events(org_id: string) {
    const events = this.get_all_events()
    return events[org_id] || {}
  }

  private get_all_events() {
    return this.store.selectSnapshot<
      Fs_Event_State_Model
    >(FS_EVENT_STATE_TOKEN)
  }

  public get_org_invites(org_id: string) {
    return this.get_all_org_invites()[org_id] || {}
  }

  private get_all_org_invites() {
    return this.store.selectSnapshot<
      Fs_Org_Member_Invite_State_Model
    >(FS_ORG_MEMBER_INVITE_STATE_TOKEN)
  }

  public get_org_member(org_id: string, member_id: string) {
    return this.get_org_members(org_id)[member_id] || null
  }

  public get_org_members(org_id: string) {
    const org = this.get_org(org_id)
    if (!org) return {}

    return this.store.selectSnapshot<
      Fs_Org_Member_State_Model
    >(FS_ORG_MEMBER_STATE_TOKEN)[org_id] || {}
  }

  public get_org(org_id: string) {
    const orgs = this.get_orgs()
    return orgs && orgs[org_id] || null
  }

  public get_orgs() {
    return this.store.selectSnapshot<
      Fs_Org_State_Model
    >(FS_ORG_STATE_TOKEN)
  }

  public get_user_app() {
    return this.store.selectSnapshot<App_User_App>(USER_APP_STATE_TOKEN)
  }

  public get_user_private() {
    return this.store.selectSnapshot<App_User_Private>(USER_PRIVATE_STATE_TOKEN)
  }

  public get_user_public() {
    return this.store.selectSnapshot<App_User_Public>(USER_PUBLIC_STATE_TOKEN)
  }

  //////////////////////////////////////////////////////////////////////
  // Action dispatchers.
  //////////////////////////////////////////////////////////////////////
  @Dispatch()
  public delete_org(org_id: string) {
    return new Actions.Fs_Org.Delete_Org(org_id)
  }

  @Dispatch()
  public delete_org_invite(org_id: string, invite_id: string) {
    return new Actions
      .Fs_Org_Member_Invite
      .Delete_Invite(org_id, invite_id)
  }

  @Dispatch()
  public delete_org_member(org_id: string, member_id: string) {
    return new Actions
      .Fs_Org_Member
      .Delete_Member(org_id, member_id)
  }

  @Dispatch()
  public set_org(org: App_Fs_Org) {
    return new Actions.Fs_Org.Set_Org(org)
  }

  @Dispatch()
  public set_org_event(event: App_Fs_Event, org_id: string) {
    return new Actions.Fs_Event.Set_Event(event, org_id)
  }

  @Dispatch()
  public set_org_member(member: App_Fs_Org_Member, org_id: string) {
    return new Actions.Fs_Org_Member.Set_Member(member, org_id)
  }

  @Dispatch()
  public set_org_invite(org_id: string, invite: App_Fs_Org_Member_Invite,) {
    return new Actions.Fs_Org_Member_Invite.Set_Invite(org_id, invite)
  }

  @Dispatch()
  public set_user_app(user: Partial<Schema.User_App>) {
    return new Actions.User_App.Set_User_App(user)
  }

  @Dispatch()
  public set_user_private(user: Partial<Schema.User_Private>) {
    return new Actions.User_Private.Set_User_Private(user)
  }

  @Dispatch()
  public set_user_public(user: Partial<Schema.User_Public>) {
    return new Actions.User_Public.Set_User_Public(user)
  }
}
