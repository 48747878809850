import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import 'firebase/auth'
import {
  canActivate,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard'
import { Login_Component } from './auth/login/login.component'
import { Register_Component } from './auth/register/register.component'
import {
  Reset_Password_Component
} from "./auth/reset-password/reset-password.component"
import { User_Resolver } from './guards/user.resolver'
import { Is_Auth_Can_Load } from './guards/is-auth.canLoad'
import { No_Anon_Can_Load } from './guards/no-anon.canLoad'
import { Is_Only_Anon_Guard } from './guards/is-only-anon.canActivate'
import { User_Resolver_Can_Load } from './guards/user-resolver.canLoad'


const routes: Routes = [
  {
    path: 'a',
    ...canActivate(() => redirectLoggedInTo([''])),
    children: [
      {
        path: 'login',
        component: Login_Component,
      },
      {
        path: 'register',
        component: Register_Component,
      },
      {
        path: 'reset-password',
        component: Reset_Password_Component,
      },
    ]
  },
  {
    // TODO - Run the Is_Auth_Can_Load guard here and redirect to anonymous event login page if necessary, then re-route to event after anon login.
    path: 'e',
    loadChildren: () => import('../event/modules/_root/module').then(mod => mod.Event_Module)
  },
  {
    path: 'incogout',
    canActivate: [Is_Only_Anon_Guard],
    loadChildren: () => import('../anon/anon.module').then(mod => mod.Anon_Module)

  },
  {
    // TODO - A better way would be to run auth/user resolver first then route to another component explaining they need to register first.We don't require auth/orgs for this route b/c we want to display a message to the user within the component when they get there.
    path: 'o/:oid/invitation/:iid',
    canLoad: [No_Anon_Can_Load],
    loadChildren: () => import('../org-member-invitation-landing/org-member-invitation-landing.module')
    .then(mod => mod.Org_Member_Invitation_Landing_Module),
  },
  {
    path: 'e/:eid/invitation/:iid',
    canLoad: [Is_Auth_Can_Load],
    resolve: [User_Resolver],
    loadChildren: () => import('../event-invitation-landing/event-invitation-landing.module')
    .then(mod => mod.Event_Invitation_Landing_Module),
  },
  {
    path: '',
    canLoad: [No_Anon_Can_Load, Is_Auth_Can_Load, User_Resolver_Can_Load],
    loadChildren: () => import('../home/home.module').then(mod => mod.Home_Module),
  },
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        // enableTracing: true, // Debugging only.
        // preloadingStrategy: Selective_Preload_Strategy_Service,
        // paramsInheritanceStrategy: 'always',
      },
    )
  ],
  exports: [RouterModule]
})

export class App_Routing_Module { }
