import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

interface I_Styles {
  background: string
  position: string
}

const default_message = 'Loading...'
const default_background = 'white'

@Component({
  selector: 'loading',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Loader_Component {
  private _message = default_message
  private _background = default_background
  private _fixed = false

  @Input()
  get message() { return this._message}
  set message(message: string) {
    this._message = message ?? default_message
  }

  @Input()
  get background() { return this._background }
  set background(bg: string) {
    this._background = bg ?? default_background
  }

  @Input()
  get fixed() { return this._fixed }
  set fixed(fixed: boolean) {
    this._fixed = fixed ?? false
  }


  constructor() { }

  get styles() {
    let position = 'static'

    if (this._fixed) {
      position = 'fixed'
    }

    const styles: I_Styles = {
      background: this._background,
      position,
    }



    return styles
  }
}
