import { State, Action, Selector, StateContext, StateToken } from '@ngxs/store'
import { Set_User_Private } from './actions'
import { App_User_Private } from '@app/types'
import { STORE_ENTITIES } from '../../constants/reducers'
import { Injectable } from '@angular/core'

export const USER_PRIVATE_STATE_TOKEN = new StateToken<App_User_Private>(STORE_ENTITIES.USER_PRIVATE)

@State<App_User_Private>({
  name: USER_PRIVATE_STATE_TOKEN,
})

@Injectable()
export class User_Private_State {
  @Selector()
  public static getState(state: App_User_Private) {
    return state
  }

  @Action(Set_User_Private)
  private add_user_private(
    ctx: StateContext<App_User_Private>,
    action: Set_User_Private,
  ) {
    ctx.patchState(action.payload)
  }
}
