import { App_Load_Module } from './root-load/root-load.module'
import { BrowserModule } from '@angular/platform-browser'
// Needed for Zorro not to complain.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule, /* APP_INITIALIZER */ } from '@angular/core'
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n'
import { NgxsModule } from '@ngxs/store'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator'
import { AngularFireModule } from '@angular/fire'
import { AngularFireDatabaseModule } from '@angular/fire/database'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFireAuthGuard } from '@angular/fire/auth-guard'
import { ReactiveFormsModule } from '@angular/forms'
import { Shared_Module } from "../shared/shared.module"
import { AngularFireFunctionsModule } from '@angular/fire/functions'
import { ENV_CONFIG } from '@client/providers'

// Components
import { Login_Component } from './auth/login/login.component'
import { Register_Component } from './auth/register/register.component'

import { _States } from '../../store'
import { App_Routing_Module } from './root-routing.module'
import { App_Component } from './_root/root.component'
import { App_Templates_Component } from './templates/templates.component'
import { environment } from '../../../environments/environment'
import { Reset_Password_Component } from './auth/reset-password/reset-password.component'
// Global route reuse strategy stuff (if desired).
// import { RouteReuseStrategy } from "@angular/router"
// import { App_Route_Reuse_Strategy } from './route-strategy'

// If we end up wanting to load icons statically (not dynamically), use this:
// import { IconDefinition } from '@ant-design/icons-angular'
// import { NgZorroAntdModule, NZ_ICON_DEFAULT_TWOTONE`_COLOR, NZ_ICONS } from 'ng-zorro-antd'
// import {
//   UserOutline,
//   MailOutline,
// } from '@ant-design/icons-angular/icons'
// const icons: IconDefinition[] = [ UserOutline ]
import { NZ_ICON_DEFAULT_TWOTONE_COLOR } from 'ng-zorro-antd/icon'
import { COLOR_PALETTE as CP } from "@ws/constants"
import { Name_Change_Modal_Component } from './name-change-modal/component'
import { Modal_User_Name_Change_Component } from './name-change-modal/name-change/name-change.component'


@NgModule({
  declarations: [
    App_Component,
    App_Templates_Component,
    Login_Component,
    Register_Component,
    Reset_Password_Component,
    Modal_User_Name_Change_Component,
    Name_Change_Modal_Component,
  ],
  imports: [
    App_Load_Module,
    AngularFireModule.initializeApp(environment.firebase_config),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    App_Routing_Module,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot(_States, { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    ReactiveFormsModule,
    Shared_Module,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: ENV_CONFIG, useValue: environment },
    // // Global route reuse strategy stuff (if desired).
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: App_Route_Reuse_Strategy,
    // },
    AngularFireAuthGuard,
    // If we end up wanting to load icons statically (not dynamically), use this:
    { provide: NZ_ICON_DEFAULT_TWOTONE_COLOR, useValue: CP.BLURPLE_LIGHT }, // If not provided, Ant Design's official blue would be used
    // { provide: NZ_ICONS, useValue: icons }
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: init,
    //   multi: true,
    //   deps: [/* your dependencies */]
    // },
  ],
  bootstrap: [App_Component]
})
export class App_Module {}

/**
 * Any work that needs to be done before app init can happen here.
 * Note that it can be async via a Promise. Also note that nothing in
 * app will load until promise resolves.
 *
 * @return  {any} Returns a function that can return Promise<any>
//  */
// function init(): any {
//   return (): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       // Do app init stuff here.
//       setTimeout(() => {
//         resolve()
//       }, 10000)
//     })
//   }
// }
