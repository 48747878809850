import { Pipe, PipeTransform } from '@angular/core'

export interface I_Object_Entries_Pipe<T> {
  key: string
  value: T
}

@Pipe({name: 'entries'})
export class Object_Entries_Pipe implements PipeTransform {
  transform(obj: any, args:string[]) : any {
    const keys = []
    for (const [key, value] of Object.entries(obj)) {
      keys.push({key: key, value: obj[key]})
    }
    return keys
  }
}
