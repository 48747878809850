<nz-collapse nzBordered=false style='width:100%' class='danger-panel-collapse'>
  <nz-collapse-panel
    [nzHeader]='panel_header'
    style='border-bottom:none'
    [nzExpandedIcon]='expandedIcon'
    #danger_panel>

    <confirm-text
      [button_text]="button_text || 'Confirm'"
      [confirm_action]='confirm_action'
      [confirm_value]='confirm_value'
      [instruction]='instruction'
      (done)='handle_done($event)'>
    </confirm-text>


    <ng-template #panel_header>
      <h3>{{ header }}</h3>
    </ng-template>
    <ng-template #expandedIcon let-active>
      <i nz-icon
        nzType='caret-right'
        class='ant-collapse-arrow'
        [nzRotate]='danger_panel.nzActive ? 90 : 0'></i>
    </ng-template>

  </nz-collapse-panel>

</nz-collapse>
