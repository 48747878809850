import { Environment } from './env-interface'
import { App_Config } from '@ws/constants'
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration develop` replaces `environment.ts` with `environment.develop.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  env_name: App_Config.ENV_NAMES.DEVELOP,
  firebase_config: {
    apiKey: "AIzaSyBFYXWwZ7R2m-5CG-BjhAa8p_aAmH0WsUw",
    authDomain: "yaatly-master.firebaseapp.com",
    databaseURL: "https://yaatly-master.firebaseio.com",
    projectId: "yaatly-master",
    storageBucket: "yaatly-master.appspot.com",
    messagingSenderId: "427177981466",
    appId: "1:427177981466:web:2dbb962cb03aafdb",
  },
  production: false,
  stripe: {
    publishable_key: 'pk_test_wQ9DVFP8LgcxjJRT7KrN3duL001EbtqBd3',
  },
  urls: App_Config.Domains.yaatly.develop,
  use_local: false,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
