import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Location } from '@angular/common'
import { Auth_Service } from '@app/services/auth.service'
import { Routes } from '@ws/constants'

@Injectable({ providedIn: 'root' })
export class Is_Only_Anon_Guard implements CanActivate {
  constructor(
    private readonly _auths: Auth_Service,
    private readonly _loc: Location,
    private readonly _router: Router,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const user = this._auths.user

    if (!user || !user.isAnonymous) {
      return this._router.parseUrl(Routes.str.root())
    }

    return true

  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return await this.canActivate(route, state)
  }
}
