import { DOMAIN_FUNCTIONS } from '../local-config'

const root = 'api-default'

interface I_Make_Route {
  is_local?: boolean
  project: string
  path: string
  region?: string
}

export interface I_Route_Base_Params {
  is_local: boolean
  project: string
}

export const make_route = ({
  is_local = false,
  project,
  path,
  region = 'us-central1',
}: I_Make_Route) => {
  return is_local
    ? `${DOMAIN_FUNCTIONS}/${project}/${region}/${root}/${path}`
    : `https://${region}-${project}.cloudfunctions.net/${root}/${path}`
}
