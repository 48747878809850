import { App_State } from './app/state'
import { Fs_Event_State, Fs_Event_State_Model, FS_EVENT_STATE_TOKEN } from './fs_event/state'
import { Fs_Org_State, Fs_Org_State_Model, FS_ORG_STATE_TOKEN } from './fs_org/state'
import { Fs_Org_Member_State, Fs_Org_Member_State_Model, FS_ORG_MEMBER_STATE_TOKEN } from './fs_org_member/state'
import { Fs_Org_Member_Invite_State, Fs_Org_Member_Invite_State_Model, FS_ORG_MEMBER_INVITE_STATE_TOKEN} from './fs_org_invite/state'
import { Router_State } from './router/state'
import { User_App_State } from './user_app/state'
import { User_Public_State, USER_PUBLIC_STATE_TOKEN} from './user_public/state'
import { User_Private_State } from './user_private/state'

export {
  App_State,
  Fs_Event_State,
  Fs_Event_State_Model,
  FS_EVENT_STATE_TOKEN,
  Fs_Org_Member_Invite_State,
  Fs_Org_Member_Invite_State_Model,
  FS_ORG_MEMBER_INVITE_STATE_TOKEN,
  Fs_Org_Member_State,
  FS_ORG_MEMBER_STATE_TOKEN,
  Fs_Org_Member_State_Model,
  Fs_Org_State,
  Fs_Org_State_Model,
  FS_ORG_STATE_TOKEN,
  Router_State,
  User_App_State,
  User_Private_State,
  User_Public_State,
  USER_PUBLIC_STATE_TOKEN,
}

// Exporting as array b/c state is registered as array of classes in root module (app.module.ts).

export const _States = [
  App_State,
  Fs_Event_State,
  Fs_Org_Member_Invite_State,
  Fs_Org_Member_State,
  Fs_Org_State,
  Router_State,
  User_App_State,
  User_Private_State,
  User_Public_State,
]
