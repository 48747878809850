<div class='flexCenterBoth'>
  <div fxLayoutAlign="center center" class='p-rel h100 w100'>
    <ng-container *ngIf='is_fetching'>
      <nz-spin nzSimple nzSize='small'></nz-spin>
    </ng-container>

    <ng-container *ngIf='!is_fetching && !has_image'>
      <div class='p-rel'>
        <i
          nz-icon nzType='camera'
          nzTheme='outline'
          [ngStyle]="get_camera_styles()"></i>
        <i
          nz-icon
          nzType='stop'
          nzTheme='twotone' nzTwotoneColor='#eb2f96'
          [ngStyle]="get_stop_styles()"></i>
      </div>
    </ng-container>

    <ng-container *ngIf='!is_fetching && has_image'>
      <img
        class='image'
        *ngIf='has_image'
        alt='organization logo'
        [src]='_logo' />
    </ng-container>
  </div>
</div>
