import { I_Route_Base_Params, make_route } from '../utils'

const base = 'event-member'

export class Event_Member {
  static root(project: string, is_local: boolean) {
    return make_route({ is_local, project, path: base})
  }

  static put_event_member_with_org(
    { project, is_local }: I_Route_Base_Params,
    event_id: string,
  ) {
    const path = `${base}/${event_id}/org-member`
    return make_route({ is_local, project, path })
  }
}
