export enum COLOR_PALETTE {
  BLURPLE = '#5e02f4',
  BLURPLE_LIGHT = '#7e34f6',
  DANGER = '#f5222d',
  GRAY_FONT = 'rgb(157, 170, 182)',
  TAG_NEW = '#ff00a0',
  PURPLE = '#ba0bff',
  PINK = '#ff00a0',
  WHITE = '#ffffff',
}
