import { I_Event_Window } from '@ws/schema-fs'
import { ServerValue } from '@firebase/database-types'

export type GUID = string
export type UID = string
export type UID_Record_Type = Record<UID, boolean>
export type GUID_Record_Type = Record<GUID, boolean>

export interface GUID_Type {
  id: GUID
}

export interface Chat {
  description?: string
  name?: string
  owner: Owner_Chat
  private: boolean
  room_id?: GUID
  type_chat: CHAT_TYPE
}

export interface I_Assigned_Team {
  type: ROOM_TEAM_TYPE
  team_no?: number
}

export interface I_Chime_Tags {
  identifier?: string
  team?: I_Assigned_Team
  ilsa_id?: number
}

export type T_Chime_Tags = Record<string, I_Chime_Tags>

export type Chats = Record<GUID, Chat>

export enum CHAT_TYPE {
  BREAKOUT = 'breakout',
  DM = 'dm',
  EVENT = 'event',
  ROOM = 'room',
  ROOM_TEAM = 'room_team',
  ORG = 'organization',
  PUBLIC = 'public',
}

export interface Chat_Message {
  created: number // This is a ServerValue.Timestamp
  message: string
  user_id: GUID
}

// GUID is the Chat_Message id.
export type Chat_Messages = Record<GUID, Chat_Message>
// GUID is the Chat id
export type Event_Chat_Messages = Record<GUID, Chat_Messages>

export interface Owner_Generic {
  id: UID
  organization_id?: GUID
}

export interface Owner_Chat extends Owner_Generic {}
export interface Owner_Event extends Owner_Generic {}
export interface Owner_Room extends Owner_Generic {}

export interface Event_Announcement {
  created: {
    by: {
      id: GUID
      name: string
    }
    on: number // This is a ServerValue.Timestamp
  }
  message: string
  title: string
}

// export interface Event_Banner {
//   message: string
// }

export interface Event {
  allow_anon_login?: boolean
  admin?: UID_Record_Type
  chat_id: GUID
  capacity: {
    [index: string]: number
  }
  is_test: boolean
  // this is 'allow_anon_login'
  is_public?: boolean
  is_listable?: boolean
  owner: Owner_Event
  room_id: GUID
  window: I_Event_Window
}

export interface Event_Dates {
  // This is START_DATE_EPOCH - END_DATE_EPOCH: CAPACITY
  [index: string]: number
}

export interface I_RT_Event_Settings {
  // ao === admin_only
  ao_social_room?: boolean
  auditorium?: I_RT_Event_Settings_Auditorium
  hide_event_hq?: boolean
  hide_postings?: boolean
  hide_social_hall?: boolean
  use_draw_room?: boolean
}

export interface I_RT_Event_Settings_Auditorium {
  url: string
  enabled: boolean
}

export interface I_RT_Event_User_Settings {
  //
}

export interface I_RT_Event_User_Chat_Settings {
  chats?: Record<string, I_Chat_Settings>
  global?: I_Chat_Settings
}

export interface I_Chat_Settings {
  show_alert: boolean
  mute_sound: boolean
}


export interface Event_Data {
  rooms_ann: string
  pairings: string
  schedule: string
  speakers: string
  topics: string
}

export enum EVENT_INVITE_STATUS {
  DECLINED = 'declined',
  INVITED = 'invited',
  PENDING = 'pending',
}

export interface Event_Checkin {
  dates: Event_Checkins
}

// This is just {date: true}
export type Event_Checkins = Record<GUID, true>

export interface Event_Member_Private {
  chats: GUID_Record_Type
  rooms: GUID_Record_Type
  test_time?: number
}

// ! Presence update must be transaction.
export interface I_RT_Event_Member_Presence {
  // Last seen
  ls: ServerValue
  // Status
  s: EVENT_MEMBER_PRESENCE_STATUS
}

export enum EVENT_MEMBER_PRESENCE_STATUS {
  AWAY = 'a',
  OFFLINE = 'of',
  ONLINE = 'on',
}

export interface Event_Member_Public {
  dropped?: boolean
  is_anon?: boolean
  name: string
  organization_id?: GUID
  organizations?: Record<GUID, boolean>
  status?: EVENT_INVITE_STATUS
  system?: Event_Member_Public_System
  rsvp?: boolean
}

export interface I_RT_Event_Member {
  block?: boolean
  tags?: Event_Member_Tags
}

export type Event_Member_Tags = Record<string, string>

export type Event_Members_Public = Record<GUID, Event_Member_Public>

export enum EVENT_MEMBER_PUBLIC_SYSTEM_STATUS {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface Event_Member_Public_System {
  audio: Event_Member_Public_System_Audio
  conn: Event_Member_Public_System_Conn
  video: Event_Member_Public_System_Video
}

interface Event_Member_Public_System_Audio {
  error?: string
  status: EVENT_MEMBER_PUBLIC_SYSTEM_STATUS
}

interface Event_Member_Public_System_Conn {
  error?: string
  rate: number
  status: EVENT_MEMBER_PUBLIC_SYSTEM_STATUS
}

interface Event_Member_Public_System_Video {
  error?: string
  status: EVENT_MEMBER_PUBLIC_SYSTEM_STATUS
}

export interface Event_Organization {
  block: boolean
  chat_id: GUID
  owner_id: UID
  name: string
  room_id: GUID
  rsvp?: boolean
  status?: EVENT_INVITE_STATUS
}

export type Event_Organizations = Record<GUID, Event_Organization>

export interface Generic_Member {
  is_admin?: boolean
  is_member: boolean
  is_owner?: boolean
}

export interface Chat_Member extends Generic_Member {}
export interface Event_Member extends Generic_Member {}
export interface Room_ACL_Member extends Generic_Member {}

export type Generic_Members = Record<UID, Generic_Member>
export type Chat_Members = Record<UID, Chat_Member>
export type Event_Members = Record<UID, Event_Member>
export type Room_ACL_Members = Record<UID, Room_ACL_Member>

// GUID is the Room id
export type Event_Room_ACL_Members = Record<GUID, Room_ACL_Members>
// GUID is the Chat id
export type Event_Chat_Members = Record<GUID, Chat_Members>

export interface Room {
  admin?: Record<string, boolean>
  chat_id: string
  // If this is a daily video room
  daily_url?: string
  event_id: string
  meeting_id: string
  name: ROOM_NAME_DEFAULTS | string
  owner: Owner_Room
  private: boolean
  type_class: ROOM_TYPE_CLASS
  type_layout: ROOM_TYPE_LAYOUT
  // TODO - remove these when implementing RT_Room
  open: boolean
}

export interface I_Org_Room extends Room {
  organization_id: string
}

export interface I_Group_Room extends Room {
  // null values means it's a SOCIAL or HQ room.
  group_id: string | null
}

export interface I_Posting_Room extends Room {
  category_id: string
  posting_id: string
}

export interface I_Posting_Team_Room extends I_Posting_Room {
  teams: Room_Teams
}

export enum ROOM_READINESS {
  GETTING_READY = 'gr',
  MISSING_COMPETITOR = 'mc',
  NEED_HELP = 'nh',
  READY = 'r',
  ROUND_STARTED = 'rs',
}

export interface I_Breakout_Room extends I_Posting_Room {
  parent: string
}

export enum ROOM_TYPE_LAYOUT {
  IE = 'ie',
  STANDARD = 'standard',
  TEAM = 'team'
}

export enum ROOM_TYPE_CLASS {
  BREAKOUT = 'breakout',
  CONGRESS = 'congress',
  CUSTOM_GROUP = 'custom_group',
  HQ = 'hq',
  IE = 'ie',
  MOCK_TRIAL = 'mock_trial',
  MOOT_COURT = 'moot_court',
  SOCIAL = 'social',
  STANDARD = 'standard',
  TEAMS_2 = 'teams_2',
  TEAMS_4 = 'teams_4'
}

export interface Room_Teams {
  judges: Room_Judge_Team
  teams: Room_Team[]
}

export type Room_Teams_Competitors = [Room_Team, Room_Team]

export type Room_Teams_Competitors_Worlds = [Room_Team, Room_Team, Room_Team, Room_Team]

export interface Room_Team {
  name: string
  // Breakout room id.
  room_id: string
  chat_id: string
  type: ROOM_DEBATE_TEAM_TYPE
}

export interface Room_Team_Debate extends Room_Team {
  team: ROOM_DEBATE_TEAM_TYPE
}

export interface Room_Judge_Team {
  members: Room_Judge_Team_Member[]
  room_id: string
  chat_id: string
}

export interface Room_Judge_Team_Member {
  // Determined by file upload.
  label: string
  name: string
}

export interface Room_Judge_Member {
  id: string
}

export enum ROOM_DEBATE_TEAM_TYPE {
  AFF = 'aff',
  NEG = 'neg',
  OG = 'og',
  OO = 'oo',
  CG = 'cg',
  CO = 'co',
}

export enum ROOM_DEBATE_TEAMS_COUNT {
  TWO = 2,
  FOUR = 4
}

export type Rooms = Record<GUID, Room>

export enum ROOM_NAME_DEFAULTS {
  EVENT = 'event'
}

export enum ROOM_TEAM_TYPE {
  COMPETITOR = 'competitor',
  JUDGE = 'judge',
  OBSERVER = 'observer',
}

  // ! Capacity update must be transaction.
export type T_Event_Capacity = number

export interface I_Posting {
  // This is uid: <category_name>
  categories: Record<string, string>
  type: E_POSTING_TYPE
}

// export interface I_IE_Posting extends I_Posting {
//   draw_room_id: string
// }

export enum E_POSTING_TYPE {
  CONGRESS = 'congress',
  DEBATE = 'debate',
  IE = 'ie',
  MT = 'mt',
  GENERIC = 'generic',
}

export interface I_Posting_Category {
  name: string
  rooms: Record<string, boolean>
}


export enum E_IE_ROOM_MEMBER_STATUS {
  DE = 'de',
  READY = 'ready',
  TE = 'te'
}

export interface I_Posting_Room_Member {
  label?: string
  name: string
  team?: number
  type: E_POSTING_ROOM_MEMBER_TYPE
}

export enum E_POSTING_ROOM_MEMBER_TYPE {
  JUDGE = 'judge',
  COMPETITOR = 'competitor',
  OBSERVER = 'observer',
}

export interface I_Posting_Room extends Room {
  category_id: string
  posting_id: string
}

export interface I_Posting_Room_Updates_Speaker {
  user_id: string
  // This is the Posting Room Member ID in Yaatly IE rooms. It's only used to map the posting member to the user id.
  // E.g. chime_tags/meeting_id/user_id = { idendifier: <posting_room_member_id }
  // Note that the meeting_id is the property room.meeting_id on a Room object.
  identity: string
}

export interface I_RT_Posting {
  hide?: boolean
}

export type I_Posting_Banner = string

export interface I_RT_Room {
  open?: boolean
  readiness?: ROOM_READINESS
  use_lobby?: boolean
}

// https://www.npmjs.com/package/ngx-countdown
export interface I_Room_Timer {
  action: E_RT_ROOM_TIMER_ACTIONS
  enabled: boolean
  time: number
  updated_on: number // EPOCH
}

export enum E_RT_ROOM_TIMER_ACTIONS {
  PAUSE = 'pause',
  RESET = 'reset',
  SET = 'set',
  START = 'start',
}

export interface I_RT_IE_Room extends I_RT_Room {
  speaker1?: I_Posting_Room_Updates_Speaker
  speaker2?: I_Posting_Room_Updates_Speaker
  is_duo?: boolean
}

export interface I_RT_Debate_Room extends I_RT_Room {}

export interface I_RT_Room_Member {
  poi?: true
  status?: E_IE_ROOM_MEMBER_STATUS
}

export type T_Any_Room = (Room
  | I_Group_Room
  | I_Breakout_Room
  | I_Posting_Team_Room
  | I_Org_Room
  | I_Posting_Room
) & GUID_Type

export type T_Chime_Attendee_Tag_Values = ROOM_TEAM_TYPE
  | E_CHIME_ATTENDEE_TAG_TEAM_VALUE
  | string

export enum E_CHIME_ATTENDEE_TAG_TEAM_VALUE {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export enum E_CHIME_TEAM_ATTENDEE_TAGS {
  TEAM_TYPE = 'team_type',
  TEAM_NO = 'team_no',
  ILSA_ID = 'ilsa_id',
}

export interface I_Chime_Team_Attendee_Tag {
  Key: E_CHIME_TEAM_ATTENDEE_TAGS
  Value: T_Chime_Attendee_Tag_Values
}

export interface I_Chime_IE_Attendee_Tag {
  Key: 'identity'
  // This is the Posting Room Member ID in Yaatly IE rooms.
  Value: string
}

export interface I_Meeting {
  chime_mid?: string
  acl_id?: string
}

export interface I_Meeting_Member {
  is_admin?: boolean
  is_member: boolean
  is_owner?: boolean
}

export interface I_Draw_Room {
  // This is the event (i.e. category) id.
  meeting_id: string
  categories: I_Draw_Room_Cat_List
}

export interface I_Draw_Room_Cat_List {
  [index: string]: I_Draw_Room_Cat_List_Item
}

export interface I_Draw_Room_Cat_List_Item {
  posting_id: string
}

export interface I_Draw_Room_Category {
  draw_times?: I_Draw_Room_Category_Times
  questions?: I_Draw_Room_Category_Questions
  posting_id: string
}

export interface I_Draw_Room_Category_Questions {
  [index: string]: T_Draw_Room_Category_Question
}

export type T_Draw_Room_Category_Question = string

export interface I_Draw_Room_Category_Times {
  // This is epoch.
  start: number
  // This is minutes.
  prep: number
  // This is minutes.
  interval: number
  num_speakers: number
}

export interface I_RT_Breakout_Room {
  owner_id: string
  name: string
}

export interface I_Pub_Posting_Room_Action_Base {
  category_id: string
  // user id
  created_by: string
  created_on: number // This is a ServerValue.Timestamp
  room_id: string
  posting_id: string
}

export interface I_Pub_Posting_Room_Updated
  extends I_Pub_Posting_Room_Action_Base {}

export interface I_Pub_Posting_Room_Deleted
  extends I_Pub_Posting_Room_Action_Base {}

export interface I_Room_Group {
  name: string
  owner_id: string
  rooms?: Record<string, boolean>
}
