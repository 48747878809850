import { Jessup_Interfaces as JI } from '@ws/constants'
import { FB_Refs_Rtdb as refs } from '@ws/constants'
import { Chat, CHAT_TYPE, E_POSTING_ROOM_MEMBER_TYPE, E_POSTING_TYPE, I_Posting, I_Posting_Category, ROOM_TYPE_CLASS, ROOM_TYPE_LAYOUT } from '@ws/schema-rtdb'
import { E_ROOM_TEAM_TYPE, I_Jessup_Comp_Room, I_Jessup_Comp_Breakout_Room, I_Room_Judge_Team, I_Room_Team } from '@jessup/schema-rtdb'

type T_Create_Key_Fn = (ref: string) => string

interface I_Params {
  matches: JI.I_Jessup_Match[]
  event_id: string
  user_id: string
  create_key: T_Create_Key_Fn
}

interface I_TP_Category_Member {
  member_id: number
  model: JI.I_ILSA_Posting_Room_Member
}

interface I_Ids {
  event_id: string
  posting_id: string
  category_id: string
  room_id: string
}

export const parse_jessup_matches = ({
  matches,
  event_id,
  user_id,
  create_key,
}: I_Params
) => {
  // We only need one posting.
  const posting_id = create_key(refs.posting_col(event_id))
  let updates: any = {}
  // We only need one category.
  updates[refs.posting_category_col({event_id, posting_id})] = {}
  // We only have 1 category for jessup postings.
  const category_name = 'default'
  const category_id = create_key(
    refs.posting_category_col({event_id, posting_id}),
  )
  const posting_categories: I_Posting['categories'] = {
    [category_id]: category_name
  }
  const room_ids: I_Posting_Category['rooms'] = {}
  const eprm_ref = refs.posting_room_member_stop_at_posting_id({event_id, posting_id})
  updates[eprm_ref] = {}
  updates[eprm_ref][category_id] = {}

  // create a room for each match
  matches.forEach((r) => {
    // Get the room (a section is a Room and vice versa).
    const chat_id = create_key(refs.chat_col(event_id))
    const room_name = r.room_name
      ? r.room_name
      : `${r.app_team_id}A vs. ${r.res_team_id}R`
    // * NOTE - we're going to try using match_ids as the room ID
    // const room_id = create_key(refs.room_col(event_id))
    const room_id = r.match_id
    room_ids[room_id] = true
    updates[eprm_ref][category_id][room_id] = {}
    // Get the team breakout room ids.
    const team_br_ids = [
      create_key(refs.room_col(event_id)),
      create_key(refs.room_col(event_id)),
    ]

    const app_users_team: JI.I_Review_Team = {
      members: r.app_users,
      name: r.app_team_id,
      type: E_ROOM_TEAM_TYPE.APP
    }

    if (r.app_mem) {
      app_users_team.memorial_link = r.app_mem
    }

    const res_users_team: JI.I_Review_Team = {
      members: r.res_users,
      name: r.res_team_id,
      type: E_ROOM_TEAM_TYPE.RES
    }

    if (r.res_mem) {
      res_users_team.memorial_link = r.res_mem
    }

    const room_teams = get_room_teams(
      team_br_ids,
      [app_users_team, res_users_team],
      event_id,
      create_key,
    )

    const judges_br_id = create_key(refs.room_col(event_id))
    const judge_chat_id = create_key(refs.chat_col(event_id))

    const room_judges: I_Room_Judge_Team = {
      chat_id: judge_chat_id,
      members: r.judges,
      room_id: judges_br_id,
    }

    const room: I_Jessup_Comp_Room = {
      admin: {[user_id]: true},
      category_id,
      chat_id,
      event_id,
      match_id: r.match_id,
      meeting_id: create_key(refs.meeting_root(event_id)),
      name: room_name,
      open: true,
      open_time: r.open,
      owner: { id: user_id },
      posting_id,
      private: false,
      teams: {
        judges: room_judges,
        teams: room_teams,
      },
      type_class: ROOM_TYPE_CLASS.TEAMS_2,
      type_layout: ROOM_TYPE_LAYOUT.TEAM,
    }

    if (r.app_observers?.length) {
      room.teams.app_observers = r.app_observers
    }

    if (r.res_observers)  {
      room.teams.res_observers = r.res_observers
    }

    if (r.room_link) {
      room.room_link = r.room_link
    }

    // Now the room
    updates[refs.room(event_id, room_id.toString())] = room

    const room_chat: Chat = {
      // Chat name is same as room
      name: `Round ${r.match_id.toString()} chat`,
      owner: { id: user_id },
      private: false,
      room_id: room_id.toString(),
      type_chat: CHAT_TYPE.ROOM,
    }

    const judge_chat: Chat = {
      name: 'Judges chat',
      owner: { id: user_id },
      private: false,
      room_id: room_id.toString(),
      type_chat: CHAT_TYPE.ROOM,
    }

    // add chats
    updates[refs.chat(event_id, chat_id)] = room_chat
    updates[refs.chat(event_id, judge_chat_id)] = judge_chat

    // Add a chat for each team.
    room_teams.forEach((t) => {
      updates[refs.chat(event_id, t.chat_id)] = {
        event_id,
        name: t.name,
        owner: { id: user_id },
        private: false,
        room_id,
        type_chat: CHAT_TYPE.ROOM_TEAM,
      }
    })

    // Get the posting room members
    const members: I_TP_Category_Member[] = get_posting_room_members({
      judges: r.judges,
      teams: [app_users_team, res_users_team],
      ids: {event_id,posting_id,category_id,room_id: room_id.toString()}
    })

    // Set the posting room members.
    members.forEach((m) => {
      // Now with each member
      updates[eprm_ref][category_id][room_id][m.member_id] = m.model
    })

    // Get the breakout room stuff.
    const team_br_updates = get_br_updates_teams(
      room,
      room_id.toString(),
      team_br_ids,
      create_key,
      r.open,
    )
    const judge_br_updates = get_br_updates_judges(
      room,
      room_id.toString(),
      judges_br_id,
      create_key,
      r.open,
    )
    updates = { ...updates, ...team_br_updates, ...judge_br_updates}
  })

  // Set the posting category update.
  const pc_ref = refs.posting_category_col({event_id, posting_id})
  updates[pc_ref][category_id] = {
    name: category_name,
    rooms: room_ids
  }

  // Set the posting update
  updates[refs.posting(event_id, posting_id)] = {
    categories: posting_categories,
    type: E_POSTING_TYPE.DEBATE
  }

  return updates
}

function get_room_teams(
  team_br_ids: string[],
  teams: JI.I_Review_Team[],
  event_id: string,
  create_key: T_Create_Key_Fn,
): I_Room_Team[] {
  return teams.map((t, i) => {
    const t2: I_Room_Team = {
      chat_id: create_key(refs.chat_col(event_id)),
      members: t.members,
      name: t.name,
      room_id: team_br_ids[i],
      type: t.type
    }

    if (t.memorial_link) {
      t2.memorial_link = t.memorial_link
    }
    return t2
  })
}

function get_br_updates_judges(
  room: I_Jessup_Comp_Room,
  room_id: string,
  br_id: string,
  create_key: T_Create_Key_Fn,
  open_time: number,
) {
  return get_team_br_updates(
    room,
    room_id,
    get_judge_team_name(),
    br_id,
    create_key,
    true,
    open_time,
  )
}

function get_br_updates_teams(
  room: I_Jessup_Comp_Room,
  room_id: string,
  br_ids: string[],
  create_key: T_Create_Key_Fn,
  open_time: number
) {
  let updates: any = {}

  // Get the breakout rooms for teams.
  Object.values(room.teams.teams ?? {}).forEach((team, i) => {
    const team_updates = get_team_br_updates(
      room,
      room_id,
      team.name.toString(),
      br_ids[i],
      create_key,
      false,
      open_time,
    )

    updates = { ...updates, ...team_updates }
  })
  return updates
}

function get_judge_team_name() {
  return 'Deliberation'
}

function get_team_br_updates(
  room: I_Jessup_Comp_Room,
  parent_room_id: string,
  room_name: string,
  br_id: string,
  create_key: (ref: string) => string,
  is_judge: boolean,
  open_time: number,
) {
  const updates: any = {}
  const cid = create_key(refs.chat_root(room.event_id))
  const eid = room.event_id

  const breakout_room: I_Jessup_Comp_Breakout_Room = {
    // The original room creator/owner will  be the admin of this room.
    admin: { [room.owner.id]: true },
    category_id: room.category_id,
    chat_id: cid,
    event_id: eid,
    is_delib: is_judge,
    is_prep: !is_judge,
    meeting_id: create_key(refs.meeting_root(eid)),
    name: room_name,
    open: true,
    open_time,
    owner: room.owner.organization_id
      ? { id: room.owner.id, organization_id: room.owner.organization_id }
      : { id: room.owner.id },
    parent: parent_room_id,
    posting_id: room.posting_id,
    private: true,
    type_layout: ROOM_TYPE_LAYOUT.STANDARD,
    type_class: ROOM_TYPE_CLASS.BREAKOUT,
  }

  if (is_judge) {
    breakout_room.is_delib = true
  } else {
    breakout_room.is_prep = true
  }

  // The room chat.
  const breakout_room_chat: Chat = {
    owner: room.owner,
    name: room_name,
    private: true,
    room_id: parent_room_id,
    type_chat: CHAT_TYPE.BREAKOUT,
  }

  updates[refs.room(eid, br_id)] = breakout_room
  updates[refs.chat(eid, cid)] = breakout_room_chat
  return updates
}

function get_posting_room_members(p: {
  judges: number[],
  teams: JI.I_Review_Team[],
  ids: I_Ids,
}): I_TP_Category_Member[] {
  return judge_map_fn(p.judges)
    // Applicant team.
    .concat(competitor_map_fn(p.teams[0].members, 0))
    // Respondent team.
    .concat(competitor_map_fn(p.teams[1].members, 1))
}

function competitor_map_fn(
  arr: number[],
  team: number
) {
  return arr.map((ilsa_id) => {
    return {
      member_id: ilsa_id,
      model: {
        name: ilsa_id,
        team,
        type: E_POSTING_ROOM_MEMBER_TYPE.COMPETITOR
      }
    }
  })
}

function judge_map_fn(
  arr: number[],
): I_TP_Category_Member[] {
  return arr.map((ilsa_id) => {
    return {
      member_id: ilsa_id,
      model: {
        name: ilsa_id,
        type: E_POSTING_ROOM_MEMBER_TYPE.JUDGE,
      }
    }
  })
}

