import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import firebase from 'firebase/app'
import { User_Service } from '@app/services/user.service'
import { Router } from '@angular/router'
import { App_Config, Routes } from '@ws/constants'
import { environment } from '@app/env'
import { App_Logging_Service } from '@client/services'

/**
 * This is the layer that interacts with Firebase auth. There
 * is also an init() function that does some data fetching
 * in the beginning.
 */
@Injectable({ providedIn: 'root' })
export class Auth_Service {
  private _is_auth = false
  // Only flips once - when user logs in.
  private logged_in = false
  private logged_in_anonymously = false
  // Place to store redirect url, if needed.
  private redirect_url: string | undefined = undefined
  private _user: firebase.User | undefined | null = undefined
  // Flag set after first user login (so we know when they logout).
  private did_auth = false

  constructor(
    private readonly _af_auth: AngularFireAuth,
    private readonly _logger: App_Logging_Service,
    private readonly _router: Router,
    private readonly _user_s: User_Service,
  ) {
    // Sets the current user before app starts. This was obtained in main.ts.
    // this._user = this._user_s.get_user()
  }

  get_redirect() {
    return this.redirect_url
  }

  /**
   * Inits the firebase user auth listener. Since this service is provided at
   * the root of the application, only one instance will exist, therefore
   * we can perform necessary logic to handle various user states here.
   *
   * Scenarios:
   * 1. User arrives logged out. They can then login, at which point the app loads their data.
   * 2. User arrives logged in. Their data is loaded.
   * 3. User logs in then out. Reload the browser.
   * @return  [Promise]  any
   */
  async init(): Promise<any> {
    const res = await this._user_s.get_user()
    if (res) {
      this._user = res
      this._is_auth = true
    }
    await this._user_s.maybe_set_user()

    /**
     * First handle the user state that was grabbed prior to app load.
     * Why do this? B/c, we unsubscribed from the onauth listener
     * in main.ts, so that we can leverage the auth service here. Also,
     * we can immediately load the user data since we now have auth state,
     * instead of a delay to wait for auth state AFTER the app is boostrapped.
    */
    if (this._user) {
      this.did_auth = true
    }

    firebase.auth().onAuthStateChanged(async(user) => {
      if (!user) {
        this._user = null
      }
      // This could occur if user arrives logged in, or logs in later. Either way, we care.
      if (user) {
        await this._maybe_print_jwt(user)
        this._user = user
        this._is_auth = true
        // Flag the user as auth'd for potential logout scenario use.
        this.did_auth = true
        // Scenario where user logged in after attempting to access an auth-only page.
        if (this.logged_in && this.redirect_url) {
          // Send the user where they intended to go before needing to login.
          this._router.navigateByUrl(this.redirect_url)
          // Redirect is complete.
          this.redirect_url = undefined
          return
        } else if (this.logged_in) {
          // console.log('User logged without redirect. Routing to home.')
          this._router.navigateByUrl(Routes.str.root())
          return
        }
      // Scenario - user logged out. Just reload the browser.
      } else if (!user && this.did_auth && !this.redirect_url) {
        this._is_auth = false
        window.location.reload()
        // Whatever scenario this is, if there is a redirect, then redirect!
      } else if (this.redirect_url) {
        this._router.navigateByUrl(this.redirect_url)
      }
    })
  }

  private async _maybe_print_jwt(user: firebase.User) {
      if (environment.env_name === App_Config.ENV_NAMES.DEV) {
        if (user) {
          const jwt = await user.getIdToken()
          if (jwt) {
            this._logger.debug('My JWT: ', {jwt})
            this._logger.debug('My uid: ', {uid: user.uid})
            this._logger.debug('My email: ', {email: user.email})
          }
        }
      }
  }

  logout(): Promise<void> {
    return this._af_auth.signOut()
  }

  async login(email: string, password: string) {
    this.logged_in = true
    const creds = await this._af_auth.signInWithEmailAndPassword(email, password)
    this._user = creds.user!
    this._is_auth = true
    return creds
  }

  async login_anonymously() {
    this.logged_in_anonymously = true
    const creds = await this._af_auth.signInAnonymously()
    this._user = creds.user!
    this._is_auth = true
    return
  }

  async register_email(email: string, password: string) {
    this.logged_in = true
    const creds = await this._af_auth.createUserWithEmailAndPassword(email, password)
    this._user = creds.user!
    this._is_auth = true
    return creds
  }

  send_password_reset_email(email: string): Promise<void> {
    const domain = environment.urls.app
    const path = Routes.str.login(false)
    const url = `${domain}/${path}`
    return this._af_auth.sendPasswordResetEmail(email, {url})
  }

  set_on_auth_redirect(url: string) {
    this._logger.debug('setting redirect url: ', {url})
    this.redirect_url = url
  }

  get user() {
    return this._user
  }

  get user_id() {
    return this._user?.uid ?? ''
  }

  get is_auth() {
    return this._is_auth
  }

  async get_af_auth() {
    return await this._af_auth.currentUser
  }
}
