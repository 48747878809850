import { Component, OnInit, Input } from '@angular/core'
import { COLOR_PALETTE } from '@ws/constants'

@Component({
  selector: 'logo-letter',
  templateUrl: './logo-letter.component.html',
  styleUrls: ['./logo-letter.component.scss']
})
export class Logo_Letter_Component implements OnInit {
  @Input() height = ''
  @Input() width = ''
  @Input() fill = ''
  dimensions: any = {}
  fill_default = COLOR_PALETTE.PURPLE

  ngOnInit() {
    this.set_styles()
  }

  private set_styles() {
    this.dimensions = {
      height: this.height || 'auto',
      width: this.width || 'auto',
    }
  }

}
