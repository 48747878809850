<div
  class='container'
  fxLayout='row'
  fxLayoutAlign='center center'
  [ngStyle]=styles>
  <nz-spin
    nzSimple
    nzTip='{{ message }}'
    nzSize='large'>
  </nz-spin>
</div>
