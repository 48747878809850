<div
  class='box'
  [ngStyle]="{'font-size': font_size || '1.4rem'}">
  <i
    nz-icon
    nzType='experiment'
    nzTheme='twotone'></i>
  <span
    *ngFor='let l of letters; index as i'
    [ngStyle]="{'color': hex[i]}">{{l}}</span>
</div>
