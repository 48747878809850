import { InjectionToken } from '@angular/core'
import { Interfaces, App_Config } from '@ws/constants'

// export * from './lib/providers';

export interface I_Client_Env {
  env_name: App_Config.ENV_NAMES
  firebase_config: Interfaces.Firebase_Config
  production: boolean
  urls: I_Env_Urls
  use_local: boolean
}

export interface I_Env_Urls {
  app: string
  info: string
  pp: string
  pricing: string
  public: string
  tos: string
}

export const ENV_CONFIG = new InjectionToken<I_Client_Env>('ENV_CONFIG')
