import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Room } from '@ws/schema-rtdb'

@Component({
  selector: 'shared-text-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Shared_Text_Input_Component implements OnInit {
  is_ready = false
  form!: FormGroup
  rooms: Room[] = []
  saving = false
  // @Input() input_action!: (input: string) => any
  @Input('input_value') set input_value(value: string | undefined) {
    this.input_value = value ?? ''
  }
  @Input('input_id') set input_id(value: string | undefined) {
    this.input_id = value || new Date().toISOString()
  }
  @Input() input_placeholder = ''
  @Input() input_label: string | undefined
  // @Output() done = new EventEmitter<any>()
  @ViewChild('text', {static:false}) textarea?: ElementRef

  constructor(
    readonly _cd: ChangeDetectorRef,
    private readonly _fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      input: [this.input_value, Validators.required]
    })
    this.is_ready = true
    this._cd.detectChanges()
  }


  get classes() {
    return {
      hidden: !this.input_label
    }
  }
}
