declare const require: any
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
 } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { CONSTANTS as C } from '@ws/constants'
import { map, take, debounceTime, takeUntil } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/firestore'
import { Firebase_Service } from '@app/services/firebase.service'
import { App_Store_Service } from '@app/services/store.service'
import { FB_Refs_Http as http_refs } from '@ws/constants'
import { Misc_Utils, App_To_Db_Shapers } from '@app/utils'
import { Organization, GUID_Type, ORG_MEMBER_ROLE } from '@ws/schema-fs'
import { App_Fs_Org } from '@app/types'
import { Subject } from 'rxjs'
import { Custom_Validator } from '@app/utils'
import { Auth_Service } from '@app/services/auth.service'
import { User_Service } from '@app/services/user.service'
const _cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'org-form',
  templateUrl: './org-form.component.html',
  styleUrls: ['./org-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Org_Form_Component implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>()
  form!: FormGroup
  name_length = 0
  name_length_limit = C.ORG_NAME_LENGTH_LIMIT
  submitted = false
  submitting = false
  success = false

  @Input() button_align?: string // 'left', 'center' or 'right
  @Input() button_size?: string
  @Input() button_text?: string
  @Input() callback!: any
  @Input() org?: App_Fs_Org
  @Input() show_labels?: boolean
  // Signals org creation is done.
  @Output() done = new EventEmitter<string>()


  constructor(
    private readonly _afs: AngularFirestore,
    private readonly _auths: Auth_Service,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _fb: FormBuilder,
    private readonly _fb_s: Firebase_Service,
    private readonly _nzms: NzMessageService,
    private readonly _ss: App_Store_Service,
    private readonly _us: User_Service,
  ) {}

  ngOnInit(): void {
    this.init_form()
    // Keep the template updated with the length of the org input.
    this.form.get('name')!.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      (val:any) => this.name_length = val.length || 0)
  }

  ngOnDestroy(): void {
    // Kill subscriptions.
    this.destroy$.next(true)
    // Now let's also unsubscribe from the subject itself:
    // this.destroy$.unsubscribe()
  }

  private get_error_message(error: any): string {
    let m = ''
    switch(error.code) {
      case C.HTTP_ERROR_CODES.ALREADY_EXISTS:
        m = 'That organization already exists.'
        break
      default:
        m = error.message || error.toString()
    }

    return m
  }

  get name(): AbstractControl | null {
    return this.form.get('name')
  }

  init_form() {
    this.form = this._fb.group({
      name: [(this.org && this.org.name) || '', [
        Validators.required,
        Validators.maxLength(C.ORG_NAME_LENGTH_LIMIT),
      ],[
        Custom_Validator.is_name_available(this._afs),
      ]],
    })
  }

  async submit_form(): Promise<void> {
    if (this.submitting || !this.form.valid) {
      return
    }

    this.submitting = true

    const name = this.name && this.name.value
    if (!name) {
      return
    }

    Object.keys(this.form.controls).forEach((key) => {
      this.form.controls[key].disable()
    })

    let res: Organization & GUID_Type


    try {
      res = await this.save_organization(name)

      if (res.id) {
        this.success = true
        // Cache it locally so app has immediate access.
        this.store_org(res)
        this.done.next(res.id)
        // If this component is being used in a modal, call the function it gives us.
        if (this.callback && res) {
          this.callback(res.id!)
        }
      }
    } catch (e) {
      console.error(e)
      const message = this.get_error_message(e)
      this._nzms.error(message, { nzDuration: 4000 })
      // Re-enabled the form controls. emitEvent = false so validation does not trigger upon renable.
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].enable({emitEvent: false})
      })
    } finally {
      this.submitting = false
      // Reenable the form.
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].enable({emitEvent: false})
      })
    }
    this._cdr.detectChanges()
  }

  // Cache the new org and update it locally
  private store_org(org: Organization & GUID_Type) {
    const app_org: App_Fs_Org = Object.assign(
      {},
      org,
      {
        is_new: this.org && this.org.id ? false : true,
        joined_on: Misc_Utils.get_fs_timestamp_now(),
        role: ORG_MEMBER_ROLE.OWNER,
      }
    )
    this._ss.set_org(app_org)
    this.org = app_org
  }

  private async save_organization(name: string) {
    const data: Organization = this.get_org_data()

    return await this._fb_s.callable_req(
      http_refs.callable_put_organization(),
      data,
    )
  }

  private get_org_data(): Organization {
    // Determine if this is new org or org is being updated
    let org: Organization

    if (this.org) {
      org = this.get_update_org_data();
      // For HTTP DB Put ops (rare), the id will be needed, even though it is not part of the schema. Add it for this particular request.
      (org as any).id = this.org!.id
    } else {
      org = this.get_new_org_data()
    }

    return org
  }

  private get_update_org_data(): Organization {
    const user = this._ss.get_user_public()
    const org = Object.assign(
      {},
      App_To_Db_Shapers.fs.org(_cloneDeep(this.org!)),
    )
    org.audit.modified = Misc_Utils.get_audit_data_wo_org(
      this._auths.user_id,
      user.name,
      C.CRUD_AUDIT_TYPE.UPDATE,
    )
    org.name = this.name && this.name.value
    return org
  }

  private get_new_org_data() {
    const user = this._ss.get_user_public()
    const created = Misc_Utils.get_audit_data_wo_org(this._auths.user_id, user.name)
    const modified = Misc_Utils.get_audit_data_wo_org(
      this._auths.user_id,
      user.name,
      C.CRUD_AUDIT_TYPE.CREATE,
    )

    return {
      audit: {
        created,
        modified,
      },
      name: this.name && this.name.value,
    }
  }
}
