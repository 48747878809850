<svg
  id="Layer_1"
  data-name="Layer 1"
  [ngStyle]="dimensions"

  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1010 408.58">
  <defs>
      <!-- <style ref='style'>.cls-1{fill:#ba0bff;}</style> -->
  </defs>
  <title>logo</title>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M162.55,131.06V320.32c0,41.87-21.36,74.95-61.55,74.95L78,348c12.14,0,24.7-8.38,24.7-27.64v-36a60.23,60.23,0,0,1-43.55,18c-34.33,0-55.69-24.71-55.69-58.62V131.06H63.31V232.39c0,11.72,5.86,20.1,17.59,20.1,10.47,0,18.84-8,21.77-20.94V131.06Z"/>
</svg>
