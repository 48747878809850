import { Injectable, ElementRef } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class Templates_Service {
  templates:any = {}

  add(name: string, ref: ElementRef) {
    this.templates[name] = ref
  }
  get(name: string) {
    return this.templates[name]
  }

  constructor() { }
}
