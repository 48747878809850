// This enum class is the central place to get routes for the entire
export enum STORE_ENTITIES {
  APP = 'app',
  FS_EVENTS = 'fs_events',
  FS_ORGANIZATIONS = 'fs_organizations',
  FS_ORG_MEMBER_INVITES = 'fs_org_member_invites',
  FS_ORG_MEMBERS = 'fs_org_members',
  LISTENERS = 'listeners',
  ROOT = 'root',
  ROUTER = 'router',
  RTDB_CHAT = 'rtdb_chat',
  RTDB_CHAT_MEMBER = 'rtdb_chat_member',
  RTDB_CHAT_MESSAGE = 'rtdb_chat_message',
  RTDB_EVENT = 'rtdb_event',
  RTDB_EVENT_DATA = 'rtdb_event_data',
  RTDB_EVENT_MEMBER = 'rtdb_event_member',
  RTDB_EVENT_MEMBER_PRESENCE = 'rtdb_event_member_presence',
  RTDB_EVENT_ORG = 'rtdb_event_organization',
  RTDB_ROOM = 'rtdb_room',
  RTDB_ROOM_MEMBER = 'rtdb_room_member',
  USER_APP = 'user_app',
  USER_PUBLIC = 'user_public',
  USER_PRIVATE = 'user_private',
}
