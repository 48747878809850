declare const require:any
import { Injectable } from '@angular/core'
import { LISTENER_TYPE_RTDB as LISTENER } from '@app/constants'
import { FB_Refs_Rtdb as refs } from '@ws/constants'
import * as Schema from '@ws/schema-rtdb'
import firebase from 'firebase/app'
require('firebase/database')
import {
  unpack_rtdb_obj,
  unpack_rtdb_col,
  unpack_rtdb_prim,
  unpack_rtdb_prim_col,
  unpack_rtdb_obj_no_id,
} from './utils'
import { Misc_Utils } from '@app/utils'
import { GUID_Type, Event_Member_Public_System, Chat_Message, Chat, Chat_Members, Event_Member_Public, Event_Announcement, ROOM_READINESS, E_IE_ROOM_MEMBER_STATUS, I_Room_Timer, I_Posting_Banner, T_Event_Capacity, I_RT_Event_Member_Presence, I_Pub_Posting_Room_Deleted, I_Room_Group, Chat_Member } from '@ws/schema-rtdb'
import clonedeep from 'lodash.clonedeep'
import { Auth_Service } from './auth.service'

interface Rtdb_Listeners {
  [LISTENER.CHILD_ADDED]: Record<string, boolean>
  [LISTENER.CHILD_CHANGED]: Record<string, boolean>
  [LISTENER.CHILD_REMOVED]: Record<string, boolean>
  [LISTENER.VALUE]: Record<string, boolean>
}

/**
 * This is the interface to the Realtime DB api. It handles
 * all the SDK calls. Also, Firebase's listeners can
 * be tricky to manage, so the RTDB listeners will all be handled
 * here in order to keep track of listeners and centralize logic.
 *
 * You will notice there may be cases of the Partial<T>
 * Typescript utility. During set operations, this will always
 * be paired with { merge: true }, thus ensuring partial types
 * will merge appropriately with Firebase data, while also
 * ensuring type safety.
 */
@Injectable({providedIn: 'root'})
export class Rtdb_Service {
  db = firebase.database()
  // Tracks all Realtime DB listeners.
  listeners: Rtdb_Listeners = {
    [LISTENER.CHILD_ADDED]: {},
    [LISTENER.CHILD_CHANGED]: {},
    [LISTENER.CHILD_REMOVED]: {},
    [LISTENER.VALUE]: {},
  }

  constructor(
    private readonly _auths: Auth_Service,
  ) { }

  static create_key(ref: string): string {
		return firebase.database().ref(ref).push().key as string
  }

	// Sets a callback for the given realtime event at the given ref.
	listen({
    listener_type,
    ref,
    callback,
    track = true,
  }: {
    listener_type: LISTENER,
    ref: string,
    callback: any,
    track?: boolean,
  }) {
		// Make sure this listener is not already attached.
    if (this.listeners[listener_type][ref]) {
      console.log(`Service for ${ref} already loaded. Returning.`)
      return
    }

		try {
			// Set the listener.
			this.db.ref(ref).on(listener_type, callback, (e: any) => {
				console.log(`Error listening for event ${listener_type} on ref ${ref}`)
        console.error(e)
      })

      if (track) {
        // Track the listener.
        this.listeners[listener_type][ref] = true
      }
		} catch (e) {
      console.error(e)
		}
  }

  	// Sets a callback for the given realtime event at the given ref.
	listen_to_last({
    listener_type,
    ref,
    query_key,
    callback,
    track = true,
  }: {
    listener_type: LISTENER,
    ref: string,
    query_key: string,
    callback: any,
    track?: boolean,
  }) {
		// Make sure this listener is not already attached.
    if (this.listeners[listener_type][ref]) {
      console.log(`Service for ${ref} already loaded. Returning.`)
      return
    }

		try {
			// Set the listener.
      this.db.ref(ref)
      .orderByChild(query_key)
      .limitToLast(1)
      .on(listener_type, callback, (e: any) => {
				console.log(`Error listening for event ${listener_type} on ref ${ref}`)
        console.error(e)
      })

      if (track) {
        // Track the listener.
        this.listeners[listener_type][ref] = true
      }
		} catch (e) {
      console.error(e)
		}
  }

  async add_event_announcement(
    event_id: string,
    ann: Event_Announcement,
  ) {

    let res: firebase.database.Reference
    try {
      res = await this.db
        .ref(refs.event_announcement_col(event_id))
        .push(ann)
    } catch (e) {
      console.error(e)
      return undefined
    }
    return res
  }

  async add_room_group(event_id: string, g: I_Room_Group) {
    try {
      await this.db.ref(refs.event_room_group_col(event_id)).push(g)
      return 0
    } catch (error) {
      return 1
    }
  }

  async delete_chat(event_id: string, chat_id: string) {
    try {
      await this.db.ref(refs.chat(event_id, chat_id)).remove()
      return 0
    } catch (e) {
      return 1
    }
  }

  async delete_room_group(event_id: string, g: I_Room_Group & GUID_Type) {
    const updates: any = {}
    updates[refs.event_room_group(event_id, g.id)] = null
    Object.keys(g.rooms ?? {}).forEach((rid) => {
      updates[refs.room(event_id, rid)] = null
    })

    try {
      await this.run_updates(updates)
      return 0
    } catch (e) {
      return 1
    }
  }

  async delete_chat_member(p: {
    event_id: string,
    chat_id: string,
    member_id: string,
  }) {
    // TODO - Run this thru the API so modifying em_private_chat can be more secure.
    return this.run_updates({
      [refs.chat_member(p.event_id, p.chat_id, p.member_id)]: null,
      [refs.em_private_chat(p.event_id, p.member_id, p.chat_id)]: null,
    })
  }

  delete_event_room(event_id: string, room_id: string) {
    return this.db.ref(refs.room(event_id, room_id)).remove()
  }

  delete_event_tag_member(event_id: string, tag: string) {
    // Since tags are the the keys themselves, get an encoded version of the tag as the key.
    const key = Misc_Utils.rtdb_key_encode(tag)
    // return this.db.ref(refs.event_tag_member(event_id, key)).remove()
  }

  async delete_posting(eid: string, pid: string) {
    return this.db.ref(refs.posting(eid, pid)).remove()
  }

  async delete_posting_category_room(p: {
    event_id: string
    posting_id: string
    category_id: string
    room_id: string
  }) {
    const message: I_Pub_Posting_Room_Deleted = {
      category_id: p.category_id,
      created_by: this._auths.user_id,
      created_on: Misc_Utils.get_rtdb_timestamp(),
      room_id: p.room_id,
      posting_id: p.posting_id,
    }

    return Promise.all([
      this.db.ref(refs.posting_category_room(p)).remove(),
      this.db.ref(refs.room(p.event_id, p.room_id)).remove(),
      this.db.ref(refs.event_pub_sub_posting_room_delete(p.event_id)).set(message)
    ])
  }

  delete_group_room(
    event_id: string,
    group_id: string,
    room_id: string,
  ) {
    return Promise.all([
      this.db.ref(refs.event_room_group_room(event_id, group_id, room_id)).remove(),
      this.db.ref(refs.room(event_id, room_id)).remove(),
    ])
  }

  delete_hq_room(event_id: string, room_id: string) {
    return Promise.all([
      this.db.ref(refs.event_hq_room(event_id, room_id)).remove(),
      this.db.ref(refs.room(event_id, room_id)).remove(),
    ])
  }

  delete_social_room(event_id: string, room_id: string) {
    return Promise.all([
      this.db.ref(refs.event_social_room(event_id, room_id)).remove(),
      this.db.ref(refs.room(event_id, room_id)).remove(),
    ])
  }

	async get_chat(event_id: string, chat_id: string) {
    const query = await this.db.ref(refs.chat(event_id, chat_id)).once('value')
		return unpack_rtdb_obj<Schema.Chat & GUID_Type>(query)
	}

	async get_chat_member_col(event_id: string, chat_id: string) {
    let query
    try {
      query = await this.db
			.ref(refs.chat_member_col(event_id, chat_id))
      .once('value')
    } catch (e) {
      console.error(e)
      return undefined
    }
		return unpack_rtdb_col<Schema.Chat_Member>(query)
  }

  async get_chat_messages_once(
    event_id: string,
    chat_id: string,
    limitToLast: number,
  ) {
    const query = await this
      .db
      .ref(refs.chat_message_col(event_id, chat_id))
      // .orderByChild('created')
      .orderByKey()
      .limitToLast(limitToLast)
      .once('value')
    return unpack_rtdb_col<Schema.Chat_Message>(query)
  }

  async get_chat_messages_at(
    event_id: string,
    chat_id: string,
    end_at: number,
  ) {
    const query = await this
      .db
      .ref(refs.chat_message_col(event_id, chat_id))
      .orderByChild('created')
      // .orderByKey()
      .limitToLast(10)
      .endAt(end_at)
      .once('value')
    return unpack_rtdb_col<Schema.Chat_Message>(query)
  }

  async get_event_user_chat_settings(p: {
    event_id: string,
    user_id: string
  }) {
    let query
    try {
      query = await this.db
        .ref(refs.event_rt_user_chat_settings(p))
        .once('value')
    } catch (e) {
      console.error(e)
      return undefined
    }

		return unpack_rtdb_obj_no_id<boolean>(query)
  }

  async get_chime_tags(p: {
    event_id: string,
    meeting_id: string,
    user_id: string,
  }) {
    const query = await this.db.ref(refs.chime_tags(p)).once('value')
    const obj = unpack_rtdb_obj<Schema.I_Chime_Tags>(query)

    // Don't need the id for this
    if (obj?.id) {
      // @ts-ignore
      delete obj.id
    }
    return obj
  }

  get_db() {
    return this.db
  }

  async get_collection<T>(ref: string) {
    const query = await this.db.ref(ref).once('value')
    return unpack_rtdb_col<T>(query)
  }

  async get_prim_collection<T>(ref: string) {
    const query = await this.db.ref(ref).once('value')
    return unpack_rtdb_prim_col<T>(query)
  }

  async get_draw_room(event_id: string) {
    const query = await this.db.ref(refs.draw_room_root(event_id)).once('value')
    return unpack_rtdb_obj_no_id<Schema.I_Draw_Room>(query)
  }

  async get_draw_room_times(p: {event_id: string, category_id: string}) {
    const query = await this.db.ref(refs.draw_room_times(p)).once('value')
    return unpack_rtdb_obj_no_id<Schema.I_Draw_Room_Category_Times>(query)
  }

  async get_draw_room_questions(p: {event_id: string, category_id: string}) {
    const query = await this.db.ref(refs.draw_room_questions(p)).once('value')
    return unpack_rtdb_obj_no_id<Schema.I_Draw_Room_Category_Questions>(query)
  }

	async get_event(event_id: string) {
    const query = await this.db.ref(refs.event(event_id)).once('value')
		return unpack_rtdb_obj<Schema.Event & Schema.GUID_Type>(query)
  }

  async get_event_admin(event_id: string, uid: string) {
    const query = await this.db.ref(refs.event_admin(event_id, uid)).once('value')
		return unpack_rtdb_prim<boolean>(query)
  }

  async get_event_capacity(event_id: string) {
    const query = await this.db.ref(refs.event_capacity(event_id)).once('value')
    return unpack_rtdb_prim<T_Event_Capacity>(query).data
  }

  async get_event_member(event_id: string, user_id: string) {
    const query = await this.db.ref(refs.event_member_public(event_id, user_id)).once('value')
    return unpack_rtdb_obj<Schema.Event_Member_Public>(query)
  }

	async get_event_member_presence_col(event_id: string){
    const query = await this.db
			.ref(refs.em_presence_col(event_id))
      .once('value')
		return unpack_rtdb_col<Schema.I_RT_Event_Member_Presence>(query)
  }

  async get_em_private_test_time(
    event_id: string,
    user_id: string,
  ) {
    let query
    try {
      query = await this.db
      .ref(refs.em_private_test_time(event_id, user_id))
      .once('value')
    } catch (error) {
      console.error(error)
      return undefined
    }
		return (query.exists() && query.val()) || 0
  }

	/**
	 * This collection is just keys (e.g. <key>:true), so it can easily
	 * be digested by the caller with javascript Object methods. In
	 * other words, no need to unpack the data as other methods in this
	 * service do.
	 */
  async get_em_private_chat_col(
    event_id: string,
    user_id: string
  ) {
    const query = await this.db
			.ref(refs.em_private_chat_col(event_id, user_id))
      .once('value')
		return unpack_rtdb_prim_col<boolean>(query)
	}

	/**
	 * This collection is just keys (e.g. <key>:true), so it can easily
	 * be digested by the caller with javascript Object methods. In
	 * other words, no need to unpack the data as other methods in this
	 * service do.
	 */
  async get_em_private_room_col(
    event_id: string,
    user_id: string,
  ) {
    const query = await this.db
			.ref(refs.em_private_room_col(event_id, user_id))
      .once('value')
		return unpack_rtdb_prim_col<boolean>(query)
  }

  async get_em_public_col(event_id: string) {
    const query = await this.db
			.ref(refs.em_public_col(event_id))
      .once('value')
		return unpack_rtdb_col<Schema.Event_Member_Public>(query)
	}

  async get_event_orgs(event_id: string) {
    const query = await this.db.ref(refs.event_orgs_col(event_id)).once('value')
		return unpack_rtdb_col<Schema.Event_Organization>(query)
  }

  async get_event_org(event_id: string, org_id: string) {
    const query = await this.db.ref(refs.event_org(event_id, org_id)).once('value')
		return unpack_rtdb_obj<Schema.Event_Organization>(query)
  }

  async get_event_settings(event_id: string) {
    const query = await this.db.ref(refs.rt_event_settings(event_id)).once('value')
    return unpack_rtdb_obj<Schema.I_RT_Event_Settings>(query, false)
  }

  async get_room_updates(event_id: string, room_id: string) {
    const query = await this.db.ref(refs.rt_room(event_id, room_id)).once('value')
		return unpack_rtdb_obj<Schema.I_RT_IE_Room>(query)
  }

  async get_rt_event_member(eid: string, uid: string) {
    const query = await this.db.ref(refs.rt_event_member(eid, uid)).once('value')
		return unpack_rtdb_obj<Schema.I_RT_Event_Member>(query)
  }

  async get_posting(event_id: string, posting_id: string) {
    const query = await this.db.ref(refs.posting(event_id, posting_id)).once('value')
		return unpack_rtdb_obj<Schema.I_Posting>(query)
  }

  async get_categories(event_id: string, posting_id: string) {
    const query = await this.db.ref(refs.posting_category_col({event_id, posting_id})).once('value')
		return unpack_rtdb_col<Schema.I_Posting_Category>(query)
  }

  async get_category(
    event_id: string,
    posting_id: string,
    category_id: string
  ) {
    const query = await this.db.ref(refs.posting_category({
        event_id,
        posting_id,
        category_id,
    })).once('value')
    return unpack_rtdb_obj<Schema.I_Posting_Category>(query)
  }

  get_ref(ref: string) {
    return this.db.ref(ref)
  }

  async get_room<T>(event_id: string, room_id: string) {
    const query = await this.db.ref(refs.room(event_id, room_id)).once('value')
    return unpack_rtdb_obj<T & GUID_Type>(query)
  }

  async get_posting_rooms(eid: string, pid: string) {
    const query = await this.db.ref(refs.room_col(eid))
        .orderByChild('posting_id')
        .equalTo(pid)
        .once('value')
    return unpack_rtdb_col<Schema.I_Posting_Room>(query)
  }

  async get_posting_category_rooms(eid: string, cid: string) {
    const query = await this.db.ref(refs.room_col(eid))
        .orderByChild('category_id')
        .equalTo(cid)
        .once('value')
    return unpack_rtdb_col<Schema.I_Posting_Room>(query)
  }

	// Just a keys collection
  async get_public_chat_col(event_id: string) {
    const query = await this.db
        .ref(refs.event_public_chat_col(event_id))
        .once('value')
		return unpack_rtdb_prim_col<boolean>(query)
  }

	// Just a keys collection
  async get_public_room_col(event_id: string) {
    const query = await this.db
        .ref(refs.event_public_room_col(event_id))
        .once('value')
		return unpack_rtdb_prim_col<boolean>(query)
  }

  async get_posting_room_member_col(p: {
    event_id: string,
    posting_id: string
    category_id: string,
    room_id: string,
  }) {
    const query = await this.db
        .ref(refs.posting_room_member_col(p))
        .once('value')
		return unpack_rtdb_col<Schema.I_Posting_Room_Member>(query)
  }

  async get_room_acl_col(event_id: string, room_id: string) {
    const query = await this.db
			.ref(refs.room_acl_col(event_id, room_id))
      .once('value')
		return unpack_rtdb_col<Schema.Room_ACL_Member>(query)
	}

	remove_event_admin(event_id: string, user_id: string) {
		return this.db.ref(refs.event_admin(event_id, user_id)).remove()
  }

  async event_member_status_update_rsvp(event_id: string, user_id: string) {
    try {
      await this.db
        .ref(refs.em_public_rsvp(event_id, user_id))
        .set(true)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_chat(
    chat: Chat,
    event_id: string,
    members?: Chat_Members,
  ) {
    try {
      // Get a chat id key.
      const chat_ref = refs.chat_col(event_id)
      const chat_id = Rtdb_Service.create_key(chat_ref)

      const updates: any = { [refs.chat(event_id, chat_id)]: chat }

      if (!chat.private) {
        updates[refs.event_public_chat(event_id, chat_id)] = true
      } else {
        updates[refs.chat_member_col(event_id, chat_id)] = members || undefined
      }

      try {
        await this.run_updates(updates)
        return chat_id
      } catch (e) {
        console.error(e)
        return null
      }
    } catch (e) {
      console.error(e)
    }
  }

  async set_chat_message(
    event_id: string,
    chat_id: string,
    message: Chat_Message,
  ) {
    let res: firebase.database.Reference
    try {
      res = await this.db
        .ref(refs.chat_message_col(event_id, chat_id))
        .push(message)
    } catch (e) {
      console.error(e)
      return undefined
    }
    return res
  }

  async set_chat_members(
    event_id: string,
    chat_id: string,
    members: {member: Chat_Member, id: string}[]
  ) {
    const updates: any = {}
    members.forEach((m) => {
      updates[refs.chat_member(event_id, chat_id, m.id)] = m.member
      updates[refs.em_private_chat(event_id, m.id, chat_id)] = true
    })
    return this.run_updates(updates)
  }

  async set_draw_room_times(p: {
    event_id: string,
    category_id: string,
    times: Schema.I_Draw_Room_Category_Times,
  }) {
    return this.db.ref(refs.draw_room_times(p)).set(p.times)
  }

  async set_draw_room_question(p: {
    event_id: string,
    category_id: string,
    index: string,
    question: string,
  }) {
    return this.db.ref(refs.draw_room_question(p)).set(p.question)
  }

  delete_draw_room_question(p: {
    event_id: string,
    category_id: string,
    index: string,
  }) {
    return this.db.ref(refs.draw_room_question(p)).remove()
  }

  delete_draw_room_category(p: {
    event_id: string,
    category_id: string,
  }) {
    return Promise.all([
      this.db.ref(refs.draw_room_categories_col_cat(p)).remove(),
      this.db.ref(refs.draw_room_category(p)).remove(),
    ])
  }

	set_event_admins(
    event_id: string,
    user_ids: string[],
  ) {
		const updates: any = {}
		user_ids.forEach((id) => {
			updates[refs.event_admin(event_id, id)] = true
		})
		return this.db.ref().update(updates)
  }

  set_posting_banner(
    event_id: string,
    posting_id: string,
    data: I_Posting_Banner,
  ) {
		return this.db.ref(refs.event_data_posting_banner(event_id, posting_id)).set(data)
  }

  set_event_data_pairings(event_id: string, data: string) {
		return this.db.ref(refs.event_data_pairings(event_id)).set(data)
  }

	set_event_data_schedule(event_id: string, data: string) {
		return this.db.ref(refs.event_data_schedule(event_id)).set(data)
  }

  set_event_data_speakers(event_id: string, data: string) {
		return this.db.ref(refs.event_data_speakers(event_id)).set(data)
  }

  set_event_data_topics(event_id: string, data: string) {
		return this.db.ref(refs.event_data_topics(event_id)).set(data)
  }

  set_event_presence(
    event_id: string,
    user_id: string,
    data: I_RT_Event_Member_Presence,
  ) {
    // Transaction b/c CF also modifies this data.
    return this.run_transaction(
      (p: I_RT_Event_Member_Presence) => data,
      refs.em_presence(event_id, user_id),
    )
  }

  set_anon_event_member_public(
    event_id: string,
    user_id: string,
    data: Event_Member_Public
  ) {
    return this.db
      .ref(refs.event_member_public(event_id, user_id))
      .set(data)
  }

  set_event_member_public(
    event_id: string,
    user_id: string,
    data: Event_Member_Public,
  ) {
    return this.db
      .ref(refs.event_member_public(event_id, user_id))
      .set(data)
  }

  set_event_member_system(
    event_id: string,
    user_id: string,
    data: Event_Member_Public_System,
  ) {
    return this.db
      .ref(refs.em_public_system(event_id, user_id))
      .set(data)
  }

  async set_rt_room_duo(eid: string, rid: string, new_duo: boolean) {
    await this.db.ref(refs.rt_room_duo(eid, rid)).set(new_duo)
  }

  async set_rt_room_use_lobby(eid: string, rid: string, use: boolean) {
    await this.db.ref(refs.rt_room_use_lobby(eid, rid)).set(use)
  }

  async set_em_private_test_time(
    event_id: string,
    user_id: string,
    time: number,
  ) {
    let query
    try {
      query = await this.db
        .ref(refs.em_private_test_time(event_id, user_id))
        .set(time)
      return 0
    } catch (error) {
      console.error(error)
      return 1
    }
  }

  async set_event_settings_event_is_public(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.event_is_public(event_id)).set(data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_ao_social_rooms(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_ao_social_rooms(event_id)).set(data)
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_auditorium_enabled(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_enable_auditorium(event_id)).set(data)
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_use_draw_room(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_use_draw_room(event_id)).set(data)
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_auditorium_link(event_id: string, data: string) {
    try {
      await this.db.ref(refs.rtes_auditorium_url(event_id)).set(data)
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_hide_event_hq(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_hide_event_hq(event_id)).set(data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_hide_postings(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_hide_postings(event_id)).set(data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_settings_hide_social_hall(event_id: string, data: boolean) {
    try {
      await this.db.ref(refs.rtes_hide_social_hall(event_id)).set(data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

	// set_event_tag_member(event_id: string, tag: string) {
  //   // Since tags are the the keys themselves, get an encoded version of the tag as the key.
  //   const key = Misc_Utils.rtdb_key_encode(tag)
	// 	return this.db.ref(refs.event_tag_member(event_id, key)).set(tag)
  // }

  async set_event_user_global_chat_sound(p: {
    event_id: string,
    user_id: string,
    data: boolean,
  }) {
    try {
      await this.db.ref(refs.event_rt_user_chat_settings_sound_global(p)).set(p.data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_user_global_chat_alert(p: {
    event_id: string,
    user_id: string,
    data: boolean,
  }) {
    try {
      await this.db.ref(refs.event_rt_user_chat_settings_alert_global(p)).set(p.data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_user_chat_sound(p: {
    event_id: string,
    user_id: string,
    chat_id: string,
    data: boolean,
  }) {
    try {
      await this.db.ref(refs.event_rt_user_chat_settings_sound(p)).set(p.data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_event_user_chat_alert(p: {
    event_id: string,
    user_id: string,
    chat_id: string,
    data: boolean,
  }) {
    try {
      await this.db.ref(refs.event_rt_user_chat_settings_alert(p)).set(p.data)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_room_readiness(
    event_id: string,
    room_id: string,
    status: ROOM_READINESS,
  ) {
    try {
      await this.db.ref(refs.rt_readiness(event_id, room_id)).set(status)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  async set_room_timer(
    event_id: string,
    room_id: string,
    timer: I_Room_Timer,
  ) {
    try {
      await this.db.ref(refs.room_timer(event_id, room_id)).set(timer)
      return 0
    } catch (e) {
      console.error(e)
      return 1
    }
  }

  set_my_poi(p: {
    eid: string,
    mid: string,
    uid: string,
    show_poi: boolean,
  }) {
    return this.db.ref(refs.rt_room_member_poi(p)).set(p.show_poi)
  }

  set_posting_room_member_status(ids: {
    eid: string,
    mid: string,
    uid: string,
  }, status: E_IE_ROOM_MEMBER_STATUS,) {
    return this.db.ref(refs.rt_room_member_status(ids)).set(status)
  }

  update_event_member_name(
    name: string,
    event_id: string,
    member_id: string,
  ) {
    return this.db
      .ref(refs.event_member_name(event_id, member_id))
      .set(name)
  }

	run_updates(updates: any) {
		return this.db.ref().update(updates)
  }

  async run_transaction(
    transaction: (data: any) => any,
    ref: string,
    on_complete?: any
  ) {
    return await this.db.ref(ref).transaction(transaction, on_complete)
  }
}


function sanitize_id(data: any) {
  const d = clonedeep(data)
  delete d.id
  return d
}
