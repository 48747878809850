import { AbstractControl, ValidatorFn } from '@angular/forms';
import { map, take, debounceTime } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

// https://angularfirebase.com/lessons/async-form-validation-in-firebase-enforce-uniqueness/
// https://github.com/AngularFirebase/87-reactive-forms-async-validation-firestore/blob/master/src/app/login-form/login-form.component.ts
export class Custom_Validator {
  static is_name_available(afs: AngularFirestore): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const org_name = control.value.toLowerCase();
      return afs
        .collection('organization', (ref) => ref.where('lowercased_name', '==', org_name).limit(1))
        .valueChanges({idField: 'id'}).pipe(
          debounceTime(750),
          take(1),
          map((arr) => {
            return arr.length ? { unavailable: true } : null
          }),
        )
    }
  }
}
