import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import {
  CanLoad,
  Route,
  Router,
  UrlSegment,
} from '@angular/router'
import { Auth_Service } from "@app/services/auth.service"
import { Routes } from "@ws/constants"

@Injectable({ providedIn: 'root' })
export class Is_Auth_Can_Load implements CanLoad {
  constructor(
    private readonly _auths: Auth_Service,
    private readonly _loc: Location,
    private readonly _router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ) {
    if (!this._auths.user) {
      this._auths.set_on_auth_redirect(this._loc.path())
      return this._router.parseUrl(Routes.str.login())
    }

    return true
  }
}
