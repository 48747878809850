import { Injectable, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { FB_Refs_Rtdb as refs } from '@ws/constants'
import { Rtdb_Service } from './rtdb.service'

@Injectable({ providedIn: 'root' })
export class App_User_Connection_Service implements OnDestroy {
  private readonly _destroy$: Subject<boolean> = new Subject<boolean>()
  private readonly _connected_ref = this._rtdbs.db.ref(refs.user_db_connection())

  constructor(
    private readonly _rtdbs: Rtdb_Service,
  ) {
    this._listen_to_connection()
  }


  ngOnDestroy() {
    this._destroy$.next(true)
  }

  private _listen_to_connection() {
    const self = this

    this._connected_ref.on('value', function(snap) {
      // console.log('connected_ref val: ', snap)
      if (snap.val() === true) {
        // console.log('connected!')
      } else {
        // console.log('not connected!')
      }
    })
  }
}
