import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core'
import { COLOR_PALETTE } from "@ws/constants"

@Component({
  selector: 'danger-panel',
  templateUrl: './danger-panel.component.html',
  styleUrls: ['./danger-panel.component.scss']
})
export class Danger_Panel_Component implements OnInit {
  color_danger = COLOR_PALETTE.DANGER
  @Input() button_text?: string
  @Input() confirm_action: any
  @Input() confirm_value!: string
  @Input() header!: string
  @Input() instruction!: string
  @Output() done = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {}

  handle_done(val: any) {
    this.done.next(val)
  }

}
