import Stripe from 'stripe'

export interface Firebase_Config {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  measurementId?: string
  appId: string
}

export interface Env_Urls {
  yaatly: {
    app: string
    info: string
    pp: string
    pricing: string
    public: string
    tos: string
  }
  moot: {
    app: string
    info: string
    pp: string
    pricing: string
    public: string
    tos: string
  }
  jessup: {
    app: string
    info: string
    pp: string
    pricing: string
    public: string
    tos: string
  }
}

export interface Buckets {
  default: string
}

export interface Http_Callable_Data {
  data: string | ArrayBuffer | null,
  extension: string,
  filename: string,
  public?: boolean,
}

export interface Http_Callable_Put_Org_Logo_Params {
  organization_id: string
}

export interface Stripe_Customer_Metadata extends Stripe.Metadata {
  uid: string
}

// export type Stripe_Invoice_Metadata = Stripe.Metadata & {
//   env_name: App_Config.ENV_NAMES
//   event_id: string
//   user_id: string
// }

export interface Stripe_Customer extends Stripe.Customer {
  metadata: Stripe_Customer_Metadata
}

// export interface Stripe_Invoice extends Stripe.Invoice {
//   metadata: Stripe_Invoice_Metadata
// }

export interface I_Stripe_Payment_Intent_Meta extends Stripe.Metadata {
  credits: string
  credit_id: string
  user_id: string
}

export enum ROOM_ROLE {
  OBSERVER = 'observer'
}

export enum AFDB_ACTION_TYPES {
  CHILD_ADDED = 'child_added',
  CHILD_CHANGED = 'child_changed',
  CHILD_REMOVED = 'child_removed',
  VALUE = 'value',
}
