import {
  I_Event_Capacity,
  I_Event_Window,
  T_Event_Capacity_Item,
  Event,
} from '@ws/schema-fs'
import {
  GUID_Type, Event as RT_Event
} from '@ws/schema-rtdb'
import clonedeep from 'lodash.clonedeep'
import * as PRICING from '../lib/pricing'

export interface I_Event_Start_End_Dates {
  start: number
  end: number
}

export const is_in_event_date_window = (dates: I_Event_Capacity) => {
  const now = Date.now()

  const found = Object.keys(clonedeep(dates)).some((date_key) => {
    const [start, end] = get_event_date_item_start_end(date_key)
    return now >= start && now <= end
  })

  return found
}

export const is_today_same_as_date = (date: number) => {
  return zero_out_time(Date.now()) === zero_out_time(date)
}

export const get_event_date_item_start = (date_key: string) => {
  return parseInt(date_key.split('-')[0], 10)
}

export const get_event_date_item_end = (date_key: string) => {
  return parseInt(date_key.split('-')[1], 10)
}

export const get_event_date_item_start_end = (date_key: string): number[] => {
  return date_key.split('-').map((d) => parseInt(d, 10))
}

export const get_current_date_window = (dates: I_Event_Capacity) => {
  const now = Date.now()
  const found = Object.keys(clonedeep(dates)).find((date_key) => {
    const [start, end] = get_event_date_item_start_end(date_key)
    return now >= start && now <= end
  })

  return found
    ? get_event_date_item_start_end(found)
    : [0,0]
}

export const get_current_date_start = (dates: I_Event_Capacity) => {
  return get_current_date_window(clonedeep(dates))[0]
}

export const get_current_date_end = (dates: I_Event_Capacity) => {
  return get_current_date_window(clonedeep(dates))[1]
}

// Gets the start date of the first day of the event.
export const get_event_start_date = (dates: I_Event_Capacity) => {
  const c_dates = clonedeep(dates)
  const date = Object.keys(c_dates).reduce((acc, curr) => {
    const [start, end] = get_event_date_item_start_end(curr)
    return start < acc ? start : acc
  }, Infinity)

  // console.log('start date: ', new Date(date))
  return date
}

// Gets the end date/time of the last day of the event.
export const get_event_end_date = (dates: I_Event_Capacity) => {
  const date = Object.keys(clonedeep(dates)).reduce((acc, curr) => {
    const [start, end] = get_event_date_item_start_end(curr)
    return end > acc ? end : acc
  }, 0)

  // console.log('end date: ', new Date(date))
  return date
}

export const is_event_ended = (dates: I_Event_Capacity) => {
  const is_ended = get_event_end_date(clonedeep(dates)) <= Date.now()
  // console.log('is ended: ', is_ended)
  return is_ended
}

export const is_event_started = (dates: I_Event_Capacity) => {
  const is_started = get_event_start_date(clonedeep(dates)) <= Date.now()
  // console.log('is started: ', is_started)
  return is_started
}

export const get_event_start_and_end_dates = (
  dates: I_Event_Capacity,
): I_Event_Start_End_Dates => {
  return {
    start: get_event_start_date(dates),
    end: get_event_end_date(dates),
  }
}

export const is_in_test_mode = (event: Event & GUID_Type) => {
  return event.is_test || !is_in_event_date_window(event.capacity)
}

export const event_date_capacity = (event: Event & GUID_Type) => {
  // If the event is not live (i.e. in "test mode"), we dont' care about capacity.
  if (!is_in_event_date_window(event.capacity)) {
    return Infinity
  }

  const item = Object.entries(event.capacity).find((entry) => {
    const date_key = entry[0]
    const cap = entry[1]
    return is_in_event_date_window({[date_key]: cap})
  })

  return (item && item[1]) ?? 0
}

export const get_user_timezone_abbreviation = () => {
  let result: string | undefined
  try {
    // Chrome, Firefox
    result = /.*\s(.+)/
      .exec((new Date())
      .toLocaleDateString(navigator.language, { timeZoneName: 'short' }))![1]
  } catch (e) {
    console.log('Timezone error. Trying backup.')
    // IE, some loss in accuracy due to guessing at the abbreviation Note: This regex adds a grouping around the open paren as a workaround for an IE regex parser bug
    result = (new Date())
      .toTimeString()
      .match(new RegExp('[A-Z](?!.*[(])', 'g'))!
      .join('')
  }
  return result
}

export const get_event_window = (c: I_Event_Capacity): I_Event_Window => {
  return {
    start: get_event_start_date(c),
    end: get_event_end_date(c),
    offset: new Date().getTimezoneOffset(),
    timezone: get_user_timezone_abbreviation(),
  }
}

// * This function does not provide any information on failures. This is b/c this should be run one the client before hitting the server, and the server should run this as well. The client should execute the same logic found here as validation before running the date key through this check.
export const can_change_date_key = (p: {
  old_key: string,
  new_key: string,
  event: Event | RT_Event,
  now: number,
  utc_offset: number
}) => {
  const [old_start, old_end] = get_event_date_item_start_end(p.old_key)
  const [new_start, new_end] = get_event_date_item_start_end(p.new_key)
  console.log('new start: ', new_start)
  console.log('new end: ', new_end)

  // The date must be the same.
  if (!is_same_start_and_end_date(new_start, new_end, p.utc_offset)) {
    console.log('start and end date not the same')
    return false
  }

  // The start date/time is being changed.
  if (is_different(old_start, new_start)) {
    if (!can_change_date_key_start({
      old_start,
      new_start,
      event: p.event,
      now: p.now,
    })) {
      console.log('cannot change date key start')
      return false
    }
  }

  // The end date/time is being changed.
  if (is_different(old_end, new_end)) {
    if (!can_change_date_key_end({
      old_end,
      new_end,
      event: p.event,
      now: p.now,
    })) {
      console.log('cannot change date key end')
      return false
    }
  }

  return true
}

export const can_adjust_date_capacity_down = (date_key: string) => {
  return get_event_date_item_start(date_key) > Date.now()
}

export const set_date_on_the_hour = (date: Date) => {
  const d = new Date(date)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

export const fold_time_into_date = (date: number, time: number) => {
  const d = new Date(date)
  const t = new Date(time)
  d.setHours(t.getHours())
  d.setMinutes(t.getMinutes())
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

function is_same_start_and_end_date(
  start: number,
  end: number,
  offset: number
) {
  const s = get_date_ms_converted_by_offset(start, offset)
  const e = get_date_ms_converted_by_offset(end, offset)
  return get_date_key_date(zero_out_time(s)) === get_date_key_date(zero_out_time(e))
}

export function get_date_ms_converted_by_offset(
  ms: number,
  offset_min: number,
) {
  return ms - (offset_min * 60 * 1000)
}

function can_change_date_key_end(p: {
  old_end: number,
  new_end: number,
  event: Event | RT_Event,
  now: number,
}) {
  return true
    // The old end should not have expired yet.
    && p.old_end > p.now
    // The new end should be greater than now.
    && p.new_end > p.now
}

function can_change_date_key_start(p: {
  old_start: number,
  new_start: number,
  event: Event | RT_Event,
  now: number,
}) {
  return true
    // The old start should not have expired yet.
    && p.old_start > p.now
    // It should be greater than now.
    && p.new_start > p.now
    // and should not conflict with another date in a date_key.
    && !is_new_date_conflict_with_another_capacity_date(
      p.old_start,
      p.new_start,
      p.event.capacity,
    )
}

export function is_new_date_conflict_with_another_capacity_date(
  old_start: number,
  new_start: number,
  event_capacity: I_Event_Capacity
) {
  return Object.keys(event_capacity).some((e_date_key) => {
    const old_sd = get_date_key_date(old_start)
    const new_sd = get_date_key_date(new_start)
    const other_sd = get_date_key_date(
      get_event_date_item_start(e_date_key)
    )
    return is_date_key_date_same(old_sd, new_sd, other_sd)
  })
}

export function get_conflict_date(
  old_start: number,
  new_start: number,
  event_capacity: I_Event_Capacity
) {
  const found = Object.keys(event_capacity).find((e_date_key) => {
    const old_sd = get_date_key_date(old_start)
    const new_sd = get_date_key_date(new_start)
    const other_sd = get_date_key_date(
      get_event_date_item_start(e_date_key)
    )
    return is_date_key_date_same(old_sd, new_sd, other_sd)
  })

  if (found) {
    return get_event_date_item_start(found)
  }

  return 0
}

function is_date_key_date_same(
  old_start_date: string,
  new_start_date: string,
  other_start_date: string,
) {
  return true
    // Skip the old start that is actually being replaced
    && old_start_date !== other_start_date
    // Check if the new start date conflicts with any other start date.
    && new_start_date === other_start_date
}

export function get_date_key_date(d: number) {
  // We are checking against YYYY-MM-DD to ensure not two keys are on the same day.
  return new Date(d).toISOString().slice(0, 10)
}

export function date_key_date_conflicts(
  old_date: number,
  new_date: number,
) {
  const o = new Date(old_date).toISOString().slice(0, 10)
  const n = new Date(new_date).toISOString().slice(0, 10)
  return o === n
}

function is_different(old: number, nnew: number) {
  return old !== nnew
}

export function zero_out_time(date: number) {
  const d = new Date(date)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d.valueOf()
}

// Postive return is a charge. Negative is a refund.
export function get_credit_diff(
  old_cap: T_Event_Capacity_Item,
  new_cap: T_Event_Capacity_Item,
) {
  let old_pricing_calc: number
  let new_pricing_calc: number

  // If the start time has already been passed, then calc credits based on current time going forward.
  if (get_event_date_item_start(old_cap.key) < Date.now()) {
    const key = `${Date.now()}-${get_event_date_item_end(old_cap.key)}`
    old_pricing_calc = PRICING.calc_event_credit_cost({
      [`${Date.now()}-${get_event_date_item_end(old_cap.key)}`]: old_cap.cap
    })
    new_pricing_calc = PRICING.calc_event_credit_cost({
      [`${Date.now()}-${get_event_date_item_end(new_cap.key)}`]: new_cap.cap
    })
  } else {
    old_pricing_calc = PRICING.calc_event_credit_cost({[old_cap.key]: old_cap.cap})
    new_pricing_calc = PRICING.calc_event_credit_cost({[new_cap.key]: new_cap.cap})
  }

  return  new_pricing_calc - old_pricing_calc
}
