import { Injectable } from '@angular/core'
import { State } from '@ngxs/store'

export class Navigate {
  static readonly type = '[Router] Navigate'
  constructor(public payload: string) {}
}

@State<string>({
  name: 'router',
})

@Injectable()
export class Router_State {
  // constructor(private router: Router) {}

  // @Action(Navigate)
  // async change_route(context: StateContext<string>, action: Navigate) {
  //   const path = action.payload
  //   await this.router.navigate([path])
  //   context.setState(path)
  // }
}
