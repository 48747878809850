import { Injectable } from '@angular/core'
import firebase from 'firebase/app'
import 'firebase/functions'
import {
  FB_Refs_Http,
  Interfaces,
} from "@ws/constants"
import { HttpsCallableResult } from "@firebase/functions-types"
import { AngularFireFunctions } from '@angular/fire/functions'
import { environment as env } from '@app/env'


// This is a generic FB class for any misc FB needs.
@Injectable({ providedIn: 'root' })
export class Firebase_Service {
  private _did_init = false

  constructor(private readonly _fns: AngularFireFunctions) {}

  private async _init() {
    await this._fns.useFunctionsEmulator('http://localhost:5001')
    this._did_init = true
  }

  private _should_init_local() {
    return env.use_local && !this._did_init
  }

  async callable_req<T>(ref: string, data: T) {
    if (this._should_init_local()) await this._init()
    return this._fns.httpsCallable(ref)(data).toPromise()
  }

  async callable_put_org_logo(
    file: File,
    org_id: string,
  ): Promise<HttpsCallableResult> {
    if (this._should_init_local()) await this._init()

    const data = await get_data_obj_from_file<Interfaces.Http_Callable_Put_Org_Logo_Params>(
      file,
      {organization_id: org_id},
    )
    return firebase.functions()
      .httpsCallable(FB_Refs_Http.callable_put_org_logo())(data)
  }
}

function get_data_obj_from_file<T>(
  file: File,
  params: T,
): Promise<Interfaces.Http_Callable_Data & T> {
  return new Promise((resolve, reject) => {
    const extension = file.name.split('.').pop()!
    const filename = file.name.substring(0, file.name.lastIndexOf('.'))
    const reader = new FileReader()
    reader.onloadend = () => {
      const data = Object.assign(
        {},
        {
          data: reader.result,
          extension,
          filename,
        },
        params,
      )

      resolve(data as any)
    }

    reader.readAsBinaryString(file)
  })
}
