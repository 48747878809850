export const CONN_ISSUES = 'https://info.yaatly.com/user-guides/technical-guide#connectivity-issues'
export const FAQ = 'https://info.yaatly.com/faq'
export const FAQ_TEST_MODE = 'https://info.yaatly.com/faq#what-is-the-event-test-mode'
export const FAQ_DEMO_EVENT = 'https://info.yaatly.com/faq#what-is-a-demo-event'
export const FAQ_LIVE_EVENT = 'https://info.yaatly.com/faq#what-is-a-live-event'
export const INFO = 'https://info.yaatly.com'
export const PRICING = 'https://info.yaatly.com/pricing'
export const PRICING_TIERS = 'https://info.yaatly.com/pricing#volume-discounts'
export const CREATE_POSTINGS = 'https://info.yaatly.com/user-guides/guide-for-tournament-directors#how-do-i-create-competition-rooms'

