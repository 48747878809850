import { State, Selector } from '@ngxs/store'
import { STORE_ENTITIES } from '@app/constants'
import { Injectable } from '@angular/core'


export interface App_State_Model {
  loading: boolean,
}
@State<App_State_Model>({
  name: STORE_ENTITIES.APP,
  defaults: {
    loading: false,
  }
})

@Injectable()
export class App_State {
  @Selector()
  static get_loading(state: App_State_Model) {
    return state.loading
  }

  @Selector()
  public static getState(state: App_State_Model) {
      return state
  }
}
