import { Injectable } from '@angular/core'
import { Observable, of, Subscription } from 'rxjs'
import { AngularFireAuth } from '@angular/fire/auth'
import firebase from 'firebase/app'
import { App_Store_Service } from './store.service'
import { User_Private, User_Public } from '@ws/schema-fs'
import clonedeep from 'lodash.clonedeep'
import { Firestore_Db_Service } from './firestore.service'

type T_User_Public_Name = Pick<User_Public, 'name'>

@Injectable({
  providedIn: 'root'
})
export class User_Service {
  user$: Observable<firebase.User | null>
  user_sub: Subscription
  user: firebase.User | null = null

  constructor(
    private readonly _af_auth: AngularFireAuth,
    private readonly _fss: Firestore_Db_Service,
    private readonly _ss: App_Store_Service,

  ) {
     // Keep track of the user. Technically this is available via firebase.auth().currentUser. But, we're using a service layer, so here you go.
    this.user$ = this._af_auth.authState
    this.user_sub = this.user$.subscribe((user) => {
      this.user = user
    })
  }

  get_user() {
    return this.user
  }

  async get_user_async() {
    const user = await this._af_auth.currentUser
    if (!this.user) this.user = user
    return user
  }

  get_user_id(): string {
    // Returning 'as string' because uid will exist if user does (Firebase User type will always have uid).
    return (this.user && this.user.uid) as string
  }

  get user_id() {
    return (this.user && this.user.uid) as string
  }

  async maybe_set_user() {
    const user = await this._af_auth.currentUser
    if (user) {
      this.user = user
    }
  }

  get_user_public() {
    if (this.user?.isAnonymous) return
    return this._ss.get_user_public() ?? undefined
  }

  get_user_private() {
    if (this.user?.isAnonymous) return
    return this._ss.get_user_private() ?? undefined
  }

  async update_user_public_name(uid: string, data: T_User_Public_Name) {
    const up = this.get_user_public()

    if (!up) {
      throw new Error('no user public to update name')
    }

    const nup: User_Public = {
      ...clonedeep(up),
      name: data.name,
    }

    await this._fss.set_user_public(nup, uid)
    this._set_user_public_store(nup)
  }

  private _set_user_public_store(data: Partial<User_Public>) {
    const up = {...this.get_user_public ?? {}, ...data}
    this._ss.set_user_public(up)
  }

  set_user_private_store(data: Partial<User_Private>) {
    const up = {...this.get_user_private ?? {}, ...data}
    this._ss.set_user_private(up)
  }
}
