import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
// https://github.com/angular/flex-layout
import { FlexLayoutModule } from '@angular/flex-layout'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { Named_Outlet_Directive } from '../../directives/named-outlet.directive'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { Zorro_UI_Module } from '../misc/zorro.module'
import { Object_Entries_Pipe } from '@app/utils'

// Components
import { Confirm_Text_Component } from '@client/components/confirm-text'
import { Danger_Panel_Component } from "@client/components/danger-panel"
import { Org_Form_Component } from './org-form/org-form.component'
import { Doge_Component } from "@client/components/doge"
import { Icon_Fail_Component } from "@client/components/icon-fail"
import { Icon_Success_Component } from "@client/components/icon-success"
import { Loader_Component } from '@client/components/loader'
import { Image_Loader_Component } from '@client/components/image-loader'
import { Logo_Letter_Component } from '@client/components/logo-letter'
import { Logo_Full_Component } from '@client/components/logo-full'

import { DatePipe } from '@angular/common'
import { Experimental_Tag_Component } from './experimental/exp.component'
import { Shared_Check_Mark_Component } from './check-mark/check.component'
import { Shared_Textarea_Input_Component } from './textarea-input/input.component'
import { Shared_Text_Input_Component } from './text-input/input'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [
    Confirm_Text_Component,
    Danger_Panel_Component,
    Doge_Component,
    Experimental_Tag_Component,
    Icon_Fail_Component,
    Icon_Success_Component,
    Image_Loader_Component,
    Loader_Component,
    Logo_Full_Component,
    Logo_Letter_Component,
    Named_Outlet_Directive,
    Object_Entries_Pipe,
    Org_Form_Component,
    Shared_Check_Mark_Component,
    Shared_Textarea_Input_Component,
    Shared_Text_Input_Component,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Zorro_UI_Module,
  ],
  entryComponents: [
    Org_Form_Component,
  ],
  exports: [
    AngularSvgIconModule,
    CommonModule,
    Confirm_Text_Component,
    Danger_Panel_Component,
    Doge_Component,
    Experimental_Tag_Component,
    FlexLayoutModule,
    FormsModule,
    Icon_Fail_Component,
    Icon_Success_Component,
    Image_Loader_Component,
    Loader_Component,
    Logo_Full_Component,
    Logo_Letter_Component,
    Named_Outlet_Directive,
    Object_Entries_Pipe,
    Org_Form_Component,
    ReactiveFormsModule,
    RouterModule,
    Shared_Check_Mark_Component,
    Shared_Textarea_Input_Component,
    Shared_Text_Input_Component,
    Zorro_UI_Module,
  ],
  providers: [
    DatePipe
  ]
})
export class Shared_Module { }
