<div style='margin-bottom: .5rem;'>
  <label class='hidden' [for]=input_id></label>
  <nz-form-control>
    <textarea
      #textarea
      [id]=input_id
      [value]=input_value
      nz-input
      [rows]=input_rows
      [placeholder]=input_placeholder></textarea>
  </nz-form-control>
</div>
<div class='ta-right'>
  <button
  nz-button
    [nzType]=input_button_type
    [nzSize]=input_button_size
    [nzLoading]='saving'
    [disabled]='saving'
    (click)=save()>Save</button>
</div>
