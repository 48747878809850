import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
// import { NOTIFICATION_TEMPLATES } from '@app/constants'
import { Templates_Service } from '@app/services/templates.service'
import { Routes } from "@ws/constants"

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class App_Templates_Component implements OnInit {
  // @ViewChild('has_invoices_notification_tpl', { static: true })
  // has_invoices_notification_tpl!: ElementRef
  url_account = Routes.arr.home_user_account()

  constructor(
    // private readonly _tpl_s: Templates_Service
  ) { }

  ngOnInit() {
    // this._tpl_s.add(NOTIFICATION_TEMPLATES.APP_USER_HAS_INVOICES, this.has_invoices_notification_tpl)
  }

}
