/**
 * This static class defines all RTDB nodes that are accessed.
 * This can be client side, service side, etc. The routes should
 * only change when schema changes.
 */
export class FB_Refs_Rtdb {
  static _root(event_id: string) {
    return `event/${event_id}`
  }

  static chat_root(event_id: string) {
    return `${this._root(event_id)}/chat`
  }

  static chat_col(event_id: string) {
    return `${this.chat_root(event_id)}`
  }

  static chat(event_id: string, chat_id: string) {
    return `${this.chat_root(event_id)}/${chat_id}`
  }

  static chat_member_root(event_id: string) {
    return `${this._root(event_id)}/chat_member`
  }

  static chat_member(event_id: string, chat_id: string, user_id: string) {
    return `${this.chat_member_root(event_id)}/${chat_id}/${user_id}`
  }

  static chat_member_col(event_id: string, chat_id: string) {
    return `${this.chat_member_root(event_id)}/${chat_id}`
  }

  static chat_messsage_root(event_id: string) {
    return `${this._root(event_id)}/chat_message`
  }

  static chat_message_col(event_id: string, chat_id: string){
		return `${this.chat_messsage_root(event_id)}/${chat_id}`
	}

  static chime_tags_root(event_id: string) {
    return `${this._root(event_id)}/chime_tags`
  }

  static chime_tags(p: {
    event_id: string,
    meeting_id: string,
    user_id: string,
  }) {
    return `${this.chime_tags_root(p.event_id)}/${p.meeting_id}/${p.user_id}`
  }

  static event_root(event_id: string) {
    return `${this._root(event_id)}/event/${event_id}`
  }

  static event(event_id: string) {
    return `${this.event_root(event_id)}`
  }

  static event_capacity_key(event_id: string, key: string) {
    return `${this.event_root(event_id)}/capacity/${key}`
  }

  static event_admin(event_id:string , user_id: string) {
		return `${this.event(event_id)}/admin/${user_id}`
  }

  static event_is_public(event_id: string) {
    return `${this.event(event_id)}/is_public`
  }

  static event_announcement_col(event_id: string) {
    return `${this._root(event_id)}/announcement`
  }

  static event_capacity(event_id: string) {
    return `${this._root(event_id)}/capacity`
  }

  static event_conns_root(event_id: string) {
    return `${this._root(event_id)}/connections`
  }

  static event_conns_col(event_id: string) {
    return `${this.event_conns_root(event_id)}`
  }

  static event_conn_user(eid: string, uid: string) {
    return `${this.event_conns_col(eid)}/${uid}`
  }

  static event_data_root(event_id: string) {
    return `${this._root(event_id)}/data`
  }

  static event_data_event_root(event_id: string) {
    return `${this.event_data_root(event_id)}`
  }

  static event_data_inputs(event_id: string) {
    return `${this.event_data_event_root(event_id)}/inputs`
  }

  static event_data_posting_banner(event_id: string, posting_id: string) {
		return `${this.event_data_inputs(event_id)}/posting_banner/${posting_id}`
  }

  static event_data_static(event_id: string) {
    return `${this.event_data_event_root(event_id)}/static`
  }

  static event_data_pairings(event_id: string) {
		return `${this.event_data_static(event_id)}/pairings`
  }

  static event_data_schedule(event_id: string) {
		return `${this.event_data_static(event_id)}/schedule`
  }

  static event_data_speakers(event_id: string) {
		return `${this.event_data_static(event_id)}/announcements`
  }

  static event_data_topics(event_id: string) {
		return `${this.event_data_static(event_id)}//topics`
  }

  static event_member_public_root(event_id: string) {
    return `${this._root(event_id)}/event_member_public`
  }

  static event_member_name(event_id: string, member_id: string) {
    return `${this.event_member_public_root(event_id)}/${member_id}/name`
  }

  static event_member_organization_id(event_id: string, member_id: string) {
    return `${this.event_member_public_root(event_id)}/${member_id}/organization_id`
  }

  static em_presence_root(event_id: string) {
    return `${this._root(event_id)}/event_member_presence`
  }

  static em_presence_col(event_id: string) {
		return `${this.em_presence_root(event_id)}`
  }

  static em_presence(event_id: string, user_id: string) {
		return `${this.em_presence_col(event_id)}/${user_id}`
  }

  static em_private_root(event_id: string) {
    return `${this._root(event_id)}/event_member_private`
  }

  static em_private_col(event_id: string) {
    return `${this.em_private_root(event_id)}`
  }

  static em_private_chat_col(
    event_id: string,
    user_id: string,
  ) {
		return `${this.em_private_col(event_id)}/${user_id}/chats/list`
	}

  static em_private_room(
    event_id: string,
    member_id: string,
    room_id: string,
  ) {
    return `${this.em_private_col(event_id)}/${member_id}/rooms/list/${room_id}`
  }

  static em_private_chat(
    event_id: string,
    member_id: string,
    chat_id: string,
  ) {
    return `${this.em_private_col(event_id)}/${member_id}/chats/list/${chat_id}`
  }

  static em_private_room_col(
    event_id: string,
    member_id: string,
  ) {
		return `${this.em_private_col(event_id)}/${member_id}/rooms/list`
  }

  static em_private_test_time(
    event_id: string,
    member_id: string,
  ) {
		return `${this.em_private_col(event_id)}/${member_id}/test_time`
  }

  static event_pub_sub_root(event_id: string) {
    return `${this._root(event_id)}/event_pub_sub`
  }

  static event_pub_sub_posting_room_update(event_id: string) {
    return `${this.event_pub_sub_root(event_id)}/posting_room_update`
  }

  static event_pub_sub_posting_room_delete(event_id: string) {
    return `${this.event_pub_sub_root(event_id)}/posting_room_delete`
  }

  static em_public_root(event_id: string) {
    return `${this._root(event_id)}/event_member_public`
  }

  static em_public_col(event_id: string) {
    return `${this.em_public_root(event_id)}`
  }

  static event_member_public(event_id: string, member_id: string) {
    return `${this.em_public_col(event_id)}/${member_id}`
  }

  static event_member_public_name(event_id: string, member_id: string) {
    return `${this.em_public_col(event_id)}/${member_id}/name`
  }

  static em_public_status(
    event_id: string,
    member_id: string,
  ) {
    return `${this.em_public_col(event_id)}/${member_id}/status`
  }

  static em_public_rsvp(
    event_id: string,
    member_id: string,
  ) {
    return `${this.em_public_col(event_id)}/${member_id}/rsvp`
  }

  static em_public_org(
    event_id: string,
    member_id: string,
    org_id: string,
  ) {
    return `${this.em_public_col(event_id)}/${member_id}/organizations/${org_id}`
  }

  static em_public_tag(
    event_id: string,
    user_id: string,
    tag_key: string,
  ) {
    return `${this.em_public_col(event_id)}/${user_id}/tags/${tag_key}`
  }

  static em_public_system(
    event_id: string,
    user_id: string,
  ) {
    return `${this.em_public_col(event_id)}/${user_id}/system`
  }

  static event_org_root(event_id: string) {
    return `${this._root(event_id)}/organization`
  }

  static event_orgs_col(event_id: string) {
		return `${this.event_org_root(event_id)}`
  }

  static event_org(event_id: string, org_id: string) {
    return `${this.event_orgs_col(event_id)}/${org_id}`
  }

  static event_public_chat_root(event_id: string) {
    return `${this._root(event_id)}/public_chat`
  }

  static event_public_chat_col(event_id: string) {
		return this.event_public_chat_root(event_id)
  }

  static event_public_chat(event_id: string, chat_id: string) {
		return `${this.event_public_chat_col(event_id)}/${chat_id}`
  }

  static event_public_room_root(event_id: string) {
    return `${this._root(event_id)}/public_room`
  }

  static event_public_room_col(event_id: string) {
		return this.event_public_room_root(event_id)
  }

  static event_public_room(event_id: string, room_id: string) {
    return `${this.event_public_room_root(event_id)}/${room_id}`
  }

  static event_room_group_col(event_id: string) {
    return `${this._root(event_id)}/room_group`
  }

  static event_room_group(event_id: string, group_id: string) {
    return `${this.event_room_group_col(event_id)}/${group_id}`
  }

  static event_room_group_room_col(
    event_id: string,
    group_id: string,
  ) {
    return `${this.event_room_group(event_id, group_id)}/rooms`
  }

  static event_room_group_room(
    event_id: string,
    group_id: string,
    room_id: string,
  ) {
    return `${this.event_room_group_room_col(event_id, group_id)}/${room_id}`
  }

  static event_social_room_col(event_id: string) {
    return `${this._root(event_id)}/room_social`
  }

  static event_social_room(event_id: string, room_id: string) {
    return `${this.event_social_room_col(event_id)}/${room_id}`
  }

  static event_hq_room_col(event_id: string) {
    return `${this._root(event_id)}/room_hq`
  }

  static event_hq_room(event_id: string, room_id: string) {
    return `${this.event_hq_room_col(event_id)}/${room_id}`
  }

  static event_rt_user_settings(event_id: string) {
    return `${this._root(event_id)}/rt_event_user_settings`
  }

  static event_rt_user_chat_settings(p: {
    event_id: string,
    user_id: string
  }) {
    return `${this._root(p.event_id)}/rt_event_user_chat_settings/${p.user_id}`
  }

  static event_rt_user_chat_settings_sound_global(p: {
    event_id: string,
    user_id: string
  }) {
    return `${this.event_rt_user_chat_settings(p)}/global/mute_sound`
  }

  static event_rt_user_chat_settings_alert_global(p: {
    event_id: string,
    user_id: string
  }) {
    return `${this.event_rt_user_chat_settings(p)}/global/show_alert`
  }

  static event_rt_user_chat_settings_sound(p: {
    event_id: string,
    user_id: string,
    chat_id: string,
  }) {
    return `${this.event_rt_user_chat_settings(p)}/chats/${p.chat_id}/mute_sound`
  }

  static event_rt_user_chat_settings_alert(p: {
    event_id: string,
    user_id: string,
    chat_id: string,
  }) {
    return `${this.event_rt_user_chat_settings(p)}/chats/${p.chat_id}/show_alert`
  }

  static job_add_private_chat_members(
    event_id: string,
    chat_id: string,
  ) {
    return `${this._root(event_id)}/job_add_private_chat_members/${chat_id}`
  }

  static posting_root(event_id: string) {
    return `${this._root(event_id)}/posting`
  }

  static posting_col(event_id: string) {
    return `${this.posting_root(event_id)}`
  }

  static posting(event_id: string, posting_id: string) {
    return `${this.posting_col(event_id)}/${posting_id}`
  }

  static draw_room_root(event_id: string) {
    return `${this._root(event_id)}/draw_room`
  }

  static draw_room_categories_col(event_id: string) {
    return `${this.draw_room_root(event_id)}/categories`
  }

  static draw_room_categories_col_cat(p: {
    event_id: string, category_id: string
  }) {
    return `${this.draw_room_categories_col(p.event_id)}/${p.category_id}`
  }

  static draw_room_meeting_id(event_id: string) {
    return `${this.draw_room_root(event_id)}/meeting_id`
  }

  static draw_room_categories_cat(event_id: string, category_id: string) {
    return `${this.draw_room_categories_col(event_id)}/${category_id}`
  }

  static draw_room_category(p: {event_id: string, category_id: string}) {
    return `${this._root(p.event_id)}/draw_room_category/${p.category_id}`
  }

  static draw_room_times(p: {event_id: string, category_id: string}) {
    return `${this.draw_room_category(p)}/times`
  }

  static draw_room_questions(p: {event_id: string, category_id: string}) {
    return `${this.draw_room_category(p)}/questions`
  }

  static draw_room_question(p: {
    event_id: string,
    category_id: string,
    index: string,
  }) {
    return `${this.draw_room_category(p)}/questions/${p.index}`
  }

  static pc_root(event_id: string) {
    return `${this._root(event_id)}/posting_category`
  }

  static posting_category_col(p: {
    event_id: string,
    posting_id: string,
  }) {
    return `${this.pc_root(p.event_id)}/${p.posting_id}`
  }

  static posting_category(p: {
    event_id: string
    posting_id: string
    category_id: string
  }) {
    return `${this.posting_category_col(p)}/${p.category_id}`
  }

  static posting_category_rooms_col(p: {
    event_id: string
    posting_id: string
    category_id: string
  }) {
    return `${this.posting_category_col(p)}/${p.category_id}/rooms`
  }

  static posting_category_room(p: {
    event_id: string
    posting_id: string
    category_id: string
    room_id: string
  }) {
    return `${this.posting_category_rooms_col(p)}/${p.room_id}`
  }

  static prm_root(event_id: string) {
    return `${this._root(event_id)}/posting_room_member`
  }

  static posting_room_member_col(p: {
    event_id: string
    posting_id: string
    category_id: string
    room_id: string
  }) {
    return `${this.prm_root(p.event_id)}/${p.posting_id}/${p.category_id}/${p.room_id}`
  }

  static posting_room_member(p: {
    event_id: string
    posting_id: string
    category_id: string
    room_id: string
    member_id: string
  }) {
    return `${this.posting_room_member_col(p)}/${p.member_id}`
  }

  static posting_room_member_stop_at_posting_id(p: {
    event_id: string,
    posting_id: string,
  }) {
    return `${this.prm_root(p.event_id)}/${p.posting_id}`
  }

  static meeting_root(event_id: string) {
    return `${this._root(event_id)}/meeting`
  }

  static meeting(event_id: string, meeting_id: string) {
    return `${this.meeting_root(event_id)}/${meeting_id}`
  }

  static meeting_acl_root(event_id: string) {
    return `${this._root(event_id)}/meeting_acl`
  }

  static meeting_acl(event_id: string, meeting_id: string) {
    return `${this.meeting_acl_root(event_id)}/${meeting_id}`
  }

  static meeting_acl_member(p: {
    event_id: string,
    meeting_id: string,
    member_id: string,
  }) {
    return `${this._root(p.event_id)}/meeting_acl/${p.meeting_id}/${p.member_id}`
  }

  static room_root(event_id: string) {
    return `${this._root(event_id)}/room`
  }

  static room(event_id: string, room_id: string) {
    return `${this.room_root(event_id)}/${room_id}`
  }

  static room_col(event_id: string) {
    return this.room_root(event_id)
  }

  static room_teams(event_id: string, room_id: string) {
    return `${this.room(event_id, room_id)}/teams`
  }

  static room_name(event_id: string, room_id: string) {
    return `${this.room(event_id, room_id)}/name`
  }

  static room_timer_root(event_id: string) {
    return `${this._root(event_id)}/room_timer`
  }

  static room_timer(event_id: string, room_id: string) {
    return `${this.room_timer_root(event_id)}/${room_id}`
  }

  static room_timer_col(event_id: string) {
    return this.room_timer_root(event_id)
  }

  static rt_event_member_root(event_id: string) {
    return `${this._root(event_id)}/rt_event_member`
  }

  static rt_event_member_col(eid: string) {
    return `${this.rt_event_member_root(eid)}`
  }

  static rt_event_member(eid: string, uid: string) {
    return `${this.rt_event_member_col(eid)}/${uid}`
  }

  static rtem_status(eid: string, uid: string) {
    return `${this.rt_event_member(eid, uid)}/status`
  }

  static rt_event_settings_root(event_id: string) {
    return `${this._root(event_id)}/rt_event_settings`
  }

  static rt_event_settings(eid: string) {
    return this.rt_event_settings_root(eid)
  }

  static rtes_ao_social_rooms(eid: string) {
    return `${this.rt_event_settings(eid)}/ao_social_room`
  }

  static rtes_hide_event_hq(eid: string) {
    return `${this.rt_event_settings(eid)}/hide_event_hq`
  }

  static rtes_hide_postings(eid: string) {
    return `${this.rt_event_settings(eid)}/hide_postings`
  }

  static rtes_hide_social_hall(eid: string) {
    return `${this.rt_event_settings(eid)}/hide_social_hall`
  }

  static rtes_use_draw_room(eid: string) {
    return `${this.rt_event_settings(eid)}/use_draw_room`
  }

  static rtes_enable_auditorium(eid: string) {
    return `${this.rt_event_settings(eid)}/auditorium/enabled`
  }

  static rtes_auditorium_url(eid: string) {
    return `${this.rt_event_settings(eid)}/auditorium/url`
  }

  static rtrm_root(event_id: string) {
    return `${this._root(event_id)}/rt_room_member`
  }

  static rt_room_member_col(p: {
    eid: string, mid: string,
  }) {
    return `${this.rtrm_root(p.eid)}/${p.mid}`
  }

  static rt_room_member(p: {
    eid: string,
    // meeting_id found under the meeting collection
    mid: string,
    uid: string
  }) {
    return `${this.rt_room_member_col(p)}/${p.uid}`
  }

  static rt_room_member_poi(p: {
    eid: string,
    mid: string,
    uid: string,
  }) {
    return `${this.rt_room_member(p)}/poi`
  }

  static rt_room_member_status(p: {
    eid: string
    mid: string
    uid: string
  }) {
    return `${this.rt_room_member(p)}/status`
  }

  static rt_room_breakout_rooms_col(p: {
    event_id: string,
    parent_id: string,
  }) {
    return `${this._root(p.event_id)}/breakout_rooms/${p.parent_id}`
  }

  static rt_room_breakout_room(p: {
    event_id: string,
    parent_id: string,
    room_id: string,
  }) {
    return `${this.rt_room_breakout_rooms_col(p)}/${p.room_id}`
  }

  static rt_room_root(event_id: string) {
    return `${this._root(event_id)}/rt_room`
  }

  static rt_room(eid: string, rid: string) {
    return `${this.rt_room_root(eid)}/${rid}`
  }

  static rt_readiness(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/readiness`
  }

  static rt_room_moot_speaker(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/speaker`
  }

  static rt_room_ie_speaker1(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/speaker1`
  }

  static rt_room_ie_speaker2(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/speaker2`
  }

  static rt_room_duo(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/is_duo`
  }

  static rt_room_use_lobby(eid: string, rid: string) {
    return `${this.rt_room(eid, rid)}/use_lobby`
  }

  static rt_room_lobby_root(eid: string) {
    return `${this._root(eid)}/rt_room_lobby`
  }

  static rt_room_lobby(eid: string, rid: string) {
    return `${this.rt_room_lobby_root(eid)}/${rid}`
  }

  static room_acl_root(event_id: string) {
    return `${this._root(event_id)}/room_acl`
  }

  static room_acl_event_root(event_id: string) {
    return this.room_acl_root(event_id)
  }

  static room_acl_col(event_id: string, room_id: string) {
    return `${this.room_acl_root(event_id)}/${room_id}`
  }

  static room_acl(event_id: string, room_id: string, user_id: string) {
    return `${this.room_acl_col(event_id, room_id)}/${user_id}`
  }

  static user_db_connection() {
    return '.info/connected'
  }

}
