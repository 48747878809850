import { I_Org_Room, I_Posting_Room, I_Posting_Team_Room } from '@ws/schema-rtdb'

export function is_posting_room(
  room: I_Posting_Room,
): room is I_Posting_Room {
  return (room as I_Posting_Room).posting_id !== undefined
}

export function is_org_room(
  room: I_Org_Room,
): room is I_Org_Room {
  return (room as I_Org_Room).organization_id !== undefined
}

export function is_team_room(
  room: I_Posting_Team_Room,
): room is I_Posting_Team_Room {
  return (room as I_Posting_Team_Room).teams !== undefined
}
