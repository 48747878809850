import { Event_Member_Public, I_Breakout_Room, I_Posting_Room } from '@ws/schema-rtdb'

export interface I_Jessup_Posting_Room extends I_Posting_Room {
  match_id: number
  open_time: number
  room_link?: string
  room_name?: string
}

export interface I_Jessup_Comp_Room extends I_Jessup_Posting_Room {
  teams: I_Room_Teams
}

export interface I_Jessup_Comp_Breakout_Room extends I_Breakout_Room {
  is_delib: boolean
  is_prep: boolean
  open_time: number
}

export interface I_Room_Teams {
  judges: I_Room_Judge_Team
  teams: I_Room_Team[]
  app_observers?: number[]
  res_observers?: number[]
}

export interface I_Room_Judge_Team {
  chat_id: string
  // This is the ILSA id.
  members: number[]
  // Breakout room id.
  room_id: string
}

export interface I_Room_Team {
  chat_id: string
  // This is the ILSA id.
  members: number[]
  memorial_link?: string
  // This is the ILSA team number
  name: number
  // Breakout room id.
  room_id: string
  type: E_ROOM_TEAM_TYPE
}

export enum E_ROOM_TEAM_TYPE {
  APP = 'app',
  RES = 'res',
}

export interface I_Match_Speakers {
  app?: I_Match_Speaker_Team
  res?: I_Match_Speaker_Team
}

export interface I_Match_Speaker_Team {
  s1?: I_Match_Speaker
  s2?: I_Match_Speaker
  // rebuttal
  rb?: number
}

export interface I_Match_Speaker {
  id: number
  time: number
}

export interface I_Jessup_EMP extends Event_Member_Public {
  ilsa_id: number
}

// Timestamp
export type T_Assemble_Court = number

// Timestamp
export type T_Matches_Ready = number



