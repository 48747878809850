<div
  fxLayoutAlign="center center"
  class='container h100 o-y-auto'
  *ngIf='!done'>

  <div class='auth-container'>
    <form
      class='animated fadeIn form-container'
      nzNoColon
      nz-form
      [formGroup]="form"
      (ngSubmit)="submit_form()">

      <div class='ta-center header'>Login to Yaatly</div>

      <nz-form-item>
        <nz-form-label class='hidden' nzFor="email"></nz-form-label>
        <nz-form-control nzErrorTip="Invalid email" nzHasFeedback>
          <nz-input-group nzPrefixIcon="mail" nzSize="large">
            <input
              formControlName="email"
              id="email"
              nz-input
              placeholder="Email"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label class='hidden' nzFor="password"></nz-form-label>
        <nz-form-control nzErrorTip="Please input your password" nzHasFeedback>
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input
              formControlName="password"
              id="password"
              nz-input
              placeholder='Password'
              type="password"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <div class='ta-right fs-1-3-rem'>
            <a [routerLink]='url_forgot_pass'>Forgot password?</a>
          </div>
          <button
            nzBlock
            nzType="primary"
            nz-button
            [nzLoading]='submitting'
            nzSize='large'
            [disabled]="!form.valid || submitting"
            >Log in</button>
        </nz-form-control>
      </nz-form-item>

      <br>

      <p class='ta-center fs-1-3-rem'>Need an account?</p>
      <p class='ta-center fs-1-3-rem'><a [routerLink]='url_register'>Sign up</a></p>

      <a
      class='p-abs pp fs-1-2-rem'
      [href]='url_pp'
      target="_blank">Privacy</a>
      <a class='p-abs logo' [href]="public_app_link">
        <logo-letter [fill]='logo_fill' [height]="'3rem'"></logo-letter>
      </a>
    </form>
  </div>

  <div class='doge-container'><doge></doge></div>

</div>
