/**
 * This static class defines all Firestore nodes that are accessed.
 * This can be client side, service side, etc. The routes should
 * only change when schema changes.
 */
export class FB_Refs_Firestore {
  static event(event_id: string) {
		return `event/${event_id}`
  }

  static archive_event(event_id: string) {
    return `archive_event/${event_id}`
  }

  static archive_event_member_public(event_id: string, user_id: string) {
    return `archive_event/${event_id}/member_public/${user_id}`
  }

  static archive_event_org(event_id: string, org_id: string) {
    return `archive_event/${event_id}/organization/${org_id}`
  }

  static archive_event_user_checkin(event_id: string, user_id: string) {
    return `archive_event/${event_id}/user_checkin/${user_id}`
  }

	static event_collection() {
		return 'event'
  }

  static event_invitation(event_id: string) {
    return `event_invitation/${event_id}`
  }

  static event_invitation_col() {
    return 'event_invitation'
  }

  static event_invitation_private_col(event_id: string) {
    return `event_invitation/${event_id}/private`
  }

	static organization(org_id: string) {
		return `organization/${org_id}`
  }

  static archive_organization(org_id: string) {
    return `archive_organization/${org_id}`
  }

  static job_complete_events(job_id: string) {
    return `job_complete_events/${job_id}`
  }

  static job_complete_events_col() {
    return `job_complete_events`
  }

  static job_user_name_change(job_id: string) {
    return `job_user_name_change/${job_id}`
  }

  static job_user_name_change_collection() {
    return 'job_user_name_change'
  }

	static organization_collection() {
		return 'organization'
  }

  static organization_event(org_id: string, event_id: string) {
    return `organization/${org_id}/event/${event_id}`
  }

	static organization_event_collection(org_id: string) {
		return `organization/${org_id}/event`
	}

	static organization_member(org_id: string, user_id: string) {
		return `organization/${org_id}/member/${user_id}`
	}

	static organization_member_collection(org_id: string) {
		return `organization/${org_id}/member`
	}

	static organization_member_email(org_id: string, user_id: string) {
		return `organization/${org_id}/member_email/${user_id}`
	}

	static organization_member_email_collection(org_id: string) {
		return `organization/${org_id}/member_email`
	}

	static organization_member_invitation(org_id: string, invite_id: string) {
		return `organization/${org_id}/member_invitation/${invite_id}`
  }

  static organization_member_invitation_archive(
    org_id: string,
    invite_id: string,
  ) {
    return `organization/${org_id}/member_invitation_archive/${invite_id}`
  }

	static organization_member_invitation_collection(org_id: string) {
		return `organization/${org_id}/member_invitation`
  }

  static issues() {
    return 'issues'
  }

	static user_event_invitation_collection(user_id: string) {
		return `${this.user_public(user_id)}/event_invitation`
	}
	static user_event_collection(user_id: string) {
		return `${this.user_public(user_id)}/event`
  }

	static user_organizations_collection(user_id: string) {
		return `${this.user_public(user_id)}/organizations`
  }

  static user_organization(user_id: string, org_id: string) {
    return `${this.user_public(user_id)}/organizations/${org_id}`
  }

  static user_app(user_id: string) {
		return `user_app/${user_id}`
  }

  static user_billing_col() {
    return 'user_billing'
  }

  static user_billing(user_id: string) {
    return `${this.user_billing_col()}/${user_id}`
  }

  static user_ledger_col(user_id: string) {
    return `${this.user_billing(user_id)}/ledger`
  }

  static user_ledger_item(user_id: string, tid: string) {
    return `${this.user_billing(user_id)}/ledger/${tid}`
  }

  static user_public(user_id: string) {
		return `user/${user_id}`
	}

	static user_private(user_id: string) {
		return `user_private/${user_id}`
	}
}
