// This should match the structure of the config when you run `firebase functions:config:get`
export enum E_FB_PROJECT {
  YAATLY = 'yaatly',
  MOOT = 'moot',
  JESSUP = 'jessup',
}

export interface I_FB_Functions_Config {
  aws: {
    secret: string
    key: string
    chime: {
      secret: string
      key: string
    }
  }
  daily: {
    key: string
  }
  functions: {
    auth_uri: string
    client_x509_cert_url: string
    client_id: string
    type: string
    private_key: string
    project_id: string
    token_uri: string
    private_key_id: string
    auth_provider_x509_cert_url: string
    client_email: string
    db_secret: string
  }
  twilio: {
    secret: string
    sid: string
    key: string
    auth_token: string
  }
  ethereal: {
    username: string
    password: string
  }
  stripe?: {
    secret_key: string
    api_webhook_secret: string
  }
  env: {
    name: string
    project: E_FB_PROJECT
  }
}
