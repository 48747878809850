<loading
  *ngIf='loader_opts.show'
  [fixed]='true'
  [message]='loader_opts.message'
  [background]='loader_opts.background'>
</loading>

<div class='modules' *ngIf='is_ready'>
  <router-outlet></router-outlet>
</div>

<app-templates></app-templates>
