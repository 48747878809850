import {
  Action,
  State,
  Selector,
  StateContext,
  StateToken,
} from '@ngxs/store'
import { Delete_Member, Set_Member } from './actions'
import { STORE_ENTITIES } from "@app/constants"
import produce from 'immer'
import { Org_Member, GUID_Type, GUID } from '@ws/schema-fs'
import { App_Fs_Org_Member } from '@app/types'
import { Injectable } from '@angular/core'


// For member state, we are grouping members under their respective organizations. Why? B/c members will be accessed this way in most of the app.
export type Fs_Org_Member_State_Model = Record<string,
  Record<string, Org_Member & GUID_Type>
>;

export const FS_ORG_MEMBER_STATE_TOKEN = new StateToken<Fs_Org_Member_State_Model>(STORE_ENTITIES.FS_ORG_MEMBERS)


@State<Fs_Org_Member_State_Model>({
  name: FS_ORG_MEMBER_STATE_TOKEN,
})
@Injectable()
export class Fs_Org_Member_State {
  @Selector()
  public static getState(state: Fs_Org_Member_State_Model) {
      return state
  }

  // Lazy selectors: https://www.ngxs.io/concepts/select#lazy-selectors.
  @Selector()
  static org_members(state: Fs_Org_Member_State_Model) {
    return (org_id: string): Record<GUID, App_Fs_Org_Member> | {} => {
      const s = state[org_id] || {}
      return s
    }
  }

  @Selector()
  static org_member(state: Fs_Org_Member_State_Model) {
    return (org_id: string, member_id: string): App_Fs_Org_Member => {
      const s = state[org_id]
      // Members may not exist as they are not loaded until later in the app.
      return s && s[member_id]
    }
  }

  @Action(Delete_Member)
  private delete_member(
    ctx: StateContext<Fs_Org_Member_State_Model>,
    action: Delete_Member,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const oid = action.organization_id
        const mid = action.member_id
        delete draft[oid][mid]
      }),
    )
  }

  @Action(Set_Member)
  private set_member(
    ctx: StateContext<Fs_Org_Member_State_Model>,
    action: Set_Member,
  ) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        const mid = action.payload.id
        const oid = action.organization_id
        // If the org doesn't exist, add it now.
        if (!draft[oid]) draft[oid] = {}
        draft[oid][mid] = action.payload
      }),
    )
  }

}
