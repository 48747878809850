import { Component, Input, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { App_Store_Service } from '@app/services/store.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { Firestore_Db_Service } from '@app/services/firestore.service'
import { Api_Service } from '@app/services/api.service'

@Component({
  selector: 'modal-username-change',
  templateUrl: './name-change.component.html',
  styleUrls: ['./name-change.component.scss']
})
export class Modal_User_Name_Change_Component implements OnInit {
  form!: FormGroup
  submitting = false
  @Input() modal_action!: any

  constructor(
    private readonly _api_s: Api_Service,
    private readonly _fb: FormBuilder,
    private readonly _message_s: NzMessageService,
    private readonly _store: App_Store_Service,
  ) {
    const name = this._store.get_user_public().name

    this.form = this._fb.group({
      name: [name || '', Validators.required]
    })
  }

  ngOnInit() {
  }

  async submit_form() {
    if (this.submitting || !this.form.valid || !this.name_input_value.trim().length) {
      return
    }

    this.submitting = true

    try {
      await this._api_s.update_username(this.name_input_value)
      this._message_s.success('Name updated!')
    } catch (e) {
      console.log(e)
      this._message_s.error(e.message)
    }

    this.submitting = false
    this.modal_action()
  }

  get name_input_value() {
    return (this.form.controls.name
      && this.form.controls.name.value)
      || ''
  }

}
