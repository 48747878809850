import { Event_Member_Public } from '@ws/schema-rtdb'

interface Req_Body_Join_Event {
  event_id: string
  invite_id: string
}

export interface Req_Body_Join_Event_Individual extends Req_Body_Join_Event {
  member_name: string
  organization_id?: string
}

export interface Req_Body_Join_Event_Organization extends Req_Body_Join_Event {
  member_name: string
  organizations: string[]
}


export interface Res_Join_Event_Individual {
  result: Event_Member_Public
}

export interface Res_Join_Event_Organization {
  result: Event_Member_Public | 0
}

export const T_Timestamp_String = 'timestamp'
