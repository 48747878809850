/**
 * This static class defines all Firebase storage refs that are accessed.
 * This can be client side, service side, etc.
 * Each ref refers to the path, excluding file
 */
export class FB_Refs_Storage {
  static organization_logo_original(org_id: string): string {
    return `logos/${org_id}/original`;
  }
  static organization_logo_thumbnail(org_id: string): string {
    return `logos/${org_id}/thumbnail`;

  }
}
