import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Service_Interfaces as SI } from '@client/services'
import { Loading_Service } from "@client/services"

@Component({
  selector: 'yaatly-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class App_Component implements OnInit {
  is_ready = true
  loader_opts: SI.Show_Loader_Opts = {
    background: undefined,
    message: undefined,
    show: false,
  }
  previous_url = ''
  subscription!: Subscription

  constructor(
    private load_s: Loading_Service,
  ) {
    this.subscribe_to_loader()
   }

  async ngOnInit() {
    this.is_ready = true
  }

  private subscribe_to_loader() {
    this.subscription = this.load_s
      .get_loader()
      .subscribe((opts: SI.Show_Loader_Opts) => {
        this.loader_opts = opts
      })
  }
}
