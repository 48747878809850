<svg
  id="Layer_1"
  data-name="Layer 1"
  [ngStyle]="dimensions"

  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1010 408.58">
  <defs>
      <!-- <style ref='style'>.cls-1{fill:#ba0bff;}</style> -->
  </defs>
  <title>logo</title>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M162.55,131.06V320.32c0,41.87-21.36,74.95-61.55,74.95L78,348c12.14,0,24.7-8.38,24.7-27.64v-36a60.23,60.23,0,0,1-43.55,18c-34.33,0-55.69-24.71-55.69-58.62V131.06H63.31V232.39c0,11.72,5.86,20.1,17.59,20.1,10.47,0,18.84-8,21.77-20.94V131.06Z"/>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M388.65,244.53v45.64c-10.89,8.38-23.87,12.15-37.27,12.15-20.09,0-36-10.47-44-28.48-13,20.1-31,28.48-52.34,28.48-49.41,0-79.14-40.2-79.14-87.93,0-46.48,29.73-87.1,79.14-87.1,18,0,34.33,5.86,46.9,20.94V131.06h59.87V237.42c0,8.37,5.86,12.14,12.56,12.14A21.92,21.92,0,0,0,388.65,244.53ZM302,232V197.22c-5.87-12.56-17.59-20.52-31.83-20.52-20.1,0-34.75,16.33-34.75,37.69,0,21.77,14.65,38.1,34.75,38.1A35,35,0,0,0,302,232Z"/>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M604.29,244.53v45.64c-10.89,8.38-23.87,12.15-37.27,12.15-20.09,0-36-10.47-44-28.48-13,20.1-31,28.48-52.34,28.48-49.41,0-79.14-40.2-79.14-87.93,0-46.48,29.73-87.1,79.14-87.1,18,0,34.33,5.86,46.9,20.94V131.06h59.87V237.42c0,8.37,5.86,12.14,12.56,12.14A21.92,21.92,0,0,0,604.29,244.53ZM517.62,232V197.22c-5.87-12.56-17.59-20.52-31.83-20.52C465.7,176.7,451,193,451,214.39c0,21.77,14.66,38.1,34.75,38.1A35,35,0,0,0,517.62,232Z"/>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M629.83,235.32V185.08H596.34v-54h33.49V61.55h59.88v69.51H727v54H689.71v43.54c0,11.73,4.19,20.94,17.17,20.94,7.53,0,13.81-2.51,20.1-5.86v46.47c-10.47,8-20.52,11.73-36,11.73C650.77,301.9,629.83,276.77,629.83,235.32Z"/>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M799,302.32c-29.32,0-49.41-21.78-49.41-55.69V.84h59.87V237.42c0,8.37,5.87,12.14,12.56,12.14a21.92,21.92,0,0,0,14.24-5v45.64C825.37,298.55,812.81,302.32,799,302.32Z"/>
  <path
    [style.fill]="fill ? fill : fill_default"
    d="M1007.52,131.06V320.32c0,41.87-21.36,74.95-61.56,74.95l-23-47.31c12.15,0,24.71-8.38,24.71-27.64v-36a60.23,60.23,0,0,1-43.55,18c-34.33,0-55.69-24.71-55.69-58.62V131.06h59.88V232.39c0,11.72,5.86,20.1,17.59,20.1,10.46,0,18.84-8,21.77-20.94V131.06Z"/>
</svg>
