import { I_Route_Base_Params, make_route } from '../utils'

const base = 'event'

export class Event {
  static root(project: string, is_local: boolean) {
    return make_route({ is_local, project, path: base})
  }

  static delete_event_date_key(
    { project, is_local }: I_Route_Base_Params,
    event_id: string,
    date_key: string,
  ) {
    const path = `${base}/${event_id}/date-key/${date_key}`
    return make_route({ is_local, project, path })
  }

  static patch_event_date_key(
    { project, is_local }: I_Route_Base_Params,
    event_id: string,
    date_key: string,
  ) {
    const path = `${base}/${event_id}/date-key/${date_key}`
    return make_route({ is_local, project, path })
  }

  static put_event({ project, is_local }: I_Route_Base_Params,) {
    const path = `${base}`
    return make_route({ is_local, project, path })
  }
}
