import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import {
  Resolve,
  Router,
} from '@angular/router'
import { Auth_Service } from "@app/services/auth.service"
import { Routes } from "@ws/constants"
import { Api_Service } from '@app/services/api.service'
import { Loading_Service } from "@client/services"
import { Crisp_Service } from '@app/services/crisp.service'
import { User_Billing_Service } from '@app/services/models/user_billing.service'
import { App_Store_Service } from '@app/services/store.service'
import { Name_Change_Modal_Component } from '../name-change-modal/component'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'

@Injectable({ providedIn: 'root' })
export class User_Resolver implements Resolve<any> {
  private _name_change_modal!: NzModalRef
  user_is_loaded = false

  constructor(
    private readonly _api_s: Api_Service,
    private readonly _auth_s: Auth_Service,
    private readonly _crisp_s: Crisp_Service,
    private readonly _load_s: Loading_Service,
    private readonly _router: Router,
    private readonly _loc: Location,
    private readonly _modal_s: NzModalService,
    private readonly _ss: App_Store_Service,
    private readonly _ubs: User_Billing_Service,
  ) {}

  async resolve(
    // route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot,
  ) {
    const user = this._auth_s.user
    // Non logged users go to the login route.
    if (!user) {
      this._auth_s.set_on_auth_redirect(this._loc.path())
      this._router.navigate(Routes.arr.login())
      return false
    }

    // Logged users will load all their data if not already. Anon users have no data to load.
    if (!this.user_is_loaded && !user.isAnonymous) {
      this._load_s.show_loader({message: 'Loading yaatly'})
      await this.load_user()
      this.user_is_loaded = true
      this._load_s.hide_loader()
    }

    // Load crisp for all logged (anon and app users) users.
    this._crisp_s.init()

    // This is a user and is loaded.
    return true
  }

  private async load_user() {
    const { user_public } = await this._api_s.get_all_user_nodes()
    this.user_is_loaded = true

    if (user_public) {
      await Promise.all([
        this._api_s.get_user_orgs(),
        this._ubs.init(),
      ])

      this._maybe_toggle_name_change_prompt()

    }
  }

  private _destroy_name_change_modal(org_id?: string) {
    this._name_change_modal.destroy()
  }

  private _maybe_toggle_name_change_prompt() {
    if (!this._ss.get_user_public()?.name?.trim().length) {
      this._name_change_modal = this._modal_s.create({
        nzContent: Name_Change_Modal_Component,
        nzFooter: null,
        nzTitle: 'Name update',
        nzComponentParams: {
          modal_action: this._destroy_name_change_modal.bind(this),
        }
      })
    }
  }
}
