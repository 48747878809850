import { STORE_ENTITIES } from '@app/constants';
import { Org_Member, GUID_Type } from "@ws/schema-fs";

export class Delete_Member {
  public static readonly type = `[${STORE_ENTITIES.FS_ORG_MEMBERS}] Delete Member`;
  constructor(
    public organization_id: string,
    public member_id: string,
  ) {}
}

export class Set_Member {
  public static readonly type = `[${STORE_ENTITIES.FS_ORG_MEMBERS}] Set Member`;
  constructor(
    public payload: Org_Member & GUID_Type,
    public organization_id: string,
  ) {}
}


