import { Injectable } from '@angular/core'
import { Root_Router_Service } from '@app/services/router.service'
import { AngularFirestore } from '@angular/fire/firestore'
import { Auth_Service } from '@app/services/auth.service'
import { App_User_Connection_Service } from '@app/services/user-connection.service'
import firebase from 'firebase'


// Used purely for pre-app stuff. E.g. instantiating singletons to get some needed constructor action.
@Injectable()
export class App_Load_Service {

  constructor(
    // Need to trigger service constructors.
    private readonly _router_s: Root_Router_Service,
    private readonly _afs: AngularFirestore,
    private readonly _auths: Auth_Service,
    private readonly _aucs: App_User_Connection_Service,
  ) {
  }

  async init() {
    await this._auths.init()
  }

  // init(): Promise<any> {
  //   return new Promise(async(resolve, reject) => {
  //     // Any stuff to do before app inits can go here.
  //   });
  // }

  // Multiple providers can be used if desired. Note they run concurrently.
  // getSettings(): any {

  //   const promise = this.httpClient.get('http://private-1ad25-initializeng.apiary-mock.com/settings')
  //     .subscribe((settings: any) => {

  //       let APP_SETTINGS = {} as any;

  //       APP_SETTINGS.connectionString = settings[0].value;
  //       APP_SETTINGS.defaultImageUrl = settings[1].value;

  //       return settings;
  //     });
  // }
}
