<div class='danger-zone' *ngIf='show_warning' fxLayoutAlign="start center">
  <div>This cannot be undone</div>
  <i
    nz-icon nzType='warning'
    nzTheme='twotone'
    style='margin-left:1rem;'
    [nzTwotoneColor]='color_danger'></i>
</div>

<form
  autocomplete='off'
  class='animated fadeIn form-container'
  nzNoColon
  nz-form
  [formGroup]="form"
  (ngSubmit)="submit_form()">

  <nz-form-item>
    <nz-form-label class='w100' nzFor="confirm">{{ instruction }}</nz-form-label>
    <nz-form-control nzHasFeedback>
      <nz-input-group nzSize="large">
        <input
          formControlName="confirm"
          id="confirm"
          nz-input/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control fxLayoutAlign='end'>
      <!-- <button
        nzType="default"
        nz-button
        (click)='close()'
        *ngIf='has_cancel'
        style='margin-right: 1rem;'>Cancel</button> -->
      <button
        nzType="danger"
        nz-button
        [nzLoading]='submitting'
        [disabled]="!form.valid || submitting"
        >{{button_text || 'Submit'}}</button>
    </nz-form-control>
  </nz-form-item>
</form>
