import { Directive, OnInit, OnDestroy, Input, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef } from "@angular/core"
import { RouterOutlet, ChildrenOutletContexts } from "@angular/router"

@Directive({
    selector: 'named-outlet',
    exportAs: 'outlet'
})
export class Named_Outlet_Directive implements OnInit, OnDestroy {
    outlet!: RouterOutlet
    @Input() name!: string

    constructor(
        private parentContexts: ChildrenOutletContexts,
        private location: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private changeDetector: ChangeDetectorRef,
    ) {}
    ngOnInit() {
      this.outlet = new RouterOutlet(this.parentContexts, this.location, this.resolver, this.name, this.changeDetector)
      this.outlet.ngOnInit()
    }
    ngOnDestroy() {
      if(this.outlet)
        this.outlet.ngOnDestroy()
    }
  }
