import { STORE_ENTITIES } from '@app/constants'
import { App_Fs_Org } from "@app/types"

export class Delete_Org {
  public static readonly type = `[${STORE_ENTITIES.FS_ORGANIZATIONS}] Delete Org`
  constructor(public payload: string) {}
}

export class Set_Org {
  public static readonly type = `[${STORE_ENTITIES.FS_ORGANIZATIONS}] Set Org`
  constructor(public payload: App_Fs_Org) {}
}


