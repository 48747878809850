import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import { User_Service } from './user.service'
import { Routes } from '@ws/constants'
import { Auth_Service } from '@app/services/auth.service'
import {
  Router,
  NavigationStart,
  Params,
  NavigationEnd,
  Event as RouterEvent,
  ActivatedRouteSnapshot,
} from '@angular/router'
import { filter } from 'rxjs/operators'
import clonedeep from 'lodash.clonedeep'

// Used for router based stuff, such as tracking previous URL and setting redirects when necessary.
@Injectable({ providedIn: 'root' })
export class Root_Router_Service {
  previous_url = ''
  private readonly _params$: BehaviorSubject<Params> = new BehaviorSubject({})
  private _params_snapshot: Params = {}
  readonly params$ = this._params$.asObservable()

  constructor(
    private auth_s: Auth_Service,
    private router: Router,
    private user_s: User_Service,
  ) {
    this.router.events.subscribe(
      (event: any) => {
        // Set previous url/redirects on Navigations.
        if (event instanceof NavigationStart ) {
          this._maybe_set_previous_url(event.url)
          this._maybe_set_redirect(event.url)
        }
      })

      this.router.events
			.pipe(
				filter(
					(event: RouterEvent ) : boolean => {
						return(event instanceof NavigationEnd)
					}
				)
			)
			.subscribe(
        // @ts-ignore
				(event: NavigationEnd) => {
					const snapshot = this.router.routerState.snapshot.root
					const next_params = this._collect_params( snapshot )
					// A Router navigation event can occur for a variety of reasons, such
					// as a change to the search-params. As such, we need to inspect the
					// params to see if the structure actually changed with this
					// navigation event. If not, we don't want to emit an event.
					if ( this._params_are_diff( this._params_snapshot, next_params ) ) {
						this._params$.next( this._params_snapshot = next_params )
					}
				}
			)
  }

  // We want to set redirects if the user is logging/registering.
  private _maybe_set_redirect(new_url: string) {
    // If user is not logged, and is logging or registering.
    if (
      !this.user_s.get_user() &&
      (new_url === Routes.str.login() ||
      new_url === Routes.str.register())
    ) {
      // If there was a previous url already set and it's not login/register, (i.e. they did not directly link to login/register)
      if (
        this.previous_url &&
        this.previous_url !== Routes.str.login() &&
        this.previous_url !== Routes.str.register()
      ) {
        // Set the redirect.
        this.auth_s.set_on_auth_redirect(this.previous_url)
      }
    }
  }

  // Keeps track of the previous URL only if the current url is not login/register.
  private _maybe_set_previous_url(curr_url: string) {
    if (curr_url !== Routes.str.login() && curr_url !== Routes.str.register()) {
      this.previous_url = curr_url
    }
  }

  private _collect_params(root: ActivatedRouteSnapshot) {
    const params: Params = {}

    const merge_params_from_snapshot = (ss: ActivatedRouteSnapshot) => {
      // Make sure params don't collide.
      this._throw_on_collisions(params, ss.params)
			Object.assign(params, ss.params)
			ss.children.forEach(merge_params_from_snapshot)
		}

    merge_params_from_snapshot(root)
		return params
  }

  private _throw_on_collisions(op: Params, np: Params) {
    Object.keys(op).forEach((key1, ki1) => {
      Object.keys(np).forEach((key2, ki2) => {
        if (key1 === key2 && ki1 !== ki2) {
          console.error()
          throw new Error(`param collision: ${key1}`)
        }
      })
    })
  }

  private _params_are_diff(curr_params: Params, next_params: Params) {
		const currentKeys = Object.keys(curr_params)
		const nextKeys = Object.keys(next_params)

		// If the collection of keys in each set of params is different, then we know
		// that we have two unique collections.
		if (currentKeys.length !== nextKeys.length) {
			return true
		}

		// If the collections of keys have the same length then we have to start
		// comparing the individual KEYS and VALUES in each collection.
		for (let i = 0; i < currentKeys.length; i++) {
			const key = currentKeys[ i ]

      if (curr_params[key] !== next_params[key]) {
				return(true)
			}
    }

		return false
  }

  get params_obs$() {
    return this._params$.asObservable()
  }

  get params_state() {
    return clonedeep(this._params_snapshot)
  }
}
