import { FB_Refs_Rtdb as rtdb_refs } from '@ws/constants'

export class RTDB_Moot_Refs {
  static match_speakers(event_id: string) {
    return `${rtdb_refs._root(event_id)}/court_speakers`
  }

  static match_speaker_app(event_id: string) {
    return `${this.match_speakers(event_id)}/app`
  }

  // static match_speaker_app1(event_id: string) {
  //   return `${this.match_speakers(event_id)}/app1`
  // }

  // static match_speaker_app2(event_id: string) {
  //   return `${this.match_speakers(event_id)}/app2`
  // }

  static match_speaker_res(event_id: string) {
    return `${this.match_speakers(event_id)}/res`
  }

  // static match_speaker_res1(event_id: string) {
  //   return `${this.match_speakers(event_id)}/res1`
  // }

  // static match_speaker_res2(event_id: string) {
  //   return `${this.match_speakers(event_id)}/res2`
  // }

}
