/**
 * This static class defines all Firestore http refs.
 */
export class FB_Refs_Http {
  static callable_join_event_individual() {
    return 'http_join_event_individual-default'
  }

  static callable_join_event_organization() {
    return 'http_join_event_organization-default'
  }

  static callable_join_room() {
    return 'http_join_room-default'
  }

  static callable_put_organization() {
    return 'http_put_organization-default'
  }

  static callable_put_org_logo() {
    return 'http_put_organization_logo-default'
  }

  static callable_put_room() {
    return 'http_create_room-default'
  }
}
