export const TRANSITION_TIMES = [500, 1000, 1500]
export const TRANSITION_TIMES_MODAL_INDEX = 0

export enum TOAST_TYPES {
  DANGER = 'danger',
  INFO = 'info',
  POSITIVE = 'positive',
  WARNING = 'warning',
}

export enum LISTENER_TYPE_RTDB {
  CHILD_ADDED = 'child_added',
  CHILD_CHANGED = 'child_changed',
  CHILD_REMOVED = 'child_removed',
  VALUE = 'value',
}

export enum LISTENER_TYPE_FS {
  ON_SNAPSHOT = 'on_snapshot',
}

export enum ERROR_CODES {
  ORG_DNE = 'org_dne',
}

export enum NOTIFICATION_TEMPLATES {
  EVENT_ANNOUNCEMENT = 'event_announcement',
}

export const time_picker_disabled_min = () => {
  return [1, 2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 18, 19, 21, 22, 23, 24, 26, 27, 28, 29, 31, 32, 33, 34, 36, 37, 38, 39, 41, 42, 43, 44, 46, 47, 48, 49, 51, 52, 53, 54, 56, 57, 58, 59]
}

