import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms'
import { COLOR_PALETTE } from "@ws/constants"

@Component({
  selector: 'confirm-text',
  templateUrl: './confirm-text.component.html',
  styleUrls: ['./confirm-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Confirm_Text_Component implements OnInit {
  color_danger = COLOR_PALETTE.DANGER
  _confirm_value = ''
  form!: FormGroup
  submitting = false

  @Input() button_text = 'Submit'
  @Input() confirm_action: any
  @Input() has_cancel?: boolean
  @Input() instruction!: string
  @Input() show_warning = true

  // Handles repeated use of confirm-text by reseting validator with new values.
  @Input()
  set confirm_value(confirm_value: string) {
    this._confirm_value = confirm_value
    // Update the validator.
    if (this.form) {
      this.form.controls.confirm.clearValidators()
      this.form.controls.confirm.setValidators([
        Validators.pattern(confirm_value),
        Validators.required,
      ])
    }
  }

  @Output() done = new EventEmitter<boolean>()
  @Output() cancel_action = new EventEmitter<boolean>()

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      confirm: [
        null,
        [
          Validators.pattern(this._confirm_value),
          Validators.required,
        ]
      ],
    }, /* { updateOn: 'blur' } */)
  }

  cancel() {
    return this.has_cancel && this.cancel_action.next(true)
  }

  async submit_form() {
    if (this.submitting) return
    this.submitting = true
    await this.confirm_action()
    this.form.controls.confirm.setValue('')
    this.form.controls.confirm.markAsPristine()
    this.form.controls.confirm.markAsUntouched()
    this.form.updateValueAndValidity()
    this.form.reset()
    this.submitting = false
    this.done.next()
    this._cd.detectChanges()
  }
}
