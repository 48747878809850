declare const $crisp: any
import { Injectable } from '@angular/core'
import { App_Store_Service } from './store.service'
import { User_Service } from './user.service'
import { environment } from '@app/env'

/**
 * * This service interfaces with the crisp "SDK" (a global object).
 * ! There is no npm module or typings so be sure to test things
 * ! manually before modifying this service.
 */
@Injectable({
  providedIn: 'root'
})
export class Crisp_Service {
  constructor(
    private readonly _store_s: App_Store_Service,
    private readonly _user_s: User_Service,
  ) {}

  // Load up Crisp with the user data. Anonymous login will just have uid. App users can provide more data to crisp.
  async init() {
    if (!environment.production) {
      setTimeout(() => {
        $crisp.push(['do', 'chat:close'])
      }, 5000)
      return
    }

    const fb_user = await this._user_s.get_user()

    if (!fb_user) {
      return
    }

    $crisp.push(["set", "session:data", [[['uid', fb_user.uid]]]])

    const app_user = this._store_s.get_user_public()

    if (!app_user || fb_user.isAnonymous) {
      return
    }

    if (!fb_user.email) {
      console.warn('No fb_user.email available in crisp chat service')
    }

    if (!app_user.name) {
      console.warn('No app_user.name available in crisp chat service')
    }

    $crisp.push(["set", "user:email", fb_user.email || '(no email available'])
    $crisp.push(["set", "user:nickname", app_user.name || '(no user name available)'])
  }
}
