import { Component, Input, OnInit } from '@angular/core'


@Component({
  selector: 'image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class Image_Loader_Component implements OnInit {
  is_fetching = false
  has_image = false
  _logo = ''

  @Input() icon_size!: number
  @Input() url_fetcher: any

  @Input()
  set src_url(val: string) {
    if (val) {
      this.has_image = true
      this._logo = val
    }
  }

  constructor() {}

  ngOnInit() {
    if (this.src_url) {
      this.has_image = true
    } else {
      if (this.url_fetcher) {
        this.is_fetching = true
        // Don't await. Images loading should not block app.
        this.url_fetcher().then((url: string) => {
          if (url) {
            this.src_url = url
            this.has_image = true
          }
        }).catch((e: any) => {
          console.log(e)
          this.has_image = false
        }).then(() => {
          this.is_fetching = false
        })
      }
    }
  }

  get_camera_styles() {
    return {
      'color': 'rgba(0,0,0,.1)',
      'font-size': `${this.icon_size.toString()}rem` || 'initial',
    }
  }

  get_stop_styles() {
    const fs = (this.icon_size / 5) < 1  ? 1 : this.icon_size / 5

    return {
      'bottom': '4px',
      'font-size': `${fs}rem` || 'initial',
      'position': 'absolute',
      'right': '-3px',
    }
  }

  // calc(7rem/5)

}
